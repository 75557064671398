import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date string, such as 2007-12-03 (YYYY-MM-DD), compliant with ISO 8601 standard for representation of dates using the Gregorian calendar. */
  Date: any
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the date-timeformat outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representationof dates and times using the Gregorian calendar. */
  DateTime: any
  Hex: any
  /** Raw JSON value */
  Json: any
  /** The Long scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any
  RGBAHue: any
  RGBATransparency: any
  /** Slate-compatible RichText AST */
  RichTextAST: any
}

export enum AdDeliveryDestination {
  Top1 = 'Top1',
  Top2 = 'Top2',
  Top3 = 'Top3',
  Article1 = 'Article1',
  Article2 = 'Article2',
}

export type AdDistributionContent = Node & {
  __typename?: 'AdDistributionContent'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<AdDistributionContent>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  compaignName: Scalars['String']
  sponsored: Scalars['String']
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  targetUrl: Scalars['String']
  urlParameter?: Maybe<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  thumbnail: Asset
  adDistributionManagement: Array<AdDistributionManagement>
  scheduledIn: Array<ScheduledOperation>
  /** List of AdDistributionContent versions */
  history: Array<Version>
}

export type AdDistributionContentDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type AdDistributionContentCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionContentUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionContentPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionContentThumbnailArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionContentAdDistributionManagementArgs = {
  where?: Maybe<AdDistributionManagementWhereInput>
  orderBy?: Maybe<AdDistributionManagementOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionContentScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionContentHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type AdDistributionContentConnectInput = {
  /** Document to connect */
  where: AdDistributionContentWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type AdDistributionContentConnection = {
  __typename?: 'AdDistributionContentConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<AdDistributionContentEdge>
  aggregate: Aggregate
}

export type AdDistributionContentCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  compaignName: Scalars['String']
  sponsored: Scalars['String']
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  targetUrl: Scalars['String']
  urlParameter?: Maybe<Scalars['String']>
  thumbnail: AssetCreateOneInlineInput
  adDistributionManagement?: Maybe<AdDistributionManagementCreateManyInlineInput>
}

export type AdDistributionContentCreateManyInlineInput = {
  /** Create and connect multiple existing AdDistributionContent documents */
  create?: Maybe<Array<AdDistributionContentCreateInput>>
  /** Connect multiple existing AdDistributionContent documents */
  connect?: Maybe<Array<AdDistributionContentWhereUniqueInput>>
}

export type AdDistributionContentCreateOneInlineInput = {
  /** Create and connect one AdDistributionContent document */
  create?: Maybe<AdDistributionContentCreateInput>
  /** Connect one existing AdDistributionContent document */
  connect?: Maybe<AdDistributionContentWhereUniqueInput>
}

/** An edge in a connection. */
export type AdDistributionContentEdge = {
  __typename?: 'AdDistributionContentEdge'
  /** The item at the end of the edge. */
  node: AdDistributionContent
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type AdDistributionContentManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AdDistributionContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AdDistributionContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AdDistributionContentWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  compaignName?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  compaignName_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  compaignName_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  compaignName_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  compaignName_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  compaignName_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  compaignName_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  compaignName_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  compaignName_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  compaignName_not_ends_with?: Maybe<Scalars['String']>
  sponsored?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  sponsored_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  sponsored_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  sponsored_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  sponsored_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  sponsored_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  sponsored_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  sponsored_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  sponsored_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  sponsored_not_ends_with?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  targetUrl?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  targetUrl_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  targetUrl_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  targetUrl_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  targetUrl_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  targetUrl_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  targetUrl_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  targetUrl_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  targetUrl_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  targetUrl_not_ends_with?: Maybe<Scalars['String']>
  urlParameter?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  urlParameter_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  urlParameter_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  urlParameter_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  urlParameter_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  urlParameter_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  urlParameter_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  urlParameter_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  urlParameter_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  urlParameter_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  adDistributionManagement_every?: Maybe<AdDistributionManagementWhereInput>
  adDistributionManagement_some?: Maybe<AdDistributionManagementWhereInput>
  adDistributionManagement_none?: Maybe<AdDistributionManagementWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum AdDistributionContentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  CompaignNameAsc = 'compaignName_ASC',
  CompaignNameDesc = 'compaignName_DESC',
  SponsoredAsc = 'sponsored_ASC',
  SponsoredDesc = 'sponsored_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  TargetUrlAsc = 'targetUrl_ASC',
  TargetUrlDesc = 'targetUrl_DESC',
  UrlParameterAsc = 'urlParameter_ASC',
  UrlParameterDesc = 'urlParameter_DESC',
}

export type AdDistributionContentUpdateInput = {
  compaignName?: Maybe<Scalars['String']>
  sponsored?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  targetUrl?: Maybe<Scalars['String']>
  urlParameter?: Maybe<Scalars['String']>
  thumbnail?: Maybe<AssetUpdateOneInlineInput>
  adDistributionManagement?: Maybe<AdDistributionManagementUpdateManyInlineInput>
}

export type AdDistributionContentUpdateManyInlineInput = {
  /** Create and connect multiple AdDistributionContent documents */
  create?: Maybe<Array<AdDistributionContentCreateInput>>
  /** Connect multiple existing AdDistributionContent documents */
  connect?: Maybe<Array<AdDistributionContentConnectInput>>
  /** Override currently-connected documents with multiple existing AdDistributionContent documents */
  set?: Maybe<Array<AdDistributionContentWhereUniqueInput>>
  /** Update multiple AdDistributionContent documents */
  update?: Maybe<Array<AdDistributionContentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple AdDistributionContent documents */
  upsert?: Maybe<Array<AdDistributionContentUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple AdDistributionContent documents */
  disconnect?: Maybe<Array<AdDistributionContentWhereUniqueInput>>
  /** Delete multiple AdDistributionContent documents */
  delete?: Maybe<Array<AdDistributionContentWhereUniqueInput>>
}

export type AdDistributionContentUpdateManyInput = {
  compaignName?: Maybe<Scalars['String']>
  sponsored?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  targetUrl?: Maybe<Scalars['String']>
  urlParameter?: Maybe<Scalars['String']>
}

export type AdDistributionContentUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: AdDistributionContentWhereInput
  /** Update many input */
  data: AdDistributionContentUpdateManyInput
}

export type AdDistributionContentUpdateOneInlineInput = {
  /** Create and connect one AdDistributionContent document */
  create?: Maybe<AdDistributionContentCreateInput>
  /** Update single AdDistributionContent document */
  update?: Maybe<AdDistributionContentUpdateWithNestedWhereUniqueInput>
  /** Upsert single AdDistributionContent document */
  upsert?: Maybe<AdDistributionContentUpsertWithNestedWhereUniqueInput>
  /** Connect existing AdDistributionContent document */
  connect?: Maybe<AdDistributionContentWhereUniqueInput>
  /** Disconnect currently connected AdDistributionContent document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected AdDistributionContent document */
  delete?: Maybe<Scalars['Boolean']>
}

export type AdDistributionContentUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AdDistributionContentWhereUniqueInput
  /** Document to update */
  data: AdDistributionContentUpdateInput
}

export type AdDistributionContentUpsertInput = {
  /** Create document if it didn't exist */
  create: AdDistributionContentCreateInput
  /** Update document if it exists */
  update: AdDistributionContentUpdateInput
}

export type AdDistributionContentUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AdDistributionContentWhereUniqueInput
  /** Upsert data */
  data: AdDistributionContentUpsertInput
}

/** Identifies documents */
export type AdDistributionContentWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AdDistributionContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AdDistributionContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AdDistributionContentWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  compaignName?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  compaignName_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  compaignName_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  compaignName_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  compaignName_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  compaignName_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  compaignName_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  compaignName_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  compaignName_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  compaignName_not_ends_with?: Maybe<Scalars['String']>
  sponsored?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  sponsored_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  sponsored_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  sponsored_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  sponsored_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  sponsored_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  sponsored_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  sponsored_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  sponsored_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  sponsored_not_ends_with?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  targetUrl?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  targetUrl_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  targetUrl_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  targetUrl_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  targetUrl_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  targetUrl_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  targetUrl_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  targetUrl_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  targetUrl_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  targetUrl_not_ends_with?: Maybe<Scalars['String']>
  urlParameter?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  urlParameter_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  urlParameter_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  urlParameter_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  urlParameter_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  urlParameter_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  urlParameter_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  urlParameter_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  urlParameter_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  urlParameter_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  adDistributionManagement_every?: Maybe<AdDistributionManagementWhereInput>
  adDistributionManagement_some?: Maybe<AdDistributionManagementWhereInput>
  adDistributionManagement_none?: Maybe<AdDistributionManagementWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References AdDistributionContent record uniquely */
export type AdDistributionContentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export enum AdDistributionFormat {
  SingleList = 'SingleList',
  SingleCard = 'SingleCard',
}

export type AdDistributionManagement = Node & {
  __typename?: 'AdDistributionManagement'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<AdDistributionManagement>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  content?: Maybe<AdDistributionContent>
  destination: AdDeliveryDestination
  format: AdDistributionFormat
  scheduledIn: Array<ScheduledOperation>
  /** List of AdDistributionManagement versions */
  history: Array<Version>
}

export type AdDistributionManagementDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type AdDistributionManagementCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionManagementUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionManagementPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionManagementContentArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionManagementScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type AdDistributionManagementHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type AdDistributionManagementConnectInput = {
  /** Document to connect */
  where: AdDistributionManagementWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type AdDistributionManagementConnection = {
  __typename?: 'AdDistributionManagementConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<AdDistributionManagementEdge>
  aggregate: Aggregate
}

export type AdDistributionManagementCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  content?: Maybe<AdDistributionContentCreateOneInlineInput>
  destination: AdDeliveryDestination
  format: AdDistributionFormat
}

export type AdDistributionManagementCreateManyInlineInput = {
  /** Create and connect multiple existing AdDistributionManagement documents */
  create?: Maybe<Array<AdDistributionManagementCreateInput>>
  /** Connect multiple existing AdDistributionManagement documents */
  connect?: Maybe<Array<AdDistributionManagementWhereUniqueInput>>
}

export type AdDistributionManagementCreateOneInlineInput = {
  /** Create and connect one AdDistributionManagement document */
  create?: Maybe<AdDistributionManagementCreateInput>
  /** Connect one existing AdDistributionManagement document */
  connect?: Maybe<AdDistributionManagementWhereUniqueInput>
}

/** An edge in a connection. */
export type AdDistributionManagementEdge = {
  __typename?: 'AdDistributionManagementEdge'
  /** The item at the end of the edge. */
  node: AdDistributionManagement
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type AdDistributionManagementManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AdDistributionManagementWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AdDistributionManagementWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AdDistributionManagementWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  content?: Maybe<AdDistributionContentWhereInput>
  destination?: Maybe<AdDeliveryDestination>
  /** All values that are not equal to given value. */
  destination_not?: Maybe<AdDeliveryDestination>
  /** All values that are contained in given list. */
  destination_in?: Maybe<Array<AdDeliveryDestination>>
  /** All values that are not contained in given list. */
  destination_not_in?: Maybe<Array<AdDeliveryDestination>>
  format?: Maybe<AdDistributionFormat>
  /** All values that are not equal to given value. */
  format_not?: Maybe<AdDistributionFormat>
  /** All values that are contained in given list. */
  format_in?: Maybe<Array<AdDistributionFormat>>
  /** All values that are not contained in given list. */
  format_not_in?: Maybe<Array<AdDistributionFormat>>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum AdDistributionManagementOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  DestinationAsc = 'destination_ASC',
  DestinationDesc = 'destination_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
}

export type AdDistributionManagementUpdateInput = {
  content?: Maybe<AdDistributionContentUpdateOneInlineInput>
  destination?: Maybe<AdDeliveryDestination>
  format?: Maybe<AdDistributionFormat>
}

export type AdDistributionManagementUpdateManyInlineInput = {
  /** Create and connect multiple AdDistributionManagement documents */
  create?: Maybe<Array<AdDistributionManagementCreateInput>>
  /** Connect multiple existing AdDistributionManagement documents */
  connect?: Maybe<Array<AdDistributionManagementConnectInput>>
  /** Override currently-connected documents with multiple existing AdDistributionManagement documents */
  set?: Maybe<Array<AdDistributionManagementWhereUniqueInput>>
  /** Update multiple AdDistributionManagement documents */
  update?: Maybe<Array<AdDistributionManagementUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple AdDistributionManagement documents */
  upsert?: Maybe<Array<AdDistributionManagementUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple AdDistributionManagement documents */
  disconnect?: Maybe<Array<AdDistributionManagementWhereUniqueInput>>
  /** Delete multiple AdDistributionManagement documents */
  delete?: Maybe<Array<AdDistributionManagementWhereUniqueInput>>
}

export type AdDistributionManagementUpdateManyInput = {
  format?: Maybe<AdDistributionFormat>
}

export type AdDistributionManagementUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: AdDistributionManagementWhereInput
  /** Update many input */
  data: AdDistributionManagementUpdateManyInput
}

export type AdDistributionManagementUpdateOneInlineInput = {
  /** Create and connect one AdDistributionManagement document */
  create?: Maybe<AdDistributionManagementCreateInput>
  /** Update single AdDistributionManagement document */
  update?: Maybe<AdDistributionManagementUpdateWithNestedWhereUniqueInput>
  /** Upsert single AdDistributionManagement document */
  upsert?: Maybe<AdDistributionManagementUpsertWithNestedWhereUniqueInput>
  /** Connect existing AdDistributionManagement document */
  connect?: Maybe<AdDistributionManagementWhereUniqueInput>
  /** Disconnect currently connected AdDistributionManagement document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected AdDistributionManagement document */
  delete?: Maybe<Scalars['Boolean']>
}

export type AdDistributionManagementUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AdDistributionManagementWhereUniqueInput
  /** Document to update */
  data: AdDistributionManagementUpdateInput
}

export type AdDistributionManagementUpsertInput = {
  /** Create document if it didn't exist */
  create: AdDistributionManagementCreateInput
  /** Update document if it exists */
  update: AdDistributionManagementUpdateInput
}

export type AdDistributionManagementUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AdDistributionManagementWhereUniqueInput
  /** Upsert data */
  data: AdDistributionManagementUpsertInput
}

/** Identifies documents */
export type AdDistributionManagementWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AdDistributionManagementWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AdDistributionManagementWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AdDistributionManagementWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  content?: Maybe<AdDistributionContentWhereInput>
  destination?: Maybe<AdDeliveryDestination>
  /** All values that are not equal to given value. */
  destination_not?: Maybe<AdDeliveryDestination>
  /** All values that are contained in given list. */
  destination_in?: Maybe<Array<AdDeliveryDestination>>
  /** All values that are not contained in given list. */
  destination_not_in?: Maybe<Array<AdDeliveryDestination>>
  format?: Maybe<AdDistributionFormat>
  /** All values that are not equal to given value. */
  format_not?: Maybe<AdDistributionFormat>
  /** All values that are contained in given list. */
  format_in?: Maybe<Array<AdDistributionFormat>>
  /** All values that are not contained in given list. */
  format_not_in?: Maybe<Array<AdDistributionFormat>>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References AdDistributionManagement record uniquely */
export type AdDistributionManagementWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  destination?: Maybe<AdDeliveryDestination>
}

export type Aggregate = {
  __typename?: 'Aggregate'
  count: Scalars['Int']
}

export type Article = Node & {
  __typename?: 'Article'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Article>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  key?: Maybe<Scalars['String']>
  title: Scalars['String']
  content?: Maybe<RichText>
  excerpt?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  isStory: Scalars['Boolean']
  storyPage?: Maybe<Scalars['Int']>
  publishDate: Scalars['DateTime']
  originalSource?: Maybe<Scalars['String']>
  isOnlyUser: Scalars['Boolean']
  company?: Maybe<Scalars['String']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  thumbnail?: Maybe<Asset>
  tags: Array<Tag>
  provider?: Maybe<Provider>
  story?: Maybe<Story>
  author?: Maybe<Author>
  categories: Array<Category>
  issues: Array<Issue>
  scheduledIn: Array<ScheduledOperation>
  /** List of Article versions */
  history: Array<Version>
}

export type ArticleDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type ArticleCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ArticleUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ArticlePublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ArticleThumbnailArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ArticleTagsArgs = {
  where?: Maybe<TagWhereInput>
  orderBy?: Maybe<TagOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type ArticleProviderArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ArticleStoryArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ArticleAuthorArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ArticleCategoriesArgs = {
  where?: Maybe<CategoryWhereInput>
  orderBy?: Maybe<CategoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type ArticleIssuesArgs = {
  where?: Maybe<IssueWhereInput>
  orderBy?: Maybe<IssueOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type ArticleScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type ArticleHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type ArticleConnectInput = {
  /** Document to connect */
  where: ArticleWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type ArticleConnection = {
  __typename?: 'ArticleConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<ArticleEdge>
  aggregate: Aggregate
}

export type ArticleCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  key?: Maybe<Scalars['String']>
  title: Scalars['String']
  content?: Maybe<Scalars['RichTextAST']>
  excerpt?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  isStory: Scalars['Boolean']
  storyPage?: Maybe<Scalars['Int']>
  publishDate: Scalars['DateTime']
  originalSource?: Maybe<Scalars['String']>
  isOnlyUser: Scalars['Boolean']
  company?: Maybe<Scalars['String']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  thumbnail?: Maybe<AssetCreateOneInlineInput>
  tags?: Maybe<TagCreateManyInlineInput>
  provider?: Maybe<ProviderCreateOneInlineInput>
  story?: Maybe<StoryCreateOneInlineInput>
  author?: Maybe<AuthorCreateOneInlineInput>
  categories?: Maybe<CategoryCreateManyInlineInput>
  issues?: Maybe<IssueCreateManyInlineInput>
}

export type ArticleCreateManyInlineInput = {
  /** Create and connect multiple existing Article documents */
  create?: Maybe<Array<ArticleCreateInput>>
  /** Connect multiple existing Article documents */
  connect?: Maybe<Array<ArticleWhereUniqueInput>>
}

export type ArticleCreateOneInlineInput = {
  /** Create and connect one Article document */
  create?: Maybe<ArticleCreateInput>
  /** Connect one existing Article document */
  connect?: Maybe<ArticleWhereUniqueInput>
}

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge'
  /** The item at the end of the edge. */
  node: Article
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type ArticleManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ArticleWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ArticleWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ArticleWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  key?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  key_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  key_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  key_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  key_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  key_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  key_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  key_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  key_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  key_not_ends_with?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  excerpt_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  excerpt_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  excerpt_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  excerpt_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  excerpt_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  excerpt_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  excerpt_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  excerpt_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  excerpt_not_ends_with?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  slug_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  slug_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  slug_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  slug_not_ends_with?: Maybe<Scalars['String']>
  isStory?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isStory_not?: Maybe<Scalars['Boolean']>
  storyPage?: Maybe<Scalars['Int']>
  /** All values that are not equal to given value. */
  storyPage_not?: Maybe<Scalars['Int']>
  /** All values that are contained in given list. */
  storyPage_in?: Maybe<Array<Scalars['Int']>>
  /** All values that are not contained in given list. */
  storyPage_not_in?: Maybe<Array<Scalars['Int']>>
  /** All values less than the given value. */
  storyPage_lt?: Maybe<Scalars['Int']>
  /** All values less than or equal the given value. */
  storyPage_lte?: Maybe<Scalars['Int']>
  /** All values greater than the given value. */
  storyPage_gt?: Maybe<Scalars['Int']>
  /** All values greater than or equal the given value. */
  storyPage_gte?: Maybe<Scalars['Int']>
  publishDate?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishDate_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishDate_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishDate_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishDate_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishDate_gte?: Maybe<Scalars['DateTime']>
  originalSource?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  originalSource_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  originalSource_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  originalSource_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  originalSource_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  originalSource_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  originalSource_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  originalSource_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  originalSource_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  originalSource_not_ends_with?: Maybe<Scalars['String']>
  isOnlyUser?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isOnlyUser_not?: Maybe<Scalars['Boolean']>
  company?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  company_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  company_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  company_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  company_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  company_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  company_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  company_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  company_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  company_not_ends_with?: Maybe<Scalars['String']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogFirstDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogFirstDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogFirstDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogFirstDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogFirstDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogFirstDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogFirstDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogFirstDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogFirstDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogSecondDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogSecondDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogSecondDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogSecondDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogSecondDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogSecondDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogSecondDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogSecondDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogSecondDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogThirdDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogThirdDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogThirdDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogThirdDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogThirdDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogThirdDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogThirdDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogThirdDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogThirdDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  tags_every?: Maybe<TagWhereInput>
  tags_some?: Maybe<TagWhereInput>
  tags_none?: Maybe<TagWhereInput>
  provider?: Maybe<ProviderWhereInput>
  story?: Maybe<StoryWhereInput>
  author?: Maybe<AuthorWhereInput>
  categories_every?: Maybe<CategoryWhereInput>
  categories_some?: Maybe<CategoryWhereInput>
  categories_none?: Maybe<CategoryWhereInput>
  issues_every?: Maybe<IssueWhereInput>
  issues_some?: Maybe<IssueWhereInput>
  issues_none?: Maybe<IssueWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum ArticleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ExcerptAsc = 'excerpt_ASC',
  ExcerptDesc = 'excerpt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  IsStoryAsc = 'isStory_ASC',
  IsStoryDesc = 'isStory_DESC',
  StoryPageAsc = 'storyPage_ASC',
  StoryPageDesc = 'storyPage_DESC',
  PublishDateAsc = 'publishDate_ASC',
  PublishDateDesc = 'publishDate_DESC',
  OriginalSourceAsc = 'originalSource_ASC',
  OriginalSourceDesc = 'originalSource_DESC',
  IsOnlyUserAsc = 'isOnlyUser_ASC',
  IsOnlyUserDesc = 'isOnlyUser_DESC',
  CompanyAsc = 'company_ASC',
  CompanyDesc = 'company_DESC',
  CatalogFirstDepthCategoryIdAsc = 'catalogFirstDepthCategoryId_ASC',
  CatalogFirstDepthCategoryIdDesc = 'catalogFirstDepthCategoryId_DESC',
  CatalogSecondDepthCategoryIdAsc = 'catalogSecondDepthCategoryId_ASC',
  CatalogSecondDepthCategoryIdDesc = 'catalogSecondDepthCategoryId_DESC',
  CatalogThirdDepthCategoryIdAsc = 'catalogThirdDepthCategoryId_ASC',
  CatalogThirdDepthCategoryIdDesc = 'catalogThirdDepthCategoryId_DESC',
}

export type ArticleUpdateInput = {
  key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  excerpt?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  isStory?: Maybe<Scalars['Boolean']>
  storyPage?: Maybe<Scalars['Int']>
  publishDate?: Maybe<Scalars['DateTime']>
  originalSource?: Maybe<Scalars['String']>
  isOnlyUser?: Maybe<Scalars['Boolean']>
  company?: Maybe<Scalars['String']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  thumbnail?: Maybe<AssetUpdateOneInlineInput>
  tags?: Maybe<TagUpdateManyInlineInput>
  provider?: Maybe<ProviderUpdateOneInlineInput>
  story?: Maybe<StoryUpdateOneInlineInput>
  author?: Maybe<AuthorUpdateOneInlineInput>
  categories?: Maybe<CategoryUpdateManyInlineInput>
  issues?: Maybe<IssueUpdateManyInlineInput>
}

export type ArticleUpdateManyInlineInput = {
  /** Create and connect multiple Article documents */
  create?: Maybe<Array<ArticleCreateInput>>
  /** Connect multiple existing Article documents */
  connect?: Maybe<Array<ArticleConnectInput>>
  /** Override currently-connected documents with multiple existing Article documents */
  set?: Maybe<Array<ArticleWhereUniqueInput>>
  /** Update multiple Article documents */
  update?: Maybe<Array<ArticleUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Article documents */
  upsert?: Maybe<Array<ArticleUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Article documents */
  disconnect?: Maybe<Array<ArticleWhereUniqueInput>>
  /** Delete multiple Article documents */
  delete?: Maybe<Array<ArticleWhereUniqueInput>>
}

export type ArticleUpdateManyInput = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  excerpt?: Maybe<Scalars['String']>
  isStory?: Maybe<Scalars['Boolean']>
  storyPage?: Maybe<Scalars['Int']>
  publishDate?: Maybe<Scalars['DateTime']>
  isOnlyUser?: Maybe<Scalars['Boolean']>
  company?: Maybe<Scalars['String']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
}

export type ArticleUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: ArticleWhereInput
  /** Update many input */
  data: ArticleUpdateManyInput
}

export type ArticleUpdateOneInlineInput = {
  /** Create and connect one Article document */
  create?: Maybe<ArticleCreateInput>
  /** Update single Article document */
  update?: Maybe<ArticleUpdateWithNestedWhereUniqueInput>
  /** Upsert single Article document */
  upsert?: Maybe<ArticleUpsertWithNestedWhereUniqueInput>
  /** Connect existing Article document */
  connect?: Maybe<ArticleWhereUniqueInput>
  /** Disconnect currently connected Article document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Article document */
  delete?: Maybe<Scalars['Boolean']>
}

export type ArticleUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ArticleWhereUniqueInput
  /** Document to update */
  data: ArticleUpdateInput
}

export type ArticleUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleCreateInput
  /** Update document if it exists */
  update: ArticleUpdateInput
}

export type ArticleUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ArticleWhereUniqueInput
  /** Upsert data */
  data: ArticleUpsertInput
}

/** Identifies documents */
export type ArticleWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ArticleWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ArticleWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ArticleWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  key?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  key_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  key_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  key_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  key_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  key_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  key_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  key_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  key_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  key_not_ends_with?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  excerpt_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  excerpt_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  excerpt_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  excerpt_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  excerpt_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  excerpt_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  excerpt_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  excerpt_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  excerpt_not_ends_with?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  slug_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  slug_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  slug_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  slug_not_ends_with?: Maybe<Scalars['String']>
  isStory?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isStory_not?: Maybe<Scalars['Boolean']>
  storyPage?: Maybe<Scalars['Int']>
  /** All values that are not equal to given value. */
  storyPage_not?: Maybe<Scalars['Int']>
  /** All values that are contained in given list. */
  storyPage_in?: Maybe<Array<Scalars['Int']>>
  /** All values that are not contained in given list. */
  storyPage_not_in?: Maybe<Array<Scalars['Int']>>
  /** All values less than the given value. */
  storyPage_lt?: Maybe<Scalars['Int']>
  /** All values less than or equal the given value. */
  storyPage_lte?: Maybe<Scalars['Int']>
  /** All values greater than the given value. */
  storyPage_gt?: Maybe<Scalars['Int']>
  /** All values greater than or equal the given value. */
  storyPage_gte?: Maybe<Scalars['Int']>
  publishDate?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishDate_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishDate_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishDate_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishDate_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishDate_gte?: Maybe<Scalars['DateTime']>
  originalSource?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  originalSource_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  originalSource_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  originalSource_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  originalSource_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  originalSource_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  originalSource_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  originalSource_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  originalSource_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  originalSource_not_ends_with?: Maybe<Scalars['String']>
  isOnlyUser?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isOnlyUser_not?: Maybe<Scalars['Boolean']>
  company?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  company_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  company_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  company_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  company_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  company_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  company_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  company_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  company_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  company_not_ends_with?: Maybe<Scalars['String']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogFirstDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogFirstDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogFirstDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogFirstDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogFirstDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogFirstDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogFirstDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogFirstDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogFirstDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogSecondDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogSecondDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogSecondDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogSecondDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogSecondDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogSecondDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogSecondDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogSecondDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogSecondDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogThirdDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogThirdDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogThirdDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogThirdDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogThirdDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogThirdDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogThirdDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogThirdDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogThirdDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  tags_every?: Maybe<TagWhereInput>
  tags_some?: Maybe<TagWhereInput>
  tags_none?: Maybe<TagWhereInput>
  provider?: Maybe<ProviderWhereInput>
  story?: Maybe<StoryWhereInput>
  author?: Maybe<AuthorWhereInput>
  categories_every?: Maybe<CategoryWhereInput>
  categories_some?: Maybe<CategoryWhereInput>
  categories_none?: Maybe<CategoryWhereInput>
  issues_every?: Maybe<IssueWhereInput>
  issues_some?: Maybe<IssueWhereInput>
  issues_none?: Maybe<IssueWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Article record uniquely */
export type ArticleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  originalSource?: Maybe<Scalars['String']>
}

/** Asset system model */
export type Asset = Node & {
  __typename?: 'Asset'
  /** System stage field */
  stage: Stage
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<Asset>
  /** Get the document in other stages */
  documentInStages: Array<Asset>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  /** The file handle */
  handle: Scalars['String']
  /** The file name */
  fileName: Scalars['String']
  /** The height of the file */
  height?: Maybe<Scalars['Float']>
  /** The file width */
  width?: Maybe<Scalars['Float']>
  /** The file size */
  size?: Maybe<Scalars['Float']>
  /** The mime type of the file */
  mimeType?: Maybe<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  thumbnailStory: Array<Story>
  thumbnailProvider: Array<Provider>
  thumbnailInformation: Array<Information>
  thumbnailArticle: Array<Article>
  authorThumbnail: Array<Author>
  thumbnailAdDistributionContent: Array<AdDistributionContent>
  scheduledIn: Array<ScheduledOperation>
  /** List of Asset versions */
  history: Array<Version>
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars['String']
}

/** Asset system model */
export type AssetLocalizationsArgs = {
  locales?: Array<Locale>
  includeCurrent?: Scalars['Boolean']
}

/** Asset system model */
export type AssetDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Asset system model */
export type AssetCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetThumbnailStoryArgs = {
  where?: Maybe<StoryWhereInput>
  orderBy?: Maybe<StoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetThumbnailProviderArgs = {
  where?: Maybe<ProviderWhereInput>
  orderBy?: Maybe<ProviderOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetThumbnailInformationArgs = {
  where?: Maybe<InformationWhereInput>
  orderBy?: Maybe<InformationOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetThumbnailArticleArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetAuthorThumbnailArgs = {
  where?: Maybe<AuthorWhereInput>
  orderBy?: Maybe<AuthorOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetThumbnailAdDistributionContentArgs = {
  where?: Maybe<AdDistributionContentWhereInput>
  orderBy?: Maybe<AdDistributionContentOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

/** Asset system model */
export type AssetUrlArgs = {
  transformation?: Maybe<AssetTransformationInput>
}

export type AssetConnectInput = {
  /** Document to connect */
  where: AssetWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: 'AssetConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<AssetEdge>
  aggregate: Aggregate
}

export type AssetCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  handle: Scalars['String']
  fileName: Scalars['String']
  height?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  mimeType?: Maybe<Scalars['String']>
  thumbnailStory?: Maybe<StoryCreateManyInlineInput>
  thumbnailProvider?: Maybe<ProviderCreateManyInlineInput>
  thumbnailInformation?: Maybe<InformationCreateManyInlineInput>
  thumbnailArticle?: Maybe<ArticleCreateManyInlineInput>
  authorThumbnail?: Maybe<AuthorCreateManyInlineInput>
  thumbnailAdDistributionContent?: Maybe<AdDistributionContentCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: Maybe<AssetCreateLocalizationsInput>
}

export type AssetCreateLocalizationDataInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  handle: Scalars['String']
  fileName: Scalars['String']
  height?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  mimeType?: Maybe<Scalars['String']>
}

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput
  locale: Locale
}

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: Maybe<Array<AssetCreateLocalizationInput>>
}

export type AssetCreateManyInlineInput = {
  /** Create and connect multiple existing Asset documents */
  create?: Maybe<Array<AssetCreateInput>>
  /** Connect multiple existing Asset documents */
  connect?: Maybe<Array<AssetWhereUniqueInput>>
}

export type AssetCreateOneInlineInput = {
  /** Create and connect one Asset document */
  create?: Maybe<AssetCreateInput>
  /** Connect one existing Asset document */
  connect?: Maybe<AssetWhereUniqueInput>
}

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: 'AssetEdge'
  /** The item at the end of the edge. */
  node: Asset
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AssetWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AssetWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AssetWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnailStory_every?: Maybe<StoryWhereInput>
  thumbnailStory_some?: Maybe<StoryWhereInput>
  thumbnailStory_none?: Maybe<StoryWhereInput>
  thumbnailProvider_every?: Maybe<ProviderWhereInput>
  thumbnailProvider_some?: Maybe<ProviderWhereInput>
  thumbnailProvider_none?: Maybe<ProviderWhereInput>
  thumbnailInformation_every?: Maybe<InformationWhereInput>
  thumbnailInformation_some?: Maybe<InformationWhereInput>
  thumbnailInformation_none?: Maybe<InformationWhereInput>
  thumbnailArticle_every?: Maybe<ArticleWhereInput>
  thumbnailArticle_some?: Maybe<ArticleWhereInput>
  thumbnailArticle_none?: Maybe<ArticleWhereInput>
  authorThumbnail_every?: Maybe<AuthorWhereInput>
  authorThumbnail_some?: Maybe<AuthorWhereInput>
  authorThumbnail_none?: Maybe<AuthorWhereInput>
  thumbnailAdDistributionContent_every?: Maybe<AdDistributionContentWhereInput>
  thumbnailAdDistributionContent_some?: Maybe<AdDistributionContentWhereInput>
  thumbnailAdDistributionContent_none?: Maybe<AdDistributionContentWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum AssetOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  image?: Maybe<ImageTransformationInput>
  document?: Maybe<DocumentTransformationInput>
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: Maybe<Scalars['Boolean']>
}

export type AssetUpdateInput = {
  handle?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  mimeType?: Maybe<Scalars['String']>
  thumbnailStory?: Maybe<StoryUpdateManyInlineInput>
  thumbnailProvider?: Maybe<ProviderUpdateManyInlineInput>
  thumbnailInformation?: Maybe<InformationUpdateManyInlineInput>
  thumbnailArticle?: Maybe<ArticleUpdateManyInlineInput>
  authorThumbnail?: Maybe<AuthorUpdateManyInlineInput>
  thumbnailAdDistributionContent?: Maybe<AdDistributionContentUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: Maybe<AssetUpdateLocalizationsInput>
}

export type AssetUpdateLocalizationDataInput = {
  handle?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  mimeType?: Maybe<Scalars['String']>
}

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput
  locale: Locale
}

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: Maybe<Array<AssetCreateLocalizationInput>>
  /** Localizations to update */
  update?: Maybe<Array<AssetUpdateLocalizationInput>>
  upsert?: Maybe<Array<AssetUpsertLocalizationInput>>
  /** Localizations to delete */
  delete?: Maybe<Array<Locale>>
}

export type AssetUpdateManyInlineInput = {
  /** Create and connect multiple Asset documents */
  create?: Maybe<Array<AssetCreateInput>>
  /** Connect multiple existing Asset documents */
  connect?: Maybe<Array<AssetConnectInput>>
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: Maybe<Array<AssetWhereUniqueInput>>
  /** Update multiple Asset documents */
  update?: Maybe<Array<AssetUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Asset documents */
  upsert?: Maybe<Array<AssetUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Asset documents */
  disconnect?: Maybe<Array<AssetWhereUniqueInput>>
  /** Delete multiple Asset documents */
  delete?: Maybe<Array<AssetWhereUniqueInput>>
}

export type AssetUpdateManyInput = {
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  mimeType?: Maybe<Scalars['String']>
  /** Optional updates to localizations */
  localizations?: Maybe<AssetUpdateManyLocalizationsInput>
}

export type AssetUpdateManyLocalizationDataInput = {
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  mimeType?: Maybe<Scalars['String']>
}

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput
  locale: Locale
}

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: Maybe<Array<AssetUpdateManyLocalizationInput>>
}

export type AssetUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: AssetWhereInput
  /** Update many input */
  data: AssetUpdateManyInput
}

export type AssetUpdateOneInlineInput = {
  /** Create and connect one Asset document */
  create?: Maybe<AssetCreateInput>
  /** Update single Asset document */
  update?: Maybe<AssetUpdateWithNestedWhereUniqueInput>
  /** Upsert single Asset document */
  upsert?: Maybe<AssetUpsertWithNestedWhereUniqueInput>
  /** Connect existing Asset document */
  connect?: Maybe<AssetWhereUniqueInput>
  /** Disconnect currently connected Asset document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Asset document */
  delete?: Maybe<Scalars['Boolean']>
}

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AssetWhereUniqueInput
  /** Document to update */
  data: AssetUpdateInput
}

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput
  /** Update document if it exists */
  update: AssetUpdateInput
}

export type AssetUpsertLocalizationInput = {
  update: AssetUpdateLocalizationDataInput
  create: AssetCreateLocalizationDataInput
  locale: Locale
}

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AssetWhereUniqueInput
  /** Upsert data */
  data: AssetUpsertInput
}

/** Identifies documents */
export type AssetWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AssetWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AssetWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AssetWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  handle?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  handle_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  handle_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  handle_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  handle_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  handle_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  handle_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  handle_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  handle_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  handle_not_ends_with?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  fileName_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  fileName_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  fileName_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  fileName_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  fileName_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  fileName_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  fileName_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  fileName_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  fileName_not_ends_with?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Float']>
  /** All values that are not equal to given value. */
  height_not?: Maybe<Scalars['Float']>
  /** All values that are contained in given list. */
  height_in?: Maybe<Array<Scalars['Float']>>
  /** All values that are not contained in given list. */
  height_not_in?: Maybe<Array<Scalars['Float']>>
  /** All values less than the given value. */
  height_lt?: Maybe<Scalars['Float']>
  /** All values less than or equal the given value. */
  height_lte?: Maybe<Scalars['Float']>
  /** All values greater than the given value. */
  height_gt?: Maybe<Scalars['Float']>
  /** All values greater than or equal the given value. */
  height_gte?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  /** All values that are not equal to given value. */
  width_not?: Maybe<Scalars['Float']>
  /** All values that are contained in given list. */
  width_in?: Maybe<Array<Scalars['Float']>>
  /** All values that are not contained in given list. */
  width_not_in?: Maybe<Array<Scalars['Float']>>
  /** All values less than the given value. */
  width_lt?: Maybe<Scalars['Float']>
  /** All values less than or equal the given value. */
  width_lte?: Maybe<Scalars['Float']>
  /** All values greater than the given value. */
  width_gt?: Maybe<Scalars['Float']>
  /** All values greater than or equal the given value. */
  width_gte?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  /** All values that are not equal to given value. */
  size_not?: Maybe<Scalars['Float']>
  /** All values that are contained in given list. */
  size_in?: Maybe<Array<Scalars['Float']>>
  /** All values that are not contained in given list. */
  size_not_in?: Maybe<Array<Scalars['Float']>>
  /** All values less than the given value. */
  size_lt?: Maybe<Scalars['Float']>
  /** All values less than or equal the given value. */
  size_lte?: Maybe<Scalars['Float']>
  /** All values greater than the given value. */
  size_gt?: Maybe<Scalars['Float']>
  /** All values greater than or equal the given value. */
  size_gte?: Maybe<Scalars['Float']>
  mimeType?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  mimeType_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  mimeType_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  mimeType_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  mimeType_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  mimeType_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  mimeType_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  mimeType_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  mimeType_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnailStory_every?: Maybe<StoryWhereInput>
  thumbnailStory_some?: Maybe<StoryWhereInput>
  thumbnailStory_none?: Maybe<StoryWhereInput>
  thumbnailProvider_every?: Maybe<ProviderWhereInput>
  thumbnailProvider_some?: Maybe<ProviderWhereInput>
  thumbnailProvider_none?: Maybe<ProviderWhereInput>
  thumbnailInformation_every?: Maybe<InformationWhereInput>
  thumbnailInformation_some?: Maybe<InformationWhereInput>
  thumbnailInformation_none?: Maybe<InformationWhereInput>
  thumbnailArticle_every?: Maybe<ArticleWhereInput>
  thumbnailArticle_some?: Maybe<ArticleWhereInput>
  thumbnailArticle_none?: Maybe<ArticleWhereInput>
  authorThumbnail_every?: Maybe<AuthorWhereInput>
  authorThumbnail_some?: Maybe<AuthorWhereInput>
  authorThumbnail_none?: Maybe<AuthorWhereInput>
  thumbnailAdDistributionContent_every?: Maybe<AdDistributionContentWhereInput>
  thumbnailAdDistributionContent_some?: Maybe<AdDistributionContentWhereInput>
  thumbnailAdDistributionContent_none?: Maybe<AdDistributionContentWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type Author = Node & {
  __typename?: 'Author'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Author>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  description?: Maybe<RichText>
  details?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  thumbnail?: Maybe<Asset>
  article: Array<Article>
  scheduledIn: Array<ScheduledOperation>
  /** List of Author versions */
  history: Array<Version>
}

export type AuthorDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type AuthorCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AuthorUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AuthorPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AuthorThumbnailArgs = {
  locales?: Maybe<Array<Locale>>
}

export type AuthorArticleArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type AuthorScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type AuthorHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type AuthorConnectInput = {
  /** Document to connect */
  where: AuthorWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type AuthorConnection = {
  __typename?: 'AuthorConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<AuthorEdge>
  aggregate: Aggregate
}

export type AuthorCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  description?: Maybe<Scalars['RichTextAST']>
  details?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  thumbnail?: Maybe<AssetCreateOneInlineInput>
  article?: Maybe<ArticleCreateManyInlineInput>
}

export type AuthorCreateManyInlineInput = {
  /** Create and connect multiple existing Author documents */
  create?: Maybe<Array<AuthorCreateInput>>
  /** Connect multiple existing Author documents */
  connect?: Maybe<Array<AuthorWhereUniqueInput>>
}

export type AuthorCreateOneInlineInput = {
  /** Create and connect one Author document */
  create?: Maybe<AuthorCreateInput>
  /** Connect one existing Author document */
  connect?: Maybe<AuthorWhereUniqueInput>
}

/** An edge in a connection. */
export type AuthorEdge = {
  __typename?: 'AuthorEdge'
  /** The item at the end of the edge. */
  node: Author
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type AuthorManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AuthorWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AuthorWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AuthorWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  details?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  details_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  details_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  details_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  details_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  details_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  details_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  details_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  details_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  details_not_ends_with?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  link_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  link_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  link_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  link_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  link_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  link_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  link_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  link_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  link_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  article_every?: Maybe<ArticleWhereInput>
  article_some?: Maybe<ArticleWhereInput>
  article_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum AuthorOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DetailsAsc = 'details_ASC',
  DetailsDesc = 'details_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
}

export type AuthorUpdateInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['RichTextAST']>
  details?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  thumbnail?: Maybe<AssetUpdateOneInlineInput>
  article?: Maybe<ArticleUpdateManyInlineInput>
}

export type AuthorUpdateManyInlineInput = {
  /** Create and connect multiple Author documents */
  create?: Maybe<Array<AuthorCreateInput>>
  /** Connect multiple existing Author documents */
  connect?: Maybe<Array<AuthorConnectInput>>
  /** Override currently-connected documents with multiple existing Author documents */
  set?: Maybe<Array<AuthorWhereUniqueInput>>
  /** Update multiple Author documents */
  update?: Maybe<Array<AuthorUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Author documents */
  upsert?: Maybe<Array<AuthorUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Author documents */
  disconnect?: Maybe<Array<AuthorWhereUniqueInput>>
  /** Delete multiple Author documents */
  delete?: Maybe<Array<AuthorWhereUniqueInput>>
}

export type AuthorUpdateManyInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['RichTextAST']>
  details?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
}

export type AuthorUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: AuthorWhereInput
  /** Update many input */
  data: AuthorUpdateManyInput
}

export type AuthorUpdateOneInlineInput = {
  /** Create and connect one Author document */
  create?: Maybe<AuthorCreateInput>
  /** Update single Author document */
  update?: Maybe<AuthorUpdateWithNestedWhereUniqueInput>
  /** Upsert single Author document */
  upsert?: Maybe<AuthorUpsertWithNestedWhereUniqueInput>
  /** Connect existing Author document */
  connect?: Maybe<AuthorWhereUniqueInput>
  /** Disconnect currently connected Author document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Author document */
  delete?: Maybe<Scalars['Boolean']>
}

export type AuthorUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AuthorWhereUniqueInput
  /** Document to update */
  data: AuthorUpdateInput
}

export type AuthorUpsertInput = {
  /** Create document if it didn't exist */
  create: AuthorCreateInput
  /** Update document if it exists */
  update: AuthorUpdateInput
}

export type AuthorUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: AuthorWhereUniqueInput
  /** Upsert data */
  data: AuthorUpsertInput
}

/** Identifies documents */
export type AuthorWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<AuthorWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<AuthorWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<AuthorWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  details?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  details_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  details_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  details_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  details_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  details_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  details_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  details_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  details_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  details_not_ends_with?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  link_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  link_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  link_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  link_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  link_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  link_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  link_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  link_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  link_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  article_every?: Maybe<ArticleWhereInput>
  article_some?: Maybe<ArticleWhereInput>
  article_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Author record uniquely */
export type AuthorWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type BatchPayload = {
  __typename?: 'BatchPayload'
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long']
}

export enum CatalogCategoryType {
  PlasticFilm = 'PlasticFilm',
  Material = 'Material',
  GeneralChemistry = 'GeneralChemistry',
}

export type Category = Node & {
  __typename?: 'Category'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Category>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  articles: Array<Article>
  scheduledIn: Array<ScheduledOperation>
  /** List of Category versions */
  history: Array<Version>
}

export type CategoryDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type CategoryCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type CategoryUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type CategoryPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type CategoryArticlesArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type CategoryScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type CategoryHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type CategoryConnectInput = {
  /** Document to connect */
  where: CategoryWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<CategoryEdge>
  aggregate: Aggregate
}

export type CategoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  articles?: Maybe<ArticleCreateManyInlineInput>
}

export type CategoryCreateManyInlineInput = {
  /** Create and connect multiple existing Category documents */
  create?: Maybe<Array<CategoryCreateInput>>
  /** Connect multiple existing Category documents */
  connect?: Maybe<Array<CategoryWhereUniqueInput>>
}

export type CategoryCreateOneInlineInput = {
  /** Create and connect one Category document */
  create?: Maybe<CategoryCreateInput>
  /** Connect one existing Category document */
  connect?: Maybe<CategoryWhereUniqueInput>
}

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge'
  /** The item at the end of the edge. */
  node: Category
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type CategoryManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CategoryWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  articles_every?: Maybe<ArticleWhereInput>
  articles_some?: Maybe<ArticleWhereInput>
  articles_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum CategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
}

export enum CategorySection {
  Chemistry = 'Chemistry',
}

export type CategoryUpdateInput = {
  title?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  articles?: Maybe<ArticleUpdateManyInlineInput>
}

export type CategoryUpdateManyInlineInput = {
  /** Create and connect multiple Category documents */
  create?: Maybe<Array<CategoryCreateInput>>
  /** Connect multiple existing Category documents */
  connect?: Maybe<Array<CategoryConnectInput>>
  /** Override currently-connected documents with multiple existing Category documents */
  set?: Maybe<Array<CategoryWhereUniqueInput>>
  /** Update multiple Category documents */
  update?: Maybe<Array<CategoryUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Category documents */
  upsert?: Maybe<Array<CategoryUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Category documents */
  disconnect?: Maybe<Array<CategoryWhereUniqueInput>>
  /** Delete multiple Category documents */
  delete?: Maybe<Array<CategoryWhereUniqueInput>>
}

export type CategoryUpdateManyInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type CategoryUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: CategoryWhereInput
  /** Update many input */
  data: CategoryUpdateManyInput
}

export type CategoryUpdateOneInlineInput = {
  /** Create and connect one Category document */
  create?: Maybe<CategoryCreateInput>
  /** Update single Category document */
  update?: Maybe<CategoryUpdateWithNestedWhereUniqueInput>
  /** Upsert single Category document */
  upsert?: Maybe<CategoryUpsertWithNestedWhereUniqueInput>
  /** Connect existing Category document */
  connect?: Maybe<CategoryWhereUniqueInput>
  /** Disconnect currently connected Category document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Category document */
  delete?: Maybe<Scalars['Boolean']>
}

export type CategoryUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: CategoryWhereUniqueInput
  /** Document to update */
  data: CategoryUpdateInput
}

export type CategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: CategoryCreateInput
  /** Update document if it exists */
  update: CategoryUpdateInput
}

export type CategoryUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: CategoryWhereUniqueInput
  /** Upsert data */
  data: CategoryUpsertInput
}

/** Identifies documents */
export type CategoryWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CategoryWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  articles_every?: Maybe<ArticleWhereInput>
  articles_some?: Maybe<ArticleWhereInput>
  articles_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Category record uniquely */
export type CategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
}

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: 'Color'
  hex: Scalars['Hex']
  rgba: Rgba
  css: Scalars['String']
}

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: Maybe<Scalars['Hex']>
  rgba?: Maybe<RgbaInput>
}

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: Maybe<Scalars['ID']>
  /** Connect document before specified document */
  before?: Maybe<Scalars['ID']>
  /** Connect document at first position */
  start?: Maybe<Scalars['Boolean']>
  /** Connect document at last position */
  end?: Maybe<Scalars['Boolean']>
}

export enum DocumentFileTypes {
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Png = 'png',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Docx = 'docx',
  Pdf = 'pdf',
  Html = 'html',
  Doc = 'doc',
  Xlsx = 'xlsx',
  Xls = 'xls',
  Pptx = 'pptx',
  Ppt = 'ppt',
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: Maybe<DocumentFileTypes>
}

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: Maybe<DocumentOutputInput>
}

export type DocumentVersion = {
  __typename?: 'DocumentVersion'
  id: Scalars['ID']
  stage: Stage
  revision: Scalars['Int']
  createdAt: Scalars['DateTime']
  data?: Maybe<Scalars['Json']>
}

export type Faq = Node & {
  __typename?: 'Faq'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Faq>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  content: RichText
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  category?: Maybe<FaqCategory>
  scheduledIn: Array<ScheduledOperation>
  /** List of Faq versions */
  history: Array<Version>
}

export type FaqDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type FaqCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type FaqUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type FaqPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type FaqScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type FaqHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export enum FaqCategory {
  General = 'General',
}

export type FaqConnectInput = {
  /** Document to connect */
  where: FaqWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type FaqConnection = {
  __typename?: 'FaqConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<FaqEdge>
  aggregate: Aggregate
}

export type FaqCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  content: Scalars['RichTextAST']
  category?: Maybe<FaqCategory>
}

export type FaqCreateManyInlineInput = {
  /** Create and connect multiple existing Faq documents */
  create?: Maybe<Array<FaqCreateInput>>
  /** Connect multiple existing Faq documents */
  connect?: Maybe<Array<FaqWhereUniqueInput>>
}

export type FaqCreateOneInlineInput = {
  /** Create and connect one Faq document */
  create?: Maybe<FaqCreateInput>
  /** Connect one existing Faq document */
  connect?: Maybe<FaqWhereUniqueInput>
}

/** An edge in a connection. */
export type FaqEdge = {
  __typename?: 'FaqEdge'
  /** The item at the end of the edge. */
  node: Faq
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type FaqManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<FaqWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<FaqWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<FaqWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  category?: Maybe<FaqCategory>
  /** All values that are not equal to given value. */
  category_not?: Maybe<FaqCategory>
  /** All values that are contained in given list. */
  category_in?: Maybe<Array<FaqCategory>>
  /** All values that are not contained in given list. */
  category_not_in?: Maybe<Array<FaqCategory>>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum FaqOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
}

export type FaqUpdateInput = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  category?: Maybe<FaqCategory>
}

export type FaqUpdateManyInlineInput = {
  /** Create and connect multiple Faq documents */
  create?: Maybe<Array<FaqCreateInput>>
  /** Connect multiple existing Faq documents */
  connect?: Maybe<Array<FaqConnectInput>>
  /** Override currently-connected documents with multiple existing Faq documents */
  set?: Maybe<Array<FaqWhereUniqueInput>>
  /** Update multiple Faq documents */
  update?: Maybe<Array<FaqUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Faq documents */
  upsert?: Maybe<Array<FaqUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Faq documents */
  disconnect?: Maybe<Array<FaqWhereUniqueInput>>
  /** Delete multiple Faq documents */
  delete?: Maybe<Array<FaqWhereUniqueInput>>
}

export type FaqUpdateManyInput = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  category?: Maybe<FaqCategory>
}

export type FaqUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: FaqWhereInput
  /** Update many input */
  data: FaqUpdateManyInput
}

export type FaqUpdateOneInlineInput = {
  /** Create and connect one Faq document */
  create?: Maybe<FaqCreateInput>
  /** Update single Faq document */
  update?: Maybe<FaqUpdateWithNestedWhereUniqueInput>
  /** Upsert single Faq document */
  upsert?: Maybe<FaqUpsertWithNestedWhereUniqueInput>
  /** Connect existing Faq document */
  connect?: Maybe<FaqWhereUniqueInput>
  /** Disconnect currently connected Faq document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Faq document */
  delete?: Maybe<Scalars['Boolean']>
}

export type FaqUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: FaqWhereUniqueInput
  /** Document to update */
  data: FaqUpdateInput
}

export type FaqUpsertInput = {
  /** Create document if it didn't exist */
  create: FaqCreateInput
  /** Update document if it exists */
  update: FaqUpdateInput
}

export type FaqUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: FaqWhereUniqueInput
  /** Upsert data */
  data: FaqUpsertInput
}

/** Identifies documents */
export type FaqWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<FaqWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<FaqWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<FaqWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  category?: Maybe<FaqCategory>
  /** All values that are not equal to given value. */
  category_not?: Maybe<FaqCategory>
  /** All values that are contained in given list. */
  category_in?: Maybe<Array<FaqCategory>>
  /** All values that are not contained in given list. */
  category_not_in?: Maybe<Array<FaqCategory>>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Faq record uniquely */
export type FaqWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = 'crop',
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = 'scale',
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = 'max',
}

export type ImageResizeInput = {
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: Maybe<Scalars['Int']>
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: Maybe<Scalars['Int']>
  /** The default value for the fit parameter is fit:clip. */
  fit?: Maybe<ImageFit>
}

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: Maybe<ImageResizeInput>
}

export type Information = Node & {
  __typename?: 'Information'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Information>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  content: RichText
  excerpt?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  thumbnail?: Maybe<Asset>
  scheduledIn: Array<ScheduledOperation>
  /** List of Information versions */
  history: Array<Version>
}

export type InformationDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type InformationCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type InformationUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type InformationPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type InformationThumbnailArgs = {
  locales?: Maybe<Array<Locale>>
}

export type InformationScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type InformationHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type InformationConnectInput = {
  /** Document to connect */
  where: InformationWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type InformationConnection = {
  __typename?: 'InformationConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<InformationEdge>
  aggregate: Aggregate
}

export type InformationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  content: Scalars['RichTextAST']
  excerpt?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  thumbnail?: Maybe<AssetCreateOneInlineInput>
}

export type InformationCreateManyInlineInput = {
  /** Create and connect multiple existing Information documents */
  create?: Maybe<Array<InformationCreateInput>>
  /** Connect multiple existing Information documents */
  connect?: Maybe<Array<InformationWhereUniqueInput>>
}

export type InformationCreateOneInlineInput = {
  /** Create and connect one Information document */
  create?: Maybe<InformationCreateInput>
  /** Connect one existing Information document */
  connect?: Maybe<InformationWhereUniqueInput>
}

/** An edge in a connection. */
export type InformationEdge = {
  __typename?: 'InformationEdge'
  /** The item at the end of the edge. */
  node: Information
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type InformationManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<InformationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<InformationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<InformationWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  excerpt_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  excerpt_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  excerpt_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  excerpt_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  excerpt_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  excerpt_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  excerpt_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  excerpt_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  excerpt_not_ends_with?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishDate_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishDate_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishDate_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishDate_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishDate_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum InformationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ExcerptAsc = 'excerpt_ASC',
  ExcerptDesc = 'excerpt_DESC',
  PublishDateAsc = 'publishDate_ASC',
  PublishDateDesc = 'publishDate_DESC',
}

export type InformationUpdateInput = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  excerpt?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  thumbnail?: Maybe<AssetUpdateOneInlineInput>
}

export type InformationUpdateManyInlineInput = {
  /** Create and connect multiple Information documents */
  create?: Maybe<Array<InformationCreateInput>>
  /** Connect multiple existing Information documents */
  connect?: Maybe<Array<InformationConnectInput>>
  /** Override currently-connected documents with multiple existing Information documents */
  set?: Maybe<Array<InformationWhereUniqueInput>>
  /** Update multiple Information documents */
  update?: Maybe<Array<InformationUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Information documents */
  upsert?: Maybe<Array<InformationUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Information documents */
  disconnect?: Maybe<Array<InformationWhereUniqueInput>>
  /** Delete multiple Information documents */
  delete?: Maybe<Array<InformationWhereUniqueInput>>
}

export type InformationUpdateManyInput = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  excerpt?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
}

export type InformationUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: InformationWhereInput
  /** Update many input */
  data: InformationUpdateManyInput
}

export type InformationUpdateOneInlineInput = {
  /** Create and connect one Information document */
  create?: Maybe<InformationCreateInput>
  /** Update single Information document */
  update?: Maybe<InformationUpdateWithNestedWhereUniqueInput>
  /** Upsert single Information document */
  upsert?: Maybe<InformationUpsertWithNestedWhereUniqueInput>
  /** Connect existing Information document */
  connect?: Maybe<InformationWhereUniqueInput>
  /** Disconnect currently connected Information document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Information document */
  delete?: Maybe<Scalars['Boolean']>
}

export type InformationUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: InformationWhereUniqueInput
  /** Document to update */
  data: InformationUpdateInput
}

export type InformationUpsertInput = {
  /** Create document if it didn't exist */
  create: InformationCreateInput
  /** Update document if it exists */
  update: InformationUpdateInput
}

export type InformationUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: InformationWhereUniqueInput
  /** Upsert data */
  data: InformationUpsertInput
}

/** Identifies documents */
export type InformationWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<InformationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<InformationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<InformationWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  excerpt_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  excerpt_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  excerpt_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  excerpt_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  excerpt_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  excerpt_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  excerpt_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  excerpt_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  excerpt_not_ends_with?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishDate_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishDate_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishDate_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishDate_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishDate_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Information record uniquely */
export type InformationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type Issue = Node & {
  __typename?: 'Issue'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Issue>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  titleKana: Scalars['String']
  keywords: Array<Scalars['String']>
  problem: RichText
  cause: RichText
  solution: RichText
  catalogIds: Array<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  issueCategory?: Maybe<IssueCategory>
  articles: Array<Article>
  scheduledIn: Array<ScheduledOperation>
  /** List of Issue versions */
  history: Array<Version>
}

export type IssueDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type IssueCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type IssueUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type IssuePublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type IssueIssueCategoryArgs = {
  locales?: Maybe<Array<Locale>>
}

export type IssueArticlesArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type IssueScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type IssueHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type IssueCategory = Node & {
  __typename?: 'IssueCategory'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<IssueCategory>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  sort: Scalars['Int']
  name: Scalars['String']
  nameKana: Scalars['String']
  description?: Maybe<RichText>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  issues: Array<Issue>
  scheduledIn: Array<ScheduledOperation>
  /** List of IssueCategory versions */
  history: Array<Version>
}

export type IssueCategoryDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type IssueCategoryCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type IssueCategoryUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type IssueCategoryPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type IssueCategoryIssuesArgs = {
  where?: Maybe<IssueWhereInput>
  orderBy?: Maybe<IssueOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type IssueCategoryScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type IssueCategoryHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type IssueCategoryConnectInput = {
  /** Document to connect */
  where: IssueCategoryWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type IssueCategoryConnection = {
  __typename?: 'IssueCategoryConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<IssueCategoryEdge>
  aggregate: Aggregate
}

export type IssueCategoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  sort: Scalars['Int']
  name: Scalars['String']
  nameKana: Scalars['String']
  description?: Maybe<Scalars['RichTextAST']>
  issues?: Maybe<IssueCreateManyInlineInput>
}

export type IssueCategoryCreateManyInlineInput = {
  /** Create and connect multiple existing IssueCategory documents */
  create?: Maybe<Array<IssueCategoryCreateInput>>
  /** Connect multiple existing IssueCategory documents */
  connect?: Maybe<Array<IssueCategoryWhereUniqueInput>>
}

export type IssueCategoryCreateOneInlineInput = {
  /** Create and connect one IssueCategory document */
  create?: Maybe<IssueCategoryCreateInput>
  /** Connect one existing IssueCategory document */
  connect?: Maybe<IssueCategoryWhereUniqueInput>
}

/** An edge in a connection. */
export type IssueCategoryEdge = {
  __typename?: 'IssueCategoryEdge'
  /** The item at the end of the edge. */
  node: IssueCategory
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type IssueCategoryManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<IssueCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<IssueCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<IssueCategoryWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  /** All values that are not equal to given value. */
  sort_not?: Maybe<Scalars['Int']>
  /** All values that are contained in given list. */
  sort_in?: Maybe<Array<Scalars['Int']>>
  /** All values that are not contained in given list. */
  sort_not_in?: Maybe<Array<Scalars['Int']>>
  /** All values less than the given value. */
  sort_lt?: Maybe<Scalars['Int']>
  /** All values less than or equal the given value. */
  sort_lte?: Maybe<Scalars['Int']>
  /** All values greater than the given value. */
  sort_gt?: Maybe<Scalars['Int']>
  /** All values greater than or equal the given value. */
  sort_gte?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  nameKana?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  nameKana_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  nameKana_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  nameKana_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  nameKana_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  nameKana_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  nameKana_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  nameKana_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  nameKana_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  nameKana_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  issues_every?: Maybe<IssueWhereInput>
  issues_some?: Maybe<IssueWhereInput>
  issues_none?: Maybe<IssueWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum IssueCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SortAsc = 'sort_ASC',
  SortDesc = 'sort_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NameKanaAsc = 'nameKana_ASC',
  NameKanaDesc = 'nameKana_DESC',
}

export type IssueCategoryUpdateInput = {
  title?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  nameKana?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['RichTextAST']>
  issues?: Maybe<IssueUpdateManyInlineInput>
}

export type IssueCategoryUpdateManyInlineInput = {
  /** Create and connect multiple IssueCategory documents */
  create?: Maybe<Array<IssueCategoryCreateInput>>
  /** Connect multiple existing IssueCategory documents */
  connect?: Maybe<Array<IssueCategoryConnectInput>>
  /** Override currently-connected documents with multiple existing IssueCategory documents */
  set?: Maybe<Array<IssueCategoryWhereUniqueInput>>
  /** Update multiple IssueCategory documents */
  update?: Maybe<Array<IssueCategoryUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple IssueCategory documents */
  upsert?: Maybe<Array<IssueCategoryUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple IssueCategory documents */
  disconnect?: Maybe<Array<IssueCategoryWhereUniqueInput>>
  /** Delete multiple IssueCategory documents */
  delete?: Maybe<Array<IssueCategoryWhereUniqueInput>>
}

export type IssueCategoryUpdateManyInput = {
  name?: Maybe<Scalars['String']>
  nameKana?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['RichTextAST']>
}

export type IssueCategoryUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: IssueCategoryWhereInput
  /** Update many input */
  data: IssueCategoryUpdateManyInput
}

export type IssueCategoryUpdateOneInlineInput = {
  /** Create and connect one IssueCategory document */
  create?: Maybe<IssueCategoryCreateInput>
  /** Update single IssueCategory document */
  update?: Maybe<IssueCategoryUpdateWithNestedWhereUniqueInput>
  /** Upsert single IssueCategory document */
  upsert?: Maybe<IssueCategoryUpsertWithNestedWhereUniqueInput>
  /** Connect existing IssueCategory document */
  connect?: Maybe<IssueCategoryWhereUniqueInput>
  /** Disconnect currently connected IssueCategory document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected IssueCategory document */
  delete?: Maybe<Scalars['Boolean']>
}

export type IssueCategoryUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: IssueCategoryWhereUniqueInput
  /** Document to update */
  data: IssueCategoryUpdateInput
}

export type IssueCategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: IssueCategoryCreateInput
  /** Update document if it exists */
  update: IssueCategoryUpdateInput
}

export type IssueCategoryUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: IssueCategoryWhereUniqueInput
  /** Upsert data */
  data: IssueCategoryUpsertInput
}

/** Identifies documents */
export type IssueCategoryWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<IssueCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<IssueCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<IssueCategoryWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  /** All values that are not equal to given value. */
  sort_not?: Maybe<Scalars['Int']>
  /** All values that are contained in given list. */
  sort_in?: Maybe<Array<Scalars['Int']>>
  /** All values that are not contained in given list. */
  sort_not_in?: Maybe<Array<Scalars['Int']>>
  /** All values less than the given value. */
  sort_lt?: Maybe<Scalars['Int']>
  /** All values less than or equal the given value. */
  sort_lte?: Maybe<Scalars['Int']>
  /** All values greater than the given value. */
  sort_gt?: Maybe<Scalars['Int']>
  /** All values greater than or equal the given value. */
  sort_gte?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  nameKana?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  nameKana_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  nameKana_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  nameKana_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  nameKana_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  nameKana_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  nameKana_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  nameKana_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  nameKana_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  nameKana_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  issues_every?: Maybe<IssueWhereInput>
  issues_some?: Maybe<IssueWhereInput>
  issues_none?: Maybe<IssueWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References IssueCategory record uniquely */
export type IssueCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
}

export type IssueConnectInput = {
  /** Document to connect */
  where: IssueWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type IssueConnection = {
  __typename?: 'IssueConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<IssueEdge>
  aggregate: Aggregate
}

export type IssueCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  titleKana: Scalars['String']
  keywords?: Maybe<Array<Scalars['String']>>
  problem: Scalars['RichTextAST']
  cause: Scalars['RichTextAST']
  solution: Scalars['RichTextAST']
  catalogIds?: Maybe<Array<Scalars['String']>>
  issueCategory?: Maybe<IssueCategoryCreateOneInlineInput>
  articles?: Maybe<ArticleCreateManyInlineInput>
}

export type IssueCreateManyInlineInput = {
  /** Create and connect multiple existing Issue documents */
  create?: Maybe<Array<IssueCreateInput>>
  /** Connect multiple existing Issue documents */
  connect?: Maybe<Array<IssueWhereUniqueInput>>
}

export type IssueCreateOneInlineInput = {
  /** Create and connect one Issue document */
  create?: Maybe<IssueCreateInput>
  /** Connect one existing Issue document */
  connect?: Maybe<IssueWhereUniqueInput>
}

/** An edge in a connection. */
export type IssueEdge = {
  __typename?: 'IssueEdge'
  /** The item at the end of the edge. */
  node: Issue
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type IssueManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<IssueWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<IssueWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<IssueWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  titleKana?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  titleKana_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  titleKana_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  titleKana_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  titleKana_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  titleKana_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  titleKana_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  titleKana_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  titleKana_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  titleKana_not_ends_with?: Maybe<Scalars['String']>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  keywords?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  keywords_not?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains *all* items provided to the filter */
  keywords_contains_all?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains at least one item provided to the filter */
  keywords_contains_some?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  keywords_contains_none?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  catalogIds?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  catalogIds_not?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains *all* items provided to the filter */
  catalogIds_contains_all?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains at least one item provided to the filter */
  catalogIds_contains_some?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  catalogIds_contains_none?: Maybe<Array<Scalars['String']>>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  issueCategory?: Maybe<IssueCategoryWhereInput>
  articles_every?: Maybe<ArticleWhereInput>
  articles_some?: Maybe<ArticleWhereInput>
  articles_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum IssueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TitleKanaAsc = 'titleKana_ASC',
  TitleKanaDesc = 'titleKana_DESC',
  KeywordsAsc = 'keywords_ASC',
  KeywordsDesc = 'keywords_DESC',
  CatalogIdsAsc = 'catalogIds_ASC',
  CatalogIdsDesc = 'catalogIds_DESC',
}

export type IssueUpdateInput = {
  title?: Maybe<Scalars['String']>
  titleKana?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  problem?: Maybe<Scalars['RichTextAST']>
  cause?: Maybe<Scalars['RichTextAST']>
  solution?: Maybe<Scalars['RichTextAST']>
  catalogIds?: Maybe<Array<Scalars['String']>>
  issueCategory?: Maybe<IssueCategoryUpdateOneInlineInput>
  articles?: Maybe<ArticleUpdateManyInlineInput>
}

export type IssueUpdateManyInlineInput = {
  /** Create and connect multiple Issue documents */
  create?: Maybe<Array<IssueCreateInput>>
  /** Connect multiple existing Issue documents */
  connect?: Maybe<Array<IssueConnectInput>>
  /** Override currently-connected documents with multiple existing Issue documents */
  set?: Maybe<Array<IssueWhereUniqueInput>>
  /** Update multiple Issue documents */
  update?: Maybe<Array<IssueUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Issue documents */
  upsert?: Maybe<Array<IssueUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Issue documents */
  disconnect?: Maybe<Array<IssueWhereUniqueInput>>
  /** Delete multiple Issue documents */
  delete?: Maybe<Array<IssueWhereUniqueInput>>
}

export type IssueUpdateManyInput = {
  title?: Maybe<Scalars['String']>
  titleKana?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  problem?: Maybe<Scalars['RichTextAST']>
  cause?: Maybe<Scalars['RichTextAST']>
  solution?: Maybe<Scalars['RichTextAST']>
  catalogIds?: Maybe<Array<Scalars['String']>>
}

export type IssueUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: IssueWhereInput
  /** Update many input */
  data: IssueUpdateManyInput
}

export type IssueUpdateOneInlineInput = {
  /** Create and connect one Issue document */
  create?: Maybe<IssueCreateInput>
  /** Update single Issue document */
  update?: Maybe<IssueUpdateWithNestedWhereUniqueInput>
  /** Upsert single Issue document */
  upsert?: Maybe<IssueUpsertWithNestedWhereUniqueInput>
  /** Connect existing Issue document */
  connect?: Maybe<IssueWhereUniqueInput>
  /** Disconnect currently connected Issue document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Issue document */
  delete?: Maybe<Scalars['Boolean']>
}

export type IssueUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: IssueWhereUniqueInput
  /** Document to update */
  data: IssueUpdateInput
}

export type IssueUpsertInput = {
  /** Create document if it didn't exist */
  create: IssueCreateInput
  /** Update document if it exists */
  update: IssueUpdateInput
}

export type IssueUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: IssueWhereUniqueInput
  /** Upsert data */
  data: IssueUpsertInput
}

/** Identifies documents */
export type IssueWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<IssueWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<IssueWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<IssueWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  titleKana?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  titleKana_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  titleKana_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  titleKana_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  titleKana_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  titleKana_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  titleKana_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  titleKana_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  titleKana_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  titleKana_not_ends_with?: Maybe<Scalars['String']>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  keywords?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  keywords_not?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains *all* items provided to the filter */
  keywords_contains_all?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains at least one item provided to the filter */
  keywords_contains_some?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  keywords_contains_none?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  catalogIds?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  catalogIds_not?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains *all* items provided to the filter */
  catalogIds_contains_all?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array contains at least one item provided to the filter */
  catalogIds_contains_some?: Maybe<Array<Scalars['String']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  catalogIds_contains_none?: Maybe<Array<Scalars['String']>>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  issueCategory?: Maybe<IssueCategoryWhereInput>
  articles_every?: Maybe<ArticleWhereInput>
  articles_some?: Maybe<ArticleWhereInput>
  articles_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Issue record uniquely */
export type IssueWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

/** Locale system enumeration */
export enum Locale {
  /** System locale */
  En = 'en',
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: 'Location'
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  distance: Scalars['Float']
}

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput
}

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Create one article */
  createArticle?: Maybe<Article>
  /** Update one article */
  updateArticle?: Maybe<Article>
  /** Delete one article from _all_ existing stages. Returns deleted document. */
  deleteArticle?: Maybe<Article>
  /** Upsert one article */
  upsertArticle?: Maybe<Article>
  /** Publish one article */
  publishArticle?: Maybe<Article>
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishArticle?: Maybe<Article>
  /** Update many Article documents */
  updateManyArticlesConnection: ArticleConnection
  /** Delete many Article documents, return deleted documents */
  deleteManyArticlesConnection: ArticleConnection
  /** Publish many Article documents */
  publishManyArticlesConnection: ArticleConnection
  /** Find many Article documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyArticlesConnection: ArticleConnection
  /**
   * Update many articles
   * @deprecated Please use the new paginated many mutation (updateManyArticlesConnection)
   */
  updateManyArticles: BatchPayload
  /**
   * Delete many Article documents
   * @deprecated Please use the new paginated many mutation (deleteManyArticlesConnection)
   */
  deleteManyArticles: BatchPayload
  /**
   * Publish many Article documents
   * @deprecated Please use the new paginated many mutation (publishManyArticlesConnection)
   */
  publishManyArticles: BatchPayload
  /**
   * Unpublish many Article documents
   * @deprecated Please use the new paginated many mutation (unpublishManyArticlesConnection)
   */
  unpublishManyArticles: BatchPayload
  /** Schedule to publish one article */
  schedulePublishArticle?: Maybe<Article>
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishArticle?: Maybe<Article>
  /** Create one pickupCatalog */
  createPickupCatalog?: Maybe<PickupCatalog>
  /** Update one pickupCatalog */
  updatePickupCatalog?: Maybe<PickupCatalog>
  /** Delete one pickupCatalog from _all_ existing stages. Returns deleted document. */
  deletePickupCatalog?: Maybe<PickupCatalog>
  /** Upsert one pickupCatalog */
  upsertPickupCatalog?: Maybe<PickupCatalog>
  /** Publish one pickupCatalog */
  publishPickupCatalog?: Maybe<PickupCatalog>
  /** Unpublish one pickupCatalog from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPickupCatalog?: Maybe<PickupCatalog>
  /** Update many PickupCatalog documents */
  updateManyPickupCatalogsConnection: PickupCatalogConnection
  /** Delete many PickupCatalog documents, return deleted documents */
  deleteManyPickupCatalogsConnection: PickupCatalogConnection
  /** Publish many PickupCatalog documents */
  publishManyPickupCatalogsConnection: PickupCatalogConnection
  /** Find many PickupCatalog documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPickupCatalogsConnection: PickupCatalogConnection
  /**
   * Update many pickupCatalogs
   * @deprecated Please use the new paginated many mutation (updateManyPickupCatalogsConnection)
   */
  updateManyPickupCatalogs: BatchPayload
  /**
   * Delete many PickupCatalog documents
   * @deprecated Please use the new paginated many mutation (deleteManyPickupCatalogsConnection)
   */
  deleteManyPickupCatalogs: BatchPayload
  /**
   * Publish many PickupCatalog documents
   * @deprecated Please use the new paginated many mutation (publishManyPickupCatalogsConnection)
   */
  publishManyPickupCatalogs: BatchPayload
  /**
   * Unpublish many PickupCatalog documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPickupCatalogsConnection)
   */
  unpublishManyPickupCatalogs: BatchPayload
  /** Schedule to publish one pickupCatalog */
  schedulePublishPickupCatalog?: Maybe<PickupCatalog>
  /** Unpublish one pickupCatalog from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPickupCatalog?: Maybe<PickupCatalog>
  /** Create one story */
  createStory?: Maybe<Story>
  /** Update one story */
  updateStory?: Maybe<Story>
  /** Delete one story from _all_ existing stages. Returns deleted document. */
  deleteStory?: Maybe<Story>
  /** Upsert one story */
  upsertStory?: Maybe<Story>
  /** Publish one story */
  publishStory?: Maybe<Story>
  /** Unpublish one story from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishStory?: Maybe<Story>
  /** Update many Story documents */
  updateManyStoriesConnection: StoryConnection
  /** Delete many Story documents, return deleted documents */
  deleteManyStoriesConnection: StoryConnection
  /** Publish many Story documents */
  publishManyStoriesConnection: StoryConnection
  /** Find many Story documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyStoriesConnection: StoryConnection
  /**
   * Update many stories
   * @deprecated Please use the new paginated many mutation (updateManyStoriesConnection)
   */
  updateManyStories: BatchPayload
  /**
   * Delete many Story documents
   * @deprecated Please use the new paginated many mutation (deleteManyStoriesConnection)
   */
  deleteManyStories: BatchPayload
  /**
   * Publish many Story documents
   * @deprecated Please use the new paginated many mutation (publishManyStoriesConnection)
   */
  publishManyStories: BatchPayload
  /**
   * Unpublish many Story documents
   * @deprecated Please use the new paginated many mutation (unpublishManyStoriesConnection)
   */
  unpublishManyStories: BatchPayload
  /** Schedule to publish one story */
  schedulePublishStory?: Maybe<Story>
  /** Unpublish one story from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishStory?: Maybe<Story>
  /** Create one provider */
  createProvider?: Maybe<Provider>
  /** Update one provider */
  updateProvider?: Maybe<Provider>
  /** Delete one provider from _all_ existing stages. Returns deleted document. */
  deleteProvider?: Maybe<Provider>
  /** Upsert one provider */
  upsertProvider?: Maybe<Provider>
  /** Publish one provider */
  publishProvider?: Maybe<Provider>
  /** Unpublish one provider from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProvider?: Maybe<Provider>
  /** Update many Provider documents */
  updateManyProvidersConnection: ProviderConnection
  /** Delete many Provider documents, return deleted documents */
  deleteManyProvidersConnection: ProviderConnection
  /** Publish many Provider documents */
  publishManyProvidersConnection: ProviderConnection
  /** Find many Provider documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProvidersConnection: ProviderConnection
  /**
   * Update many providers
   * @deprecated Please use the new paginated many mutation (updateManyProvidersConnection)
   */
  updateManyProviders: BatchPayload
  /**
   * Delete many Provider documents
   * @deprecated Please use the new paginated many mutation (deleteManyProvidersConnection)
   */
  deleteManyProviders: BatchPayload
  /**
   * Publish many Provider documents
   * @deprecated Please use the new paginated many mutation (publishManyProvidersConnection)
   */
  publishManyProviders: BatchPayload
  /**
   * Unpublish many Provider documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProvidersConnection)
   */
  unpublishManyProviders: BatchPayload
  /** Schedule to publish one provider */
  schedulePublishProvider?: Maybe<Provider>
  /** Unpublish one provider from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProvider?: Maybe<Provider>
  /** Create one information */
  createInformation?: Maybe<Information>
  /** Update one information */
  updateInformation?: Maybe<Information>
  /** Delete one information from _all_ existing stages. Returns deleted document. */
  deleteInformation?: Maybe<Information>
  /** Upsert one information */
  upsertInformation?: Maybe<Information>
  /** Publish one information */
  publishInformation?: Maybe<Information>
  /** Unpublish one information from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishInformation?: Maybe<Information>
  /** Update many Information documents */
  updateManyInformationsConnection: InformationConnection
  /** Delete many Information documents, return deleted documents */
  deleteManyInformationsConnection: InformationConnection
  /** Publish many Information documents */
  publishManyInformationsConnection: InformationConnection
  /** Find many Information documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyInformationsConnection: InformationConnection
  /**
   * Update many informations
   * @deprecated Please use the new paginated many mutation (updateManyInformationsConnection)
   */
  updateManyInformations: BatchPayload
  /**
   * Delete many Information documents
   * @deprecated Please use the new paginated many mutation (deleteManyInformationsConnection)
   */
  deleteManyInformations: BatchPayload
  /**
   * Publish many Information documents
   * @deprecated Please use the new paginated many mutation (publishManyInformationsConnection)
   */
  publishManyInformations: BatchPayload
  /**
   * Unpublish many Information documents
   * @deprecated Please use the new paginated many mutation (unpublishManyInformationsConnection)
   */
  unpublishManyInformations: BatchPayload
  /** Schedule to publish one information */
  schedulePublishInformation?: Maybe<Information>
  /** Unpublish one information from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishInformation?: Maybe<Information>
  /** Create one staticPage */
  createStaticPage?: Maybe<StaticPage>
  /** Update one staticPage */
  updateStaticPage?: Maybe<StaticPage>
  /** Delete one staticPage from _all_ existing stages. Returns deleted document. */
  deleteStaticPage?: Maybe<StaticPage>
  /** Upsert one staticPage */
  upsertStaticPage?: Maybe<StaticPage>
  /** Publish one staticPage */
  publishStaticPage?: Maybe<StaticPage>
  /** Unpublish one staticPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishStaticPage?: Maybe<StaticPage>
  /** Update many StaticPage documents */
  updateManyStaticPagesConnection: StaticPageConnection
  /** Delete many StaticPage documents, return deleted documents */
  deleteManyStaticPagesConnection: StaticPageConnection
  /** Publish many StaticPage documents */
  publishManyStaticPagesConnection: StaticPageConnection
  /** Find many StaticPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyStaticPagesConnection: StaticPageConnection
  /**
   * Update many staticPages
   * @deprecated Please use the new paginated many mutation (updateManyStaticPagesConnection)
   */
  updateManyStaticPages: BatchPayload
  /**
   * Delete many StaticPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyStaticPagesConnection)
   */
  deleteManyStaticPages: BatchPayload
  /**
   * Publish many StaticPage documents
   * @deprecated Please use the new paginated many mutation (publishManyStaticPagesConnection)
   */
  publishManyStaticPages: BatchPayload
  /**
   * Unpublish many StaticPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyStaticPagesConnection)
   */
  unpublishManyStaticPages: BatchPayload
  /** Schedule to publish one staticPage */
  schedulePublishStaticPage?: Maybe<StaticPage>
  /** Unpublish one staticPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishStaticPage?: Maybe<StaticPage>
  /** Create one faq */
  createFaq?: Maybe<Faq>
  /** Update one faq */
  updateFaq?: Maybe<Faq>
  /** Delete one faq from _all_ existing stages. Returns deleted document. */
  deleteFaq?: Maybe<Faq>
  /** Upsert one faq */
  upsertFaq?: Maybe<Faq>
  /** Publish one faq */
  publishFaq?: Maybe<Faq>
  /** Unpublish one faq from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFaq?: Maybe<Faq>
  /** Update many Faq documents */
  updateManyFaqsConnection: FaqConnection
  /** Delete many Faq documents, return deleted documents */
  deleteManyFaqsConnection: FaqConnection
  /** Publish many Faq documents */
  publishManyFaqsConnection: FaqConnection
  /** Find many Faq documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFaqsConnection: FaqConnection
  /**
   * Update many faqs
   * @deprecated Please use the new paginated many mutation (updateManyFaqsConnection)
   */
  updateManyFaqs: BatchPayload
  /**
   * Delete many Faq documents
   * @deprecated Please use the new paginated many mutation (deleteManyFaqsConnection)
   */
  deleteManyFaqs: BatchPayload
  /**
   * Publish many Faq documents
   * @deprecated Please use the new paginated many mutation (publishManyFaqsConnection)
   */
  publishManyFaqs: BatchPayload
  /**
   * Unpublish many Faq documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFaqsConnection)
   */
  unpublishManyFaqs: BatchPayload
  /** Schedule to publish one faq */
  schedulePublishFaq?: Maybe<Faq>
  /** Unpublish one faq from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFaq?: Maybe<Faq>
  /** Create one category */
  createCategory?: Maybe<Category>
  /** Update one category */
  updateCategory?: Maybe<Category>
  /** Delete one category from _all_ existing stages. Returns deleted document. */
  deleteCategory?: Maybe<Category>
  /** Upsert one category */
  upsertCategory?: Maybe<Category>
  /** Publish one category */
  publishCategory?: Maybe<Category>
  /** Unpublish one category from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCategory?: Maybe<Category>
  /** Update many Category documents */
  updateManyCategoriesConnection: CategoryConnection
  /** Delete many Category documents, return deleted documents */
  deleteManyCategoriesConnection: CategoryConnection
  /** Publish many Category documents */
  publishManyCategoriesConnection: CategoryConnection
  /** Find many Category documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCategoriesConnection: CategoryConnection
  /**
   * Update many categories
   * @deprecated Please use the new paginated many mutation (updateManyCategoriesConnection)
   */
  updateManyCategories: BatchPayload
  /**
   * Delete many Category documents
   * @deprecated Please use the new paginated many mutation (deleteManyCategoriesConnection)
   */
  deleteManyCategories: BatchPayload
  /**
   * Publish many Category documents
   * @deprecated Please use the new paginated many mutation (publishManyCategoriesConnection)
   */
  publishManyCategories: BatchPayload
  /**
   * Unpublish many Category documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCategoriesConnection)
   */
  unpublishManyCategories: BatchPayload
  /** Schedule to publish one category */
  schedulePublishCategory?: Maybe<Category>
  /** Unpublish one category from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCategory?: Maybe<Category>
  /** Create one author */
  createAuthor?: Maybe<Author>
  /** Update one author */
  updateAuthor?: Maybe<Author>
  /** Delete one author from _all_ existing stages. Returns deleted document. */
  deleteAuthor?: Maybe<Author>
  /** Upsert one author */
  upsertAuthor?: Maybe<Author>
  /** Publish one author */
  publishAuthor?: Maybe<Author>
  /** Unpublish one author from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAuthor?: Maybe<Author>
  /** Update many Author documents */
  updateManyAuthorsConnection: AuthorConnection
  /** Delete many Author documents, return deleted documents */
  deleteManyAuthorsConnection: AuthorConnection
  /** Publish many Author documents */
  publishManyAuthorsConnection: AuthorConnection
  /** Find many Author documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAuthorsConnection: AuthorConnection
  /**
   * Update many authors
   * @deprecated Please use the new paginated many mutation (updateManyAuthorsConnection)
   */
  updateManyAuthors: BatchPayload
  /**
   * Delete many Author documents
   * @deprecated Please use the new paginated many mutation (deleteManyAuthorsConnection)
   */
  deleteManyAuthors: BatchPayload
  /**
   * Publish many Author documents
   * @deprecated Please use the new paginated many mutation (publishManyAuthorsConnection)
   */
  publishManyAuthors: BatchPayload
  /**
   * Unpublish many Author documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAuthorsConnection)
   */
  unpublishManyAuthors: BatchPayload
  /** Schedule to publish one author */
  schedulePublishAuthor?: Maybe<Author>
  /** Unpublish one author from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAuthor?: Maybe<Author>
  /** Create one tag */
  createTag?: Maybe<Tag>
  /** Update one tag */
  updateTag?: Maybe<Tag>
  /** Delete one tag from _all_ existing stages. Returns deleted document. */
  deleteTag?: Maybe<Tag>
  /** Upsert one tag */
  upsertTag?: Maybe<Tag>
  /** Publish one tag */
  publishTag?: Maybe<Tag>
  /** Unpublish one tag from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTag?: Maybe<Tag>
  /** Update many Tag documents */
  updateManyTagsConnection: TagConnection
  /** Delete many Tag documents, return deleted documents */
  deleteManyTagsConnection: TagConnection
  /** Publish many Tag documents */
  publishManyTagsConnection: TagConnection
  /** Find many Tag documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTagsConnection: TagConnection
  /**
   * Update many tags
   * @deprecated Please use the new paginated many mutation (updateManyTagsConnection)
   */
  updateManyTags: BatchPayload
  /**
   * Delete many Tag documents
   * @deprecated Please use the new paginated many mutation (deleteManyTagsConnection)
   */
  deleteManyTags: BatchPayload
  /**
   * Publish many Tag documents
   * @deprecated Please use the new paginated many mutation (publishManyTagsConnection)
   */
  publishManyTags: BatchPayload
  /**
   * Unpublish many Tag documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTagsConnection)
   */
  unpublishManyTags: BatchPayload
  /** Schedule to publish one tag */
  schedulePublishTag?: Maybe<Tag>
  /** Unpublish one tag from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTag?: Maybe<Tag>
  /** Create one adDistributionManagement */
  createAdDistributionManagement?: Maybe<AdDistributionManagement>
  /** Update one adDistributionManagement */
  updateAdDistributionManagement?: Maybe<AdDistributionManagement>
  /** Delete one adDistributionManagement from _all_ existing stages. Returns deleted document. */
  deleteAdDistributionManagement?: Maybe<AdDistributionManagement>
  /** Upsert one adDistributionManagement */
  upsertAdDistributionManagement?: Maybe<AdDistributionManagement>
  /** Publish one adDistributionManagement */
  publishAdDistributionManagement?: Maybe<AdDistributionManagement>
  /** Unpublish one adDistributionManagement from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAdDistributionManagement?: Maybe<AdDistributionManagement>
  /** Update many AdDistributionManagement documents */
  updateManyAdDistributionManagementsConnection: AdDistributionManagementConnection
  /** Delete many AdDistributionManagement documents, return deleted documents */
  deleteManyAdDistributionManagementsConnection: AdDistributionManagementConnection
  /** Publish many AdDistributionManagement documents */
  publishManyAdDistributionManagementsConnection: AdDistributionManagementConnection
  /** Find many AdDistributionManagement documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAdDistributionManagementsConnection: AdDistributionManagementConnection
  /**
   * Update many adDistributionManagements
   * @deprecated Please use the new paginated many mutation (updateManyAdDistributionManagementsConnection)
   */
  updateManyAdDistributionManagements: BatchPayload
  /**
   * Delete many AdDistributionManagement documents
   * @deprecated Please use the new paginated many mutation (deleteManyAdDistributionManagementsConnection)
   */
  deleteManyAdDistributionManagements: BatchPayload
  /**
   * Publish many AdDistributionManagement documents
   * @deprecated Please use the new paginated many mutation (publishManyAdDistributionManagementsConnection)
   */
  publishManyAdDistributionManagements: BatchPayload
  /**
   * Unpublish many AdDistributionManagement documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAdDistributionManagementsConnection)
   */
  unpublishManyAdDistributionManagements: BatchPayload
  /** Schedule to publish one adDistributionManagement */
  schedulePublishAdDistributionManagement?: Maybe<AdDistributionManagement>
  /** Unpublish one adDistributionManagement from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAdDistributionManagement?: Maybe<AdDistributionManagement>
  /** Create one adDistributionContent */
  createAdDistributionContent?: Maybe<AdDistributionContent>
  /** Update one adDistributionContent */
  updateAdDistributionContent?: Maybe<AdDistributionContent>
  /** Delete one adDistributionContent from _all_ existing stages. Returns deleted document. */
  deleteAdDistributionContent?: Maybe<AdDistributionContent>
  /** Upsert one adDistributionContent */
  upsertAdDistributionContent?: Maybe<AdDistributionContent>
  /** Publish one adDistributionContent */
  publishAdDistributionContent?: Maybe<AdDistributionContent>
  /** Unpublish one adDistributionContent from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAdDistributionContent?: Maybe<AdDistributionContent>
  /** Update many AdDistributionContent documents */
  updateManyAdDistributionContentsConnection: AdDistributionContentConnection
  /** Delete many AdDistributionContent documents, return deleted documents */
  deleteManyAdDistributionContentsConnection: AdDistributionContentConnection
  /** Publish many AdDistributionContent documents */
  publishManyAdDistributionContentsConnection: AdDistributionContentConnection
  /** Find many AdDistributionContent documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAdDistributionContentsConnection: AdDistributionContentConnection
  /**
   * Update many adDistributionContents
   * @deprecated Please use the new paginated many mutation (updateManyAdDistributionContentsConnection)
   */
  updateManyAdDistributionContents: BatchPayload
  /**
   * Delete many AdDistributionContent documents
   * @deprecated Please use the new paginated many mutation (deleteManyAdDistributionContentsConnection)
   */
  deleteManyAdDistributionContents: BatchPayload
  /**
   * Publish many AdDistributionContent documents
   * @deprecated Please use the new paginated many mutation (publishManyAdDistributionContentsConnection)
   */
  publishManyAdDistributionContents: BatchPayload
  /**
   * Unpublish many AdDistributionContent documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAdDistributionContentsConnection)
   */
  unpublishManyAdDistributionContents: BatchPayload
  /** Schedule to publish one adDistributionContent */
  schedulePublishAdDistributionContent?: Maybe<AdDistributionContent>
  /** Unpublish one adDistributionContent from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAdDistributionContent?: Maybe<AdDistributionContent>
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<Asset>
  /** Update one asset */
  updateAsset?: Maybe<Asset>
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>
  /** Publish one asset */
  publishAsset?: Maybe<Asset>
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<Asset>
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<Asset>
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<ScheduledOperation>
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<ScheduledRelease>
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<ScheduledRelease>
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<ScheduledRelease>
  /** Create one wordCategory */
  createWordCategory?: Maybe<WordCategory>
  /** Update one wordCategory */
  updateWordCategory?: Maybe<WordCategory>
  /** Delete one wordCategory from _all_ existing stages. Returns deleted document. */
  deleteWordCategory?: Maybe<WordCategory>
  /** Upsert one wordCategory */
  upsertWordCategory?: Maybe<WordCategory>
  /** Publish one wordCategory */
  publishWordCategory?: Maybe<WordCategory>
  /** Unpublish one wordCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishWordCategory?: Maybe<WordCategory>
  /** Update many WordCategory documents */
  updateManyWordCategoriesConnection: WordCategoryConnection
  /** Delete many WordCategory documents, return deleted documents */
  deleteManyWordCategoriesConnection: WordCategoryConnection
  /** Publish many WordCategory documents */
  publishManyWordCategoriesConnection: WordCategoryConnection
  /** Find many WordCategory documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyWordCategoriesConnection: WordCategoryConnection
  /**
   * Update many wordCategories
   * @deprecated Please use the new paginated many mutation (updateManyWordCategoriesConnection)
   */
  updateManyWordCategories: BatchPayload
  /**
   * Delete many WordCategory documents
   * @deprecated Please use the new paginated many mutation (deleteManyWordCategoriesConnection)
   */
  deleteManyWordCategories: BatchPayload
  /**
   * Publish many WordCategory documents
   * @deprecated Please use the new paginated many mutation (publishManyWordCategoriesConnection)
   */
  publishManyWordCategories: BatchPayload
  /**
   * Unpublish many WordCategory documents
   * @deprecated Please use the new paginated many mutation (unpublishManyWordCategoriesConnection)
   */
  unpublishManyWordCategories: BatchPayload
  /** Schedule to publish one wordCategory */
  schedulePublishWordCategory?: Maybe<WordCategory>
  /** Unpublish one wordCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishWordCategory?: Maybe<WordCategory>
  /** Create one word */
  createWord?: Maybe<Word>
  /** Update one word */
  updateWord?: Maybe<Word>
  /** Delete one word from _all_ existing stages. Returns deleted document. */
  deleteWord?: Maybe<Word>
  /** Upsert one word */
  upsertWord?: Maybe<Word>
  /** Publish one word */
  publishWord?: Maybe<Word>
  /** Unpublish one word from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishWord?: Maybe<Word>
  /** Update many Word documents */
  updateManyWordsConnection: WordConnection
  /** Delete many Word documents, return deleted documents */
  deleteManyWordsConnection: WordConnection
  /** Publish many Word documents */
  publishManyWordsConnection: WordConnection
  /** Find many Word documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyWordsConnection: WordConnection
  /**
   * Update many words
   * @deprecated Please use the new paginated many mutation (updateManyWordsConnection)
   */
  updateManyWords: BatchPayload
  /**
   * Delete many Word documents
   * @deprecated Please use the new paginated many mutation (deleteManyWordsConnection)
   */
  deleteManyWords: BatchPayload
  /**
   * Publish many Word documents
   * @deprecated Please use the new paginated many mutation (publishManyWordsConnection)
   */
  publishManyWords: BatchPayload
  /**
   * Unpublish many Word documents
   * @deprecated Please use the new paginated many mutation (unpublishManyWordsConnection)
   */
  unpublishManyWords: BatchPayload
  /** Schedule to publish one word */
  schedulePublishWord?: Maybe<Word>
  /** Unpublish one word from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishWord?: Maybe<Word>
  /** Create one issue */
  createIssue?: Maybe<Issue>
  /** Update one issue */
  updateIssue?: Maybe<Issue>
  /** Delete one issue from _all_ existing stages. Returns deleted document. */
  deleteIssue?: Maybe<Issue>
  /** Upsert one issue */
  upsertIssue?: Maybe<Issue>
  /** Publish one issue */
  publishIssue?: Maybe<Issue>
  /** Unpublish one issue from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishIssue?: Maybe<Issue>
  /** Update many Issue documents */
  updateManyIssuesConnection: IssueConnection
  /** Delete many Issue documents, return deleted documents */
  deleteManyIssuesConnection: IssueConnection
  /** Publish many Issue documents */
  publishManyIssuesConnection: IssueConnection
  /** Find many Issue documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyIssuesConnection: IssueConnection
  /**
   * Update many issues
   * @deprecated Please use the new paginated many mutation (updateManyIssuesConnection)
   */
  updateManyIssues: BatchPayload
  /**
   * Delete many Issue documents
   * @deprecated Please use the new paginated many mutation (deleteManyIssuesConnection)
   */
  deleteManyIssues: BatchPayload
  /**
   * Publish many Issue documents
   * @deprecated Please use the new paginated many mutation (publishManyIssuesConnection)
   */
  publishManyIssues: BatchPayload
  /**
   * Unpublish many Issue documents
   * @deprecated Please use the new paginated many mutation (unpublishManyIssuesConnection)
   */
  unpublishManyIssues: BatchPayload
  /** Schedule to publish one issue */
  schedulePublishIssue?: Maybe<Issue>
  /** Unpublish one issue from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishIssue?: Maybe<Issue>
  /** Create one issueCategory */
  createIssueCategory?: Maybe<IssueCategory>
  /** Update one issueCategory */
  updateIssueCategory?: Maybe<IssueCategory>
  /** Delete one issueCategory from _all_ existing stages. Returns deleted document. */
  deleteIssueCategory?: Maybe<IssueCategory>
  /** Upsert one issueCategory */
  upsertIssueCategory?: Maybe<IssueCategory>
  /** Publish one issueCategory */
  publishIssueCategory?: Maybe<IssueCategory>
  /** Unpublish one issueCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishIssueCategory?: Maybe<IssueCategory>
  /** Update many IssueCategory documents */
  updateManyIssueCategoriesConnection: IssueCategoryConnection
  /** Delete many IssueCategory documents, return deleted documents */
  deleteManyIssueCategoriesConnection: IssueCategoryConnection
  /** Publish many IssueCategory documents */
  publishManyIssueCategoriesConnection: IssueCategoryConnection
  /** Find many IssueCategory documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyIssueCategoriesConnection: IssueCategoryConnection
  /**
   * Update many issueCategories
   * @deprecated Please use the new paginated many mutation (updateManyIssueCategoriesConnection)
   */
  updateManyIssueCategories: BatchPayload
  /**
   * Delete many IssueCategory documents
   * @deprecated Please use the new paginated many mutation (deleteManyIssueCategoriesConnection)
   */
  deleteManyIssueCategories: BatchPayload
  /**
   * Publish many IssueCategory documents
   * @deprecated Please use the new paginated many mutation (publishManyIssueCategoriesConnection)
   */
  publishManyIssueCategories: BatchPayload
  /**
   * Unpublish many IssueCategory documents
   * @deprecated Please use the new paginated many mutation (unpublishManyIssueCategoriesConnection)
   */
  unpublishManyIssueCategories: BatchPayload
  /** Schedule to publish one issueCategory */
  schedulePublishIssueCategory?: Maybe<IssueCategory>
  /** Unpublish one issueCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishIssueCategory?: Maybe<IssueCategory>
}

export type MutationCreateArticleArgs = {
  data: ArticleCreateInput
}

export type MutationUpdateArticleArgs = {
  where: ArticleWhereUniqueInput
  data: ArticleUpdateInput
}

export type MutationDeleteArticleArgs = {
  where: ArticleWhereUniqueInput
}

export type MutationUpsertArticleArgs = {
  where: ArticleWhereUniqueInput
  upsert: ArticleUpsertInput
}

export type MutationPublishArticleArgs = {
  where: ArticleWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishArticleArgs = {
  where: ArticleWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyArticlesConnectionArgs = {
  where?: Maybe<ArticleManyWhereInput>
  data: ArticleUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyArticlesConnectionArgs = {
  where?: Maybe<ArticleManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyArticlesConnectionArgs = {
  where?: Maybe<ArticleManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyArticlesConnectionArgs = {
  where?: Maybe<ArticleManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyArticlesArgs = {
  where?: Maybe<ArticleManyWhereInput>
  data: ArticleUpdateManyInput
}

export type MutationDeleteManyArticlesArgs = {
  where?: Maybe<ArticleManyWhereInput>
}

export type MutationPublishManyArticlesArgs = {
  where?: Maybe<ArticleManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyArticlesArgs = {
  where?: Maybe<ArticleManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishArticleArgs = {
  where: ArticleWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishArticleArgs = {
  where: ArticleWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreatePickupCatalogArgs = {
  data: PickupCatalogCreateInput
}

export type MutationUpdatePickupCatalogArgs = {
  where: PickupCatalogWhereUniqueInput
  data: PickupCatalogUpdateInput
}

export type MutationDeletePickupCatalogArgs = {
  where: PickupCatalogWhereUniqueInput
}

export type MutationUpsertPickupCatalogArgs = {
  where: PickupCatalogWhereUniqueInput
  upsert: PickupCatalogUpsertInput
}

export type MutationPublishPickupCatalogArgs = {
  where: PickupCatalogWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishPickupCatalogArgs = {
  where: PickupCatalogWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyPickupCatalogsConnectionArgs = {
  where?: Maybe<PickupCatalogManyWhereInput>
  data: PickupCatalogUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyPickupCatalogsConnectionArgs = {
  where?: Maybe<PickupCatalogManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyPickupCatalogsConnectionArgs = {
  where?: Maybe<PickupCatalogManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyPickupCatalogsConnectionArgs = {
  where?: Maybe<PickupCatalogManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyPickupCatalogsArgs = {
  where?: Maybe<PickupCatalogManyWhereInput>
  data: PickupCatalogUpdateManyInput
}

export type MutationDeleteManyPickupCatalogsArgs = {
  where?: Maybe<PickupCatalogManyWhereInput>
}

export type MutationPublishManyPickupCatalogsArgs = {
  where?: Maybe<PickupCatalogManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyPickupCatalogsArgs = {
  where?: Maybe<PickupCatalogManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishPickupCatalogArgs = {
  where: PickupCatalogWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishPickupCatalogArgs = {
  where: PickupCatalogWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateStoryArgs = {
  data: StoryCreateInput
}

export type MutationUpdateStoryArgs = {
  where: StoryWhereUniqueInput
  data: StoryUpdateInput
}

export type MutationDeleteStoryArgs = {
  where: StoryWhereUniqueInput
}

export type MutationUpsertStoryArgs = {
  where: StoryWhereUniqueInput
  upsert: StoryUpsertInput
}

export type MutationPublishStoryArgs = {
  where: StoryWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishStoryArgs = {
  where: StoryWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyStoriesConnectionArgs = {
  where?: Maybe<StoryManyWhereInput>
  data: StoryUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyStoriesConnectionArgs = {
  where?: Maybe<StoryManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyStoriesConnectionArgs = {
  where?: Maybe<StoryManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyStoriesConnectionArgs = {
  where?: Maybe<StoryManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyStoriesArgs = {
  where?: Maybe<StoryManyWhereInput>
  data: StoryUpdateManyInput
}

export type MutationDeleteManyStoriesArgs = {
  where?: Maybe<StoryManyWhereInput>
}

export type MutationPublishManyStoriesArgs = {
  where?: Maybe<StoryManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyStoriesArgs = {
  where?: Maybe<StoryManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishStoryArgs = {
  where: StoryWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishStoryArgs = {
  where: StoryWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateProviderArgs = {
  data: ProviderCreateInput
}

export type MutationUpdateProviderArgs = {
  where: ProviderWhereUniqueInput
  data: ProviderUpdateInput
}

export type MutationDeleteProviderArgs = {
  where: ProviderWhereUniqueInput
}

export type MutationUpsertProviderArgs = {
  where: ProviderWhereUniqueInput
  upsert: ProviderUpsertInput
}

export type MutationPublishProviderArgs = {
  where: ProviderWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishProviderArgs = {
  where: ProviderWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyProvidersConnectionArgs = {
  where?: Maybe<ProviderManyWhereInput>
  data: ProviderUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyProvidersConnectionArgs = {
  where?: Maybe<ProviderManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyProvidersConnectionArgs = {
  where?: Maybe<ProviderManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyProvidersConnectionArgs = {
  where?: Maybe<ProviderManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyProvidersArgs = {
  where?: Maybe<ProviderManyWhereInput>
  data: ProviderUpdateManyInput
}

export type MutationDeleteManyProvidersArgs = {
  where?: Maybe<ProviderManyWhereInput>
}

export type MutationPublishManyProvidersArgs = {
  where?: Maybe<ProviderManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyProvidersArgs = {
  where?: Maybe<ProviderManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishProviderArgs = {
  where: ProviderWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishProviderArgs = {
  where: ProviderWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateInformationArgs = {
  data: InformationCreateInput
}

export type MutationUpdateInformationArgs = {
  where: InformationWhereUniqueInput
  data: InformationUpdateInput
}

export type MutationDeleteInformationArgs = {
  where: InformationWhereUniqueInput
}

export type MutationUpsertInformationArgs = {
  where: InformationWhereUniqueInput
  upsert: InformationUpsertInput
}

export type MutationPublishInformationArgs = {
  where: InformationWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishInformationArgs = {
  where: InformationWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyInformationsConnectionArgs = {
  where?: Maybe<InformationManyWhereInput>
  data: InformationUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyInformationsConnectionArgs = {
  where?: Maybe<InformationManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyInformationsConnectionArgs = {
  where?: Maybe<InformationManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyInformationsConnectionArgs = {
  where?: Maybe<InformationManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyInformationsArgs = {
  where?: Maybe<InformationManyWhereInput>
  data: InformationUpdateManyInput
}

export type MutationDeleteManyInformationsArgs = {
  where?: Maybe<InformationManyWhereInput>
}

export type MutationPublishManyInformationsArgs = {
  where?: Maybe<InformationManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyInformationsArgs = {
  where?: Maybe<InformationManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishInformationArgs = {
  where: InformationWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishInformationArgs = {
  where: InformationWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateStaticPageArgs = {
  data: StaticPageCreateInput
}

export type MutationUpdateStaticPageArgs = {
  where: StaticPageWhereUniqueInput
  data: StaticPageUpdateInput
}

export type MutationDeleteStaticPageArgs = {
  where: StaticPageWhereUniqueInput
}

export type MutationUpsertStaticPageArgs = {
  where: StaticPageWhereUniqueInput
  upsert: StaticPageUpsertInput
}

export type MutationPublishStaticPageArgs = {
  where: StaticPageWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishStaticPageArgs = {
  where: StaticPageWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyStaticPagesConnectionArgs = {
  where?: Maybe<StaticPageManyWhereInput>
  data: StaticPageUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyStaticPagesConnectionArgs = {
  where?: Maybe<StaticPageManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyStaticPagesConnectionArgs = {
  where?: Maybe<StaticPageManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyStaticPagesConnectionArgs = {
  where?: Maybe<StaticPageManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyStaticPagesArgs = {
  where?: Maybe<StaticPageManyWhereInput>
  data: StaticPageUpdateManyInput
}

export type MutationDeleteManyStaticPagesArgs = {
  where?: Maybe<StaticPageManyWhereInput>
}

export type MutationPublishManyStaticPagesArgs = {
  where?: Maybe<StaticPageManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyStaticPagesArgs = {
  where?: Maybe<StaticPageManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishStaticPageArgs = {
  where: StaticPageWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishStaticPageArgs = {
  where: StaticPageWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateFaqArgs = {
  data: FaqCreateInput
}

export type MutationUpdateFaqArgs = {
  where: FaqWhereUniqueInput
  data: FaqUpdateInput
}

export type MutationDeleteFaqArgs = {
  where: FaqWhereUniqueInput
}

export type MutationUpsertFaqArgs = {
  where: FaqWhereUniqueInput
  upsert: FaqUpsertInput
}

export type MutationPublishFaqArgs = {
  where: FaqWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishFaqArgs = {
  where: FaqWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyFaqsConnectionArgs = {
  where?: Maybe<FaqManyWhereInput>
  data: FaqUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyFaqsConnectionArgs = {
  where?: Maybe<FaqManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyFaqsConnectionArgs = {
  where?: Maybe<FaqManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyFaqsConnectionArgs = {
  where?: Maybe<FaqManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyFaqsArgs = {
  where?: Maybe<FaqManyWhereInput>
  data: FaqUpdateManyInput
}

export type MutationDeleteManyFaqsArgs = {
  where?: Maybe<FaqManyWhereInput>
}

export type MutationPublishManyFaqsArgs = {
  where?: Maybe<FaqManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyFaqsArgs = {
  where?: Maybe<FaqManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishFaqArgs = {
  where: FaqWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishFaqArgs = {
  where: FaqWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateCategoryArgs = {
  data: CategoryCreateInput
}

export type MutationUpdateCategoryArgs = {
  where: CategoryWhereUniqueInput
  data: CategoryUpdateInput
}

export type MutationDeleteCategoryArgs = {
  where: CategoryWhereUniqueInput
}

export type MutationUpsertCategoryArgs = {
  where: CategoryWhereUniqueInput
  upsert: CategoryUpsertInput
}

export type MutationPublishCategoryArgs = {
  where: CategoryWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishCategoryArgs = {
  where: CategoryWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyCategoriesConnectionArgs = {
  where?: Maybe<CategoryManyWhereInput>
  data: CategoryUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyCategoriesConnectionArgs = {
  where?: Maybe<CategoryManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyCategoriesConnectionArgs = {
  where?: Maybe<CategoryManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyCategoriesConnectionArgs = {
  where?: Maybe<CategoryManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyCategoriesArgs = {
  where?: Maybe<CategoryManyWhereInput>
  data: CategoryUpdateManyInput
}

export type MutationDeleteManyCategoriesArgs = {
  where?: Maybe<CategoryManyWhereInput>
}

export type MutationPublishManyCategoriesArgs = {
  where?: Maybe<CategoryManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyCategoriesArgs = {
  where?: Maybe<CategoryManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishCategoryArgs = {
  where: CategoryWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishCategoryArgs = {
  where: CategoryWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateAuthorArgs = {
  data: AuthorCreateInput
}

export type MutationUpdateAuthorArgs = {
  where: AuthorWhereUniqueInput
  data: AuthorUpdateInput
}

export type MutationDeleteAuthorArgs = {
  where: AuthorWhereUniqueInput
}

export type MutationUpsertAuthorArgs = {
  where: AuthorWhereUniqueInput
  upsert: AuthorUpsertInput
}

export type MutationPublishAuthorArgs = {
  where: AuthorWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishAuthorArgs = {
  where: AuthorWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyAuthorsConnectionArgs = {
  where?: Maybe<AuthorManyWhereInput>
  data: AuthorUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyAuthorsConnectionArgs = {
  where?: Maybe<AuthorManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyAuthorsConnectionArgs = {
  where?: Maybe<AuthorManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyAuthorsConnectionArgs = {
  where?: Maybe<AuthorManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyAuthorsArgs = {
  where?: Maybe<AuthorManyWhereInput>
  data: AuthorUpdateManyInput
}

export type MutationDeleteManyAuthorsArgs = {
  where?: Maybe<AuthorManyWhereInput>
}

export type MutationPublishManyAuthorsArgs = {
  where?: Maybe<AuthorManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyAuthorsArgs = {
  where?: Maybe<AuthorManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishAuthorArgs = {
  where: AuthorWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishAuthorArgs = {
  where: AuthorWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateTagArgs = {
  data: TagCreateInput
}

export type MutationUpdateTagArgs = {
  where: TagWhereUniqueInput
  data: TagUpdateInput
}

export type MutationDeleteTagArgs = {
  where: TagWhereUniqueInput
}

export type MutationUpsertTagArgs = {
  where: TagWhereUniqueInput
  upsert: TagUpsertInput
}

export type MutationPublishTagArgs = {
  where: TagWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishTagArgs = {
  where: TagWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyTagsConnectionArgs = {
  where?: Maybe<TagManyWhereInput>
  data: TagUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyTagsConnectionArgs = {
  where?: Maybe<TagManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyTagsConnectionArgs = {
  where?: Maybe<TagManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyTagsConnectionArgs = {
  where?: Maybe<TagManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyTagsArgs = {
  where?: Maybe<TagManyWhereInput>
  data: TagUpdateManyInput
}

export type MutationDeleteManyTagsArgs = {
  where?: Maybe<TagManyWhereInput>
}

export type MutationPublishManyTagsArgs = {
  where?: Maybe<TagManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyTagsArgs = {
  where?: Maybe<TagManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishTagArgs = {
  where: TagWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishTagArgs = {
  where: TagWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateAdDistributionManagementArgs = {
  data: AdDistributionManagementCreateInput
}

export type MutationUpdateAdDistributionManagementArgs = {
  where: AdDistributionManagementWhereUniqueInput
  data: AdDistributionManagementUpdateInput
}

export type MutationDeleteAdDistributionManagementArgs = {
  where: AdDistributionManagementWhereUniqueInput
}

export type MutationUpsertAdDistributionManagementArgs = {
  where: AdDistributionManagementWhereUniqueInput
  upsert: AdDistributionManagementUpsertInput
}

export type MutationPublishAdDistributionManagementArgs = {
  where: AdDistributionManagementWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishAdDistributionManagementArgs = {
  where: AdDistributionManagementWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyAdDistributionManagementsConnectionArgs = {
  where?: Maybe<AdDistributionManagementManyWhereInput>
  data: AdDistributionManagementUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyAdDistributionManagementsConnectionArgs = {
  where?: Maybe<AdDistributionManagementManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyAdDistributionManagementsConnectionArgs = {
  where?: Maybe<AdDistributionManagementManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyAdDistributionManagementsConnectionArgs = {
  where?: Maybe<AdDistributionManagementManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyAdDistributionManagementsArgs = {
  where?: Maybe<AdDistributionManagementManyWhereInput>
  data: AdDistributionManagementUpdateManyInput
}

export type MutationDeleteManyAdDistributionManagementsArgs = {
  where?: Maybe<AdDistributionManagementManyWhereInput>
}

export type MutationPublishManyAdDistributionManagementsArgs = {
  where?: Maybe<AdDistributionManagementManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyAdDistributionManagementsArgs = {
  where?: Maybe<AdDistributionManagementManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishAdDistributionManagementArgs = {
  where: AdDistributionManagementWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishAdDistributionManagementArgs = {
  where: AdDistributionManagementWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateAdDistributionContentArgs = {
  data: AdDistributionContentCreateInput
}

export type MutationUpdateAdDistributionContentArgs = {
  where: AdDistributionContentWhereUniqueInput
  data: AdDistributionContentUpdateInput
}

export type MutationDeleteAdDistributionContentArgs = {
  where: AdDistributionContentWhereUniqueInput
}

export type MutationUpsertAdDistributionContentArgs = {
  where: AdDistributionContentWhereUniqueInput
  upsert: AdDistributionContentUpsertInput
}

export type MutationPublishAdDistributionContentArgs = {
  where: AdDistributionContentWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishAdDistributionContentArgs = {
  where: AdDistributionContentWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyAdDistributionContentsConnectionArgs = {
  where?: Maybe<AdDistributionContentManyWhereInput>
  data: AdDistributionContentUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyAdDistributionContentsConnectionArgs = {
  where?: Maybe<AdDistributionContentManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyAdDistributionContentsConnectionArgs = {
  where?: Maybe<AdDistributionContentManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyAdDistributionContentsConnectionArgs = {
  where?: Maybe<AdDistributionContentManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyAdDistributionContentsArgs = {
  where?: Maybe<AdDistributionContentManyWhereInput>
  data: AdDistributionContentUpdateManyInput
}

export type MutationDeleteManyAdDistributionContentsArgs = {
  where?: Maybe<AdDistributionContentManyWhereInput>
}

export type MutationPublishManyAdDistributionContentsArgs = {
  where?: Maybe<AdDistributionContentManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyAdDistributionContentsArgs = {
  where?: Maybe<AdDistributionContentManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishAdDistributionContentArgs = {
  where: AdDistributionContentWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishAdDistributionContentArgs = {
  where: AdDistributionContentWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateAssetArgs = {
  data: AssetCreateInput
}

export type MutationUpdateAssetArgs = {
  where: AssetWhereUniqueInput
  data: AssetUpdateInput
}

export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput
}

export type MutationUpsertAssetArgs = {
  where: AssetWhereUniqueInput
  upsert: AssetUpsertInput
}

export type MutationPublishAssetArgs = {
  where: AssetWhereUniqueInput
  locales?: Maybe<Array<Locale>>
  publishBase?: Maybe<Scalars['Boolean']>
  withDefaultLocale?: Maybe<Scalars['Boolean']>
  to?: Array<Stage>
}

export type MutationUnpublishAssetArgs = {
  where: AssetWhereUniqueInput
  from?: Array<Stage>
  locales?: Maybe<Array<Locale>>
  unpublishBase?: Maybe<Scalars['Boolean']>
}

export type MutationUpdateManyAssetsConnectionArgs = {
  where?: Maybe<AssetManyWhereInput>
  data: AssetUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyAssetsConnectionArgs = {
  where?: Maybe<AssetManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyAssetsConnectionArgs = {
  where?: Maybe<AssetManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
  locales?: Maybe<Array<Locale>>
  publishBase?: Maybe<Scalars['Boolean']>
  withDefaultLocale?: Maybe<Scalars['Boolean']>
}

export type MutationUnpublishManyAssetsConnectionArgs = {
  where?: Maybe<AssetManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
  locales?: Maybe<Array<Locale>>
  unpublishBase?: Maybe<Scalars['Boolean']>
}

export type MutationUpdateManyAssetsArgs = {
  where?: Maybe<AssetManyWhereInput>
  data: AssetUpdateManyInput
}

export type MutationDeleteManyAssetsArgs = {
  where?: Maybe<AssetManyWhereInput>
}

export type MutationPublishManyAssetsArgs = {
  where?: Maybe<AssetManyWhereInput>
  to?: Array<Stage>
  locales?: Maybe<Array<Locale>>
  publishBase?: Maybe<Scalars['Boolean']>
  withDefaultLocale?: Maybe<Scalars['Boolean']>
}

export type MutationUnpublishManyAssetsArgs = {
  where?: Maybe<AssetManyWhereInput>
  from?: Array<Stage>
  locales?: Maybe<Array<Locale>>
  unpublishBase?: Maybe<Scalars['Boolean']>
}

export type MutationSchedulePublishAssetArgs = {
  where: AssetWhereUniqueInput
  locales?: Maybe<Array<Locale>>
  publishBase?: Maybe<Scalars['Boolean']>
  withDefaultLocale?: Maybe<Scalars['Boolean']>
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishAssetArgs = {
  where: AssetWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
  locales?: Maybe<Array<Locale>>
  unpublishBase?: Maybe<Scalars['Boolean']>
}

export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput
}

export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput
}

export type MutationUpdateScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput
  data: ScheduledReleaseUpdateInput
}

export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput
}

export type MutationCreateWordCategoryArgs = {
  data: WordCategoryCreateInput
}

export type MutationUpdateWordCategoryArgs = {
  where: WordCategoryWhereUniqueInput
  data: WordCategoryUpdateInput
}

export type MutationDeleteWordCategoryArgs = {
  where: WordCategoryWhereUniqueInput
}

export type MutationUpsertWordCategoryArgs = {
  where: WordCategoryWhereUniqueInput
  upsert: WordCategoryUpsertInput
}

export type MutationPublishWordCategoryArgs = {
  where: WordCategoryWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishWordCategoryArgs = {
  where: WordCategoryWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyWordCategoriesConnectionArgs = {
  where?: Maybe<WordCategoryManyWhereInput>
  data: WordCategoryUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyWordCategoriesConnectionArgs = {
  where?: Maybe<WordCategoryManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyWordCategoriesConnectionArgs = {
  where?: Maybe<WordCategoryManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyWordCategoriesConnectionArgs = {
  where?: Maybe<WordCategoryManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyWordCategoriesArgs = {
  where?: Maybe<WordCategoryManyWhereInput>
  data: WordCategoryUpdateManyInput
}

export type MutationDeleteManyWordCategoriesArgs = {
  where?: Maybe<WordCategoryManyWhereInput>
}

export type MutationPublishManyWordCategoriesArgs = {
  where?: Maybe<WordCategoryManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyWordCategoriesArgs = {
  where?: Maybe<WordCategoryManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishWordCategoryArgs = {
  where: WordCategoryWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishWordCategoryArgs = {
  where: WordCategoryWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateWordArgs = {
  data: WordCreateInput
}

export type MutationUpdateWordArgs = {
  where: WordWhereUniqueInput
  data: WordUpdateInput
}

export type MutationDeleteWordArgs = {
  where: WordWhereUniqueInput
}

export type MutationUpsertWordArgs = {
  where: WordWhereUniqueInput
  upsert: WordUpsertInput
}

export type MutationPublishWordArgs = {
  where: WordWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishWordArgs = {
  where: WordWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyWordsConnectionArgs = {
  where?: Maybe<WordManyWhereInput>
  data: WordUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyWordsConnectionArgs = {
  where?: Maybe<WordManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyWordsConnectionArgs = {
  where?: Maybe<WordManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyWordsConnectionArgs = {
  where?: Maybe<WordManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyWordsArgs = {
  where?: Maybe<WordManyWhereInput>
  data: WordUpdateManyInput
}

export type MutationDeleteManyWordsArgs = {
  where?: Maybe<WordManyWhereInput>
}

export type MutationPublishManyWordsArgs = {
  where?: Maybe<WordManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyWordsArgs = {
  where?: Maybe<WordManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishWordArgs = {
  where: WordWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishWordArgs = {
  where: WordWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateIssueArgs = {
  data: IssueCreateInput
}

export type MutationUpdateIssueArgs = {
  where: IssueWhereUniqueInput
  data: IssueUpdateInput
}

export type MutationDeleteIssueArgs = {
  where: IssueWhereUniqueInput
}

export type MutationUpsertIssueArgs = {
  where: IssueWhereUniqueInput
  upsert: IssueUpsertInput
}

export type MutationPublishIssueArgs = {
  where: IssueWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishIssueArgs = {
  where: IssueWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyIssuesConnectionArgs = {
  where?: Maybe<IssueManyWhereInput>
  data: IssueUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyIssuesConnectionArgs = {
  where?: Maybe<IssueManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyIssuesConnectionArgs = {
  where?: Maybe<IssueManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyIssuesConnectionArgs = {
  where?: Maybe<IssueManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyIssuesArgs = {
  where?: Maybe<IssueManyWhereInput>
  data: IssueUpdateManyInput
}

export type MutationDeleteManyIssuesArgs = {
  where?: Maybe<IssueManyWhereInput>
}

export type MutationPublishManyIssuesArgs = {
  where?: Maybe<IssueManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyIssuesArgs = {
  where?: Maybe<IssueManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishIssueArgs = {
  where: IssueWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishIssueArgs = {
  where: IssueWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationCreateIssueCategoryArgs = {
  data: IssueCategoryCreateInput
}

export type MutationUpdateIssueCategoryArgs = {
  where: IssueCategoryWhereUniqueInput
  data: IssueCategoryUpdateInput
}

export type MutationDeleteIssueCategoryArgs = {
  where: IssueCategoryWhereUniqueInput
}

export type MutationUpsertIssueCategoryArgs = {
  where: IssueCategoryWhereUniqueInput
  upsert: IssueCategoryUpsertInput
}

export type MutationPublishIssueCategoryArgs = {
  where: IssueCategoryWhereUniqueInput
  to?: Array<Stage>
}

export type MutationUnpublishIssueCategoryArgs = {
  where: IssueCategoryWhereUniqueInput
  from?: Array<Stage>
}

export type MutationUpdateManyIssueCategoriesConnectionArgs = {
  where?: Maybe<IssueCategoryManyWhereInput>
  data: IssueCategoryUpdateManyInput
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationDeleteManyIssueCategoriesConnectionArgs = {
  where?: Maybe<IssueCategoryManyWhereInput>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationPublishManyIssueCategoriesConnectionArgs = {
  where?: Maybe<IssueCategoryManyWhereInput>
  from?: Maybe<Stage>
  to?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUnpublishManyIssueCategoriesConnectionArgs = {
  where?: Maybe<IssueCategoryManyWhereInput>
  stage?: Maybe<Stage>
  from?: Array<Stage>
  skip?: Maybe<Scalars['Int']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['ID']>
  after?: Maybe<Scalars['ID']>
}

export type MutationUpdateManyIssueCategoriesArgs = {
  where?: Maybe<IssueCategoryManyWhereInput>
  data: IssueCategoryUpdateManyInput
}

export type MutationDeleteManyIssueCategoriesArgs = {
  where?: Maybe<IssueCategoryManyWhereInput>
}

export type MutationPublishManyIssueCategoriesArgs = {
  where?: Maybe<IssueCategoryManyWhereInput>
  to?: Array<Stage>
}

export type MutationUnpublishManyIssueCategoriesArgs = {
  where?: Maybe<IssueCategoryManyWhereInput>
  from?: Array<Stage>
}

export type MutationSchedulePublishIssueCategoryArgs = {
  where: IssueCategoryWhereUniqueInput
  to?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

export type MutationScheduleUnpublishIssueCategoryArgs = {
  where: IssueCategoryWhereUniqueInput
  from?: Array<Stage>
  releaseAt?: Maybe<Scalars['DateTime']>
  releaseId?: Maybe<Scalars['String']>
}

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']
  /** The Stage of an object */
  stage: Stage
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars['Int']>
}

export type PickupCatalog = Node & {
  __typename?: 'PickupCatalog'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<PickupCatalog>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  catalogId: Scalars['String']
  sort?: Maybe<Scalars['Int']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  scheduledIn: Array<ScheduledOperation>
  /** List of PickupCatalog versions */
  history: Array<Version>
}

export type PickupCatalogDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type PickupCatalogCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type PickupCatalogUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type PickupCatalogPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type PickupCatalogScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type PickupCatalogHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type PickupCatalogConnectInput = {
  /** Document to connect */
  where: PickupCatalogWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type PickupCatalogConnection = {
  __typename?: 'PickupCatalogConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<PickupCatalogEdge>
  aggregate: Aggregate
}

export type PickupCatalogCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  catalogId: Scalars['String']
  sort?: Maybe<Scalars['Int']>
}

export type PickupCatalogCreateManyInlineInput = {
  /** Create and connect multiple existing PickupCatalog documents */
  create?: Maybe<Array<PickupCatalogCreateInput>>
  /** Connect multiple existing PickupCatalog documents */
  connect?: Maybe<Array<PickupCatalogWhereUniqueInput>>
}

export type PickupCatalogCreateOneInlineInput = {
  /** Create and connect one PickupCatalog document */
  create?: Maybe<PickupCatalogCreateInput>
  /** Connect one existing PickupCatalog document */
  connect?: Maybe<PickupCatalogWhereUniqueInput>
}

/** An edge in a connection. */
export type PickupCatalogEdge = {
  __typename?: 'PickupCatalogEdge'
  /** The item at the end of the edge. */
  node: PickupCatalog
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type PickupCatalogManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PickupCatalogWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PickupCatalogWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PickupCatalogWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  catalogId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogId_not_ends_with?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  /** All values that are not equal to given value. */
  sort_not?: Maybe<Scalars['Int']>
  /** All values that are contained in given list. */
  sort_in?: Maybe<Array<Scalars['Int']>>
  /** All values that are not contained in given list. */
  sort_not_in?: Maybe<Array<Scalars['Int']>>
  /** All values less than the given value. */
  sort_lt?: Maybe<Scalars['Int']>
  /** All values less than or equal the given value. */
  sort_lte?: Maybe<Scalars['Int']>
  /** All values greater than the given value. */
  sort_gt?: Maybe<Scalars['Int']>
  /** All values greater than or equal the given value. */
  sort_gte?: Maybe<Scalars['Int']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum PickupCatalogOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  CatalogIdAsc = 'catalogId_ASC',
  CatalogIdDesc = 'catalogId_DESC',
  SortAsc = 'sort_ASC',
  SortDesc = 'sort_DESC',
}

export type PickupCatalogUpdateInput = {
  catalogId?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
}

export type PickupCatalogUpdateManyInlineInput = {
  /** Create and connect multiple PickupCatalog documents */
  create?: Maybe<Array<PickupCatalogCreateInput>>
  /** Connect multiple existing PickupCatalog documents */
  connect?: Maybe<Array<PickupCatalogConnectInput>>
  /** Override currently-connected documents with multiple existing PickupCatalog documents */
  set?: Maybe<Array<PickupCatalogWhereUniqueInput>>
  /** Update multiple PickupCatalog documents */
  update?: Maybe<Array<PickupCatalogUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple PickupCatalog documents */
  upsert?: Maybe<Array<PickupCatalogUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple PickupCatalog documents */
  disconnect?: Maybe<Array<PickupCatalogWhereUniqueInput>>
  /** Delete multiple PickupCatalog documents */
  delete?: Maybe<Array<PickupCatalogWhereUniqueInput>>
}

export type PickupCatalogUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: Maybe<Scalars['String']>
}

export type PickupCatalogUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: PickupCatalogWhereInput
  /** Update many input */
  data: PickupCatalogUpdateManyInput
}

export type PickupCatalogUpdateOneInlineInput = {
  /** Create and connect one PickupCatalog document */
  create?: Maybe<PickupCatalogCreateInput>
  /** Update single PickupCatalog document */
  update?: Maybe<PickupCatalogUpdateWithNestedWhereUniqueInput>
  /** Upsert single PickupCatalog document */
  upsert?: Maybe<PickupCatalogUpsertWithNestedWhereUniqueInput>
  /** Connect existing PickupCatalog document */
  connect?: Maybe<PickupCatalogWhereUniqueInput>
  /** Disconnect currently connected PickupCatalog document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected PickupCatalog document */
  delete?: Maybe<Scalars['Boolean']>
}

export type PickupCatalogUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PickupCatalogWhereUniqueInput
  /** Document to update */
  data: PickupCatalogUpdateInput
}

export type PickupCatalogUpsertInput = {
  /** Create document if it didn't exist */
  create: PickupCatalogCreateInput
  /** Update document if it exists */
  update: PickupCatalogUpdateInput
}

export type PickupCatalogUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: PickupCatalogWhereUniqueInput
  /** Upsert data */
  data: PickupCatalogUpsertInput
}

/** Identifies documents */
export type PickupCatalogWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PickupCatalogWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PickupCatalogWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PickupCatalogWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  catalogId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogId_not_ends_with?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  /** All values that are not equal to given value. */
  sort_not?: Maybe<Scalars['Int']>
  /** All values that are contained in given list. */
  sort_in?: Maybe<Array<Scalars['Int']>>
  /** All values that are not contained in given list. */
  sort_not_in?: Maybe<Array<Scalars['Int']>>
  /** All values less than the given value. */
  sort_lt?: Maybe<Scalars['Int']>
  /** All values less than or equal the given value. */
  sort_lte?: Maybe<Scalars['Int']>
  /** All values greater than the given value. */
  sort_gt?: Maybe<Scalars['Int']>
  /** All values greater than or equal the given value. */
  sort_gte?: Maybe<Scalars['Int']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References PickupCatalog record uniquely */
export type PickupCatalogWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  catalogId?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
}

export type Provider = Node & {
  __typename?: 'Provider'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Provider>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  key: Scalars['String']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  thumbnail?: Maybe<Asset>
  articles: Array<Article>
  scheduledIn: Array<ScheduledOperation>
  /** List of Provider versions */
  history: Array<Version>
}

export type ProviderDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type ProviderCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ProviderUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ProviderPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ProviderThumbnailArgs = {
  locales?: Maybe<Array<Locale>>
}

export type ProviderArticlesArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type ProviderScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type ProviderHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type ProviderConnectInput = {
  /** Document to connect */
  where: ProviderWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type ProviderConnection = {
  __typename?: 'ProviderConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<ProviderEdge>
  aggregate: Aggregate
}

export type ProviderCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  key: Scalars['String']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  thumbnail?: Maybe<AssetCreateOneInlineInput>
  articles?: Maybe<ArticleCreateManyInlineInput>
}

export type ProviderCreateManyInlineInput = {
  /** Create and connect multiple existing Provider documents */
  create?: Maybe<Array<ProviderCreateInput>>
  /** Connect multiple existing Provider documents */
  connect?: Maybe<Array<ProviderWhereUniqueInput>>
}

export type ProviderCreateOneInlineInput = {
  /** Create and connect one Provider document */
  create?: Maybe<ProviderCreateInput>
  /** Connect one existing Provider document */
  connect?: Maybe<ProviderWhereUniqueInput>
}

/** An edge in a connection. */
export type ProviderEdge = {
  __typename?: 'ProviderEdge'
  /** The item at the end of the edge. */
  node: Provider
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type ProviderManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProviderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProviderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProviderWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  key?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  key_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  key_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  key_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  key_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  key_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  key_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  key_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  key_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  key_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  articles_every?: Maybe<ArticleWhereInput>
  articles_some?: Maybe<ArticleWhereInput>
  articles_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum ProviderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
}

export enum ProviderType {
  Jiji = 'Jiji',
}

export type ProviderUpdateInput = {
  key?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  thumbnail?: Maybe<AssetUpdateOneInlineInput>
  articles?: Maybe<ArticleUpdateManyInlineInput>
}

export type ProviderUpdateManyInlineInput = {
  /** Create and connect multiple Provider documents */
  create?: Maybe<Array<ProviderCreateInput>>
  /** Connect multiple existing Provider documents */
  connect?: Maybe<Array<ProviderConnectInput>>
  /** Override currently-connected documents with multiple existing Provider documents */
  set?: Maybe<Array<ProviderWhereUniqueInput>>
  /** Update multiple Provider documents */
  update?: Maybe<Array<ProviderUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Provider documents */
  upsert?: Maybe<Array<ProviderUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Provider documents */
  disconnect?: Maybe<Array<ProviderWhereUniqueInput>>
  /** Delete multiple Provider documents */
  delete?: Maybe<Array<ProviderWhereUniqueInput>>
}

export type ProviderUpdateManyInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type ProviderUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: ProviderWhereInput
  /** Update many input */
  data: ProviderUpdateManyInput
}

export type ProviderUpdateOneInlineInput = {
  /** Create and connect one Provider document */
  create?: Maybe<ProviderCreateInput>
  /** Update single Provider document */
  update?: Maybe<ProviderUpdateWithNestedWhereUniqueInput>
  /** Upsert single Provider document */
  upsert?: Maybe<ProviderUpsertWithNestedWhereUniqueInput>
  /** Connect existing Provider document */
  connect?: Maybe<ProviderWhereUniqueInput>
  /** Disconnect currently connected Provider document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Provider document */
  delete?: Maybe<Scalars['Boolean']>
}

export type ProviderUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ProviderWhereUniqueInput
  /** Document to update */
  data: ProviderUpdateInput
}

export type ProviderUpsertInput = {
  /** Create document if it didn't exist */
  create: ProviderCreateInput
  /** Update document if it exists */
  update: ProviderUpdateInput
}

export type ProviderUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ProviderWhereUniqueInput
  /** Upsert data */
  data: ProviderUpsertInput
}

/** Identifies documents */
export type ProviderWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProviderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProviderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProviderWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  key?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  key_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  key_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  key_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  key_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  key_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  key_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  key_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  key_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  key_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  articles_every?: Maybe<ArticleWhereInput>
  articles_some?: Maybe<ArticleWhereInput>
  articles_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Provider record uniquely */
export type ProviderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
}

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale
  /** Stages to publish selected locales to */
  stages: Array<Stage>
}

export type Query = {
  __typename?: 'Query'
  /** Fetches an object given its ID */
  node?: Maybe<Node>
  /** Retrieve multiple articles */
  articles: Array<Article>
  /** Retrieve a single article */
  article?: Maybe<Article>
  /** Retrieve multiple articles using the Relay connection interface */
  articlesConnection: ArticleConnection
  /** Retrieve document version */
  articleVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple pickupCatalogs */
  pickupCatalogs: Array<PickupCatalog>
  /** Retrieve a single pickupCatalog */
  pickupCatalog?: Maybe<PickupCatalog>
  /** Retrieve multiple pickupCatalogs using the Relay connection interface */
  pickupCatalogsConnection: PickupCatalogConnection
  /** Retrieve document version */
  pickupCatalogVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple stories */
  stories: Array<Story>
  /** Retrieve a single story */
  story?: Maybe<Story>
  /** Retrieve multiple stories using the Relay connection interface */
  storiesConnection: StoryConnection
  /** Retrieve document version */
  storyVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple providers */
  providers: Array<Provider>
  /** Retrieve a single provider */
  provider?: Maybe<Provider>
  /** Retrieve multiple providers using the Relay connection interface */
  providersConnection: ProviderConnection
  /** Retrieve document version */
  providerVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple informations */
  informations: Array<Information>
  /** Retrieve a single information */
  information?: Maybe<Information>
  /** Retrieve multiple informations using the Relay connection interface */
  informationsConnection: InformationConnection
  /** Retrieve document version */
  informationVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple staticPages */
  staticPages: Array<StaticPage>
  /** Retrieve a single staticPage */
  staticPage?: Maybe<StaticPage>
  /** Retrieve multiple staticPages using the Relay connection interface */
  staticPagesConnection: StaticPageConnection
  /** Retrieve document version */
  staticPageVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple faqs */
  faqs: Array<Faq>
  /** Retrieve a single faq */
  faq?: Maybe<Faq>
  /** Retrieve multiple faqs using the Relay connection interface */
  faqsConnection: FaqConnection
  /** Retrieve document version */
  faqVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple categories */
  categories: Array<Category>
  /** Retrieve a single category */
  category?: Maybe<Category>
  /** Retrieve multiple categories using the Relay connection interface */
  categoriesConnection: CategoryConnection
  /** Retrieve document version */
  categoryVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple authors */
  authors: Array<Author>
  /** Retrieve a single author */
  author?: Maybe<Author>
  /** Retrieve multiple authors using the Relay connection interface */
  authorsConnection: AuthorConnection
  /** Retrieve document version */
  authorVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple tags */
  tags: Array<Tag>
  /** Retrieve a single tag */
  tag?: Maybe<Tag>
  /** Retrieve multiple tags using the Relay connection interface */
  tagsConnection: TagConnection
  /** Retrieve document version */
  tagVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple adDistributionManagements */
  adDistributionManagements: Array<AdDistributionManagement>
  /** Retrieve a single adDistributionManagement */
  adDistributionManagement?: Maybe<AdDistributionManagement>
  /** Retrieve multiple adDistributionManagements using the Relay connection interface */
  adDistributionManagementsConnection: AdDistributionManagementConnection
  /** Retrieve document version */
  adDistributionManagementVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple adDistributionContents */
  adDistributionContents: Array<AdDistributionContent>
  /** Retrieve a single adDistributionContent */
  adDistributionContent?: Maybe<AdDistributionContent>
  /** Retrieve multiple adDistributionContents using the Relay connection interface */
  adDistributionContentsConnection: AdDistributionContentConnection
  /** Retrieve document version */
  adDistributionContentVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple assets */
  assets: Array<Asset>
  /** Retrieve a single asset */
  asset?: Maybe<Asset>
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple users */
  users: Array<User>
  /** Retrieve a single user */
  user?: Maybe<User>
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<ScheduledOperation>
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<ScheduledRelease>
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection
  /** Retrieve multiple wordCategories */
  wordCategories: Array<WordCategory>
  /** Retrieve a single wordCategory */
  wordCategory?: Maybe<WordCategory>
  /** Retrieve multiple wordCategories using the Relay connection interface */
  wordCategoriesConnection: WordCategoryConnection
  /** Retrieve document version */
  wordCategoryVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple words */
  words: Array<Word>
  /** Retrieve a single word */
  word?: Maybe<Word>
  /** Retrieve multiple words using the Relay connection interface */
  wordsConnection: WordConnection
  /** Retrieve document version */
  wordVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple issues */
  issues: Array<Issue>
  /** Retrieve a single issue */
  issue?: Maybe<Issue>
  /** Retrieve multiple issues using the Relay connection interface */
  issuesConnection: IssueConnection
  /** Retrieve document version */
  issueVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple issueCategories */
  issueCategories: Array<IssueCategory>
  /** Retrieve a single issueCategory */
  issueCategory?: Maybe<IssueCategory>
  /** Retrieve multiple issueCategories using the Relay connection interface */
  issueCategoriesConnection: IssueCategoryConnection
  /** Retrieve document version */
  issueCategoryVersion?: Maybe<DocumentVersion>
}

export type QueryNodeArgs = {
  id: Scalars['ID']
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryArticlesArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryArticleArgs = {
  where: ArticleWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryArticlesConnectionArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryArticleVersionArgs = {
  where: VersionWhereInput
}

export type QueryPickupCatalogsArgs = {
  where?: Maybe<PickupCatalogWhereInput>
  orderBy?: Maybe<PickupCatalogOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryPickupCatalogArgs = {
  where: PickupCatalogWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryPickupCatalogsConnectionArgs = {
  where?: Maybe<PickupCatalogWhereInput>
  orderBy?: Maybe<PickupCatalogOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryPickupCatalogVersionArgs = {
  where: VersionWhereInput
}

export type QueryStoriesArgs = {
  where?: Maybe<StoryWhereInput>
  orderBy?: Maybe<StoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryStoryArgs = {
  where: StoryWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryStoriesConnectionArgs = {
  where?: Maybe<StoryWhereInput>
  orderBy?: Maybe<StoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryStoryVersionArgs = {
  where: VersionWhereInput
}

export type QueryProvidersArgs = {
  where?: Maybe<ProviderWhereInput>
  orderBy?: Maybe<ProviderOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryProviderArgs = {
  where: ProviderWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryProvidersConnectionArgs = {
  where?: Maybe<ProviderWhereInput>
  orderBy?: Maybe<ProviderOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryProviderVersionArgs = {
  where: VersionWhereInput
}

export type QueryInformationsArgs = {
  where?: Maybe<InformationWhereInput>
  orderBy?: Maybe<InformationOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryInformationArgs = {
  where: InformationWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryInformationsConnectionArgs = {
  where?: Maybe<InformationWhereInput>
  orderBy?: Maybe<InformationOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryInformationVersionArgs = {
  where: VersionWhereInput
}

export type QueryStaticPagesArgs = {
  where?: Maybe<StaticPageWhereInput>
  orderBy?: Maybe<StaticPageOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryStaticPageArgs = {
  where: StaticPageWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryStaticPagesConnectionArgs = {
  where?: Maybe<StaticPageWhereInput>
  orderBy?: Maybe<StaticPageOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryStaticPageVersionArgs = {
  where: VersionWhereInput
}

export type QueryFaqsArgs = {
  where?: Maybe<FaqWhereInput>
  orderBy?: Maybe<FaqOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryFaqArgs = {
  where: FaqWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryFaqsConnectionArgs = {
  where?: Maybe<FaqWhereInput>
  orderBy?: Maybe<FaqOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryFaqVersionArgs = {
  where: VersionWhereInput
}

export type QueryCategoriesArgs = {
  where?: Maybe<CategoryWhereInput>
  orderBy?: Maybe<CategoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryCategoriesConnectionArgs = {
  where?: Maybe<CategoryWhereInput>
  orderBy?: Maybe<CategoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryCategoryVersionArgs = {
  where: VersionWhereInput
}

export type QueryAuthorsArgs = {
  where?: Maybe<AuthorWhereInput>
  orderBy?: Maybe<AuthorOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAuthorArgs = {
  where: AuthorWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAuthorsConnectionArgs = {
  where?: Maybe<AuthorWhereInput>
  orderBy?: Maybe<AuthorOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAuthorVersionArgs = {
  where: VersionWhereInput
}

export type QueryTagsArgs = {
  where?: Maybe<TagWhereInput>
  orderBy?: Maybe<TagOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryTagArgs = {
  where: TagWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryTagsConnectionArgs = {
  where?: Maybe<TagWhereInput>
  orderBy?: Maybe<TagOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryTagVersionArgs = {
  where: VersionWhereInput
}

export type QueryAdDistributionManagementsArgs = {
  where?: Maybe<AdDistributionManagementWhereInput>
  orderBy?: Maybe<AdDistributionManagementOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAdDistributionManagementArgs = {
  where: AdDistributionManagementWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAdDistributionManagementsConnectionArgs = {
  where?: Maybe<AdDistributionManagementWhereInput>
  orderBy?: Maybe<AdDistributionManagementOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAdDistributionManagementVersionArgs = {
  where: VersionWhereInput
}

export type QueryAdDistributionContentsArgs = {
  where?: Maybe<AdDistributionContentWhereInput>
  orderBy?: Maybe<AdDistributionContentOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAdDistributionContentArgs = {
  where: AdDistributionContentWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAdDistributionContentsConnectionArgs = {
  where?: Maybe<AdDistributionContentWhereInput>
  orderBy?: Maybe<AdDistributionContentOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAdDistributionContentVersionArgs = {
  where: VersionWhereInput
}

export type QueryAssetsArgs = {
  where?: Maybe<AssetWhereInput>
  orderBy?: Maybe<AssetOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAssetArgs = {
  where: AssetWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAssetsConnectionArgs = {
  where?: Maybe<AssetWhereInput>
  orderBy?: Maybe<AssetOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryAssetVersionArgs = {
  where: VersionWhereInput
}

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>
  orderBy?: Maybe<UserOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryUserArgs = {
  where: UserWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryUsersConnectionArgs = {
  where?: Maybe<UserWhereInput>
  orderBy?: Maybe<UserOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryScheduledOperationsArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  orderBy?: Maybe<ScheduledOperationOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryScheduledOperationsConnectionArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  orderBy?: Maybe<ScheduledOperationOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryScheduledReleasesArgs = {
  where?: Maybe<ScheduledReleaseWhereInput>
  orderBy?: Maybe<ScheduledReleaseOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryScheduledReleasesConnectionArgs = {
  where?: Maybe<ScheduledReleaseWhereInput>
  orderBy?: Maybe<ScheduledReleaseOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryWordCategoriesArgs = {
  where?: Maybe<WordCategoryWhereInput>
  orderBy?: Maybe<WordCategoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryWordCategoryArgs = {
  where: WordCategoryWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryWordCategoriesConnectionArgs = {
  where?: Maybe<WordCategoryWhereInput>
  orderBy?: Maybe<WordCategoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryWordCategoryVersionArgs = {
  where: VersionWhereInput
}

export type QueryWordsArgs = {
  where?: Maybe<WordWhereInput>
  orderBy?: Maybe<WordOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryWordArgs = {
  where: WordWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryWordsConnectionArgs = {
  where?: Maybe<WordWhereInput>
  orderBy?: Maybe<WordOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryWordVersionArgs = {
  where: VersionWhereInput
}

export type QueryIssuesArgs = {
  where?: Maybe<IssueWhereInput>
  orderBy?: Maybe<IssueOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryIssueArgs = {
  where: IssueWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryIssuesConnectionArgs = {
  where?: Maybe<IssueWhereInput>
  orderBy?: Maybe<IssueOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryIssueVersionArgs = {
  where: VersionWhereInput
}

export type QueryIssueCategoriesArgs = {
  where?: Maybe<IssueCategoryWhereInput>
  orderBy?: Maybe<IssueCategoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryIssueCategoryArgs = {
  where: IssueCategoryWhereUniqueInput
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryIssueCategoriesConnectionArgs = {
  where?: Maybe<IssueCategoryWhereInput>
  orderBy?: Maybe<IssueCategoryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  stage?: Stage
  locales?: Array<Locale>
}

export type QueryIssueCategoryVersionArgs = {
  where: VersionWhereInput
}

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: 'RGBA'
  r: Scalars['RGBAHue']
  g: Scalars['RGBAHue']
  b: Scalars['RGBAHue']
  a: Scalars['RGBATransparency']
}

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  r: Scalars['RGBAHue']
  g: Scalars['RGBAHue']
  b: Scalars['RGBAHue']
  a: Scalars['RGBATransparency']
}

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: 'RichText'
  /** Returns AST representation */
  raw: Scalars['RichTextAST']
  /** Returns HTMl representation */
  html: Scalars['String']
  /** Returns Markdown representation */
  markdown: Scalars['String']
  /** Returns plain-text contents of RichText */
  text: Scalars['String']
}

/** Scheduled Operation system model */
export type ScheduledOperation = Node & {
  __typename?: 'ScheduledOperation'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<ScheduledOperation>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  /** Operation description */
  description?: Maybe<Scalars['String']>
  /** Operation error message */
  errorMessage?: Maybe<Scalars['String']>
  /** Raw operation payload including all details, this field is subject to change */
  rawPayload: Scalars['Json']
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  /** The release this operation is scheduled for */
  release?: Maybe<ScheduledRelease>
  /** operation Status */
  status: ScheduledOperationStatus
  affectedDocuments: Array<ScheduledOperationAffectedDocument>
}

/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type ScheduledOperationAffectedDocument =
  | AdDistributionContent
  | AdDistributionManagement
  | Article
  | Asset
  | Author
  | Category
  | Faq
  | Information
  | Issue
  | IssueCategory
  | PickupCatalog
  | Provider
  | StaticPage
  | Story
  | Tag
  | Word
  | WordCategory

export type ScheduledOperationConnectInput = {
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: 'ScheduledOperationConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>
  aggregate: Aggregate
}

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: Maybe<Array<ScheduledOperationWhereUniqueInput>>
}

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: Maybe<ScheduledOperationWhereUniqueInput>
}

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: 'ScheduledOperationEdge'
  /** The item at the end of the edge. */
  node: ScheduledOperation
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ScheduledOperationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ScheduledOperationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ScheduledOperationWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  errorMessage?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  errorMessage_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  release?: Maybe<ScheduledReleaseWhereInput>
  status?: Maybe<ScheduledOperationStatus>
  /** All values that are not equal to given value. */
  status_not?: Maybe<ScheduledOperationStatus>
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<ScheduledOperationStatus>>
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<ScheduledOperationStatus>>
}

export enum ScheduledOperationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: Maybe<Array<ScheduledOperationConnectInput>>
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: Maybe<Array<ScheduledOperationWhereUniqueInput>>
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: Maybe<Array<ScheduledOperationWhereUniqueInput>>
}

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: Maybe<ScheduledOperationWhereUniqueInput>
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: Maybe<Scalars['Boolean']>
}

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ScheduledOperationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ScheduledOperationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ScheduledOperationWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  errorMessage?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  errorMessage_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  release?: Maybe<ScheduledReleaseWhereInput>
  status?: Maybe<ScheduledOperationStatus>
  /** All values that are not equal to given value. */
  status_not?: Maybe<ScheduledOperationStatus>
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<ScheduledOperationStatus>>
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<ScheduledOperationStatus>>
}

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

/** Scheduled Release system model */
export type ScheduledRelease = Node & {
  __typename?: 'ScheduledRelease'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<ScheduledRelease>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  /** Release Title */
  title?: Maybe<Scalars['String']>
  /** Release description */
  description?: Maybe<Scalars['String']>
  /** Release error message */
  errorMessage?: Maybe<Scalars['String']>
  /** Whether scheduled release should be run */
  isActive: Scalars['Boolean']
  /** Whether scheduled release is implicit */
  isImplicit: Scalars['Boolean']
  /** Release date and time */
  releaseAt?: Maybe<Scalars['DateTime']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  /** Operations to run with this release */
  operations: Array<ScheduledOperation>
  /** Release Status */
  status: ScheduledReleaseStatus
}

/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  orderBy?: Maybe<ScheduledOperationOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type ScheduledReleaseConnectInput = {
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: 'ScheduledReleaseConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>
  aggregate: Aggregate
}

export type ScheduledReleaseCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  errorMessage?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  releaseAt?: Maybe<Scalars['DateTime']>
}

export type ScheduledReleaseCreateManyInlineInput = {
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: Maybe<Array<ScheduledReleaseCreateInput>>
  /** Connect multiple existing ScheduledRelease documents */
  connect?: Maybe<Array<ScheduledReleaseWhereUniqueInput>>
}

export type ScheduledReleaseCreateOneInlineInput = {
  /** Create and connect one ScheduledRelease document */
  create?: Maybe<ScheduledReleaseCreateInput>
  /** Connect one existing ScheduledRelease document */
  connect?: Maybe<ScheduledReleaseWhereUniqueInput>
}

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: 'ScheduledReleaseEdge'
  /** The item at the end of the edge. */
  node: ScheduledRelease
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ScheduledReleaseWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ScheduledReleaseWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ScheduledReleaseWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  errorMessage?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  errorMessage_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isActive_not?: Maybe<Scalars['Boolean']>
  isImplicit?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isImplicit_not?: Maybe<Scalars['Boolean']>
  releaseAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  releaseAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  releaseAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  releaseAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  releaseAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  releaseAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  releaseAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  releaseAt_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  operations_every?: Maybe<ScheduledOperationWhereInput>
  operations_some?: Maybe<ScheduledOperationWhereInput>
  operations_none?: Maybe<ScheduledOperationWhereInput>
  status?: Maybe<ScheduledReleaseStatus>
  /** All values that are not equal to given value. */
  status_not?: Maybe<ScheduledReleaseStatus>
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<ScheduledReleaseStatus>>
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<ScheduledReleaseStatus>>
}

export enum ScheduledReleaseOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsImplicitAsc = 'isImplicit_ASC',
  IsImplicitDesc = 'isImplicit_DESC',
  ReleaseAtAsc = 'releaseAt_ASC',
  ReleaseAtDesc = 'releaseAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

export type ScheduledReleaseUpdateInput = {
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  errorMessage?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  releaseAt?: Maybe<Scalars['DateTime']>
}

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Create and connect multiple ScheduledRelease documents */
  create?: Maybe<Array<ScheduledReleaseCreateInput>>
  /** Connect multiple existing ScheduledRelease documents */
  connect?: Maybe<Array<ScheduledReleaseConnectInput>>
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: Maybe<Array<ScheduledReleaseWhereUniqueInput>>
  /** Update multiple ScheduledRelease documents */
  update?: Maybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ScheduledRelease documents */
  upsert?: Maybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: Maybe<Array<ScheduledReleaseWhereUniqueInput>>
  /** Delete multiple ScheduledRelease documents */
  delete?: Maybe<Array<ScheduledReleaseWhereUniqueInput>>
}

export type ScheduledReleaseUpdateManyInput = {
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  errorMessage?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  releaseAt?: Maybe<Scalars['DateTime']>
}

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: ScheduledReleaseWhereInput
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput
}

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Create and connect one ScheduledRelease document */
  create?: Maybe<ScheduledReleaseCreateInput>
  /** Update single ScheduledRelease document */
  update?: Maybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>
  /** Upsert single ScheduledRelease document */
  upsert?: Maybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>
  /** Connect existing ScheduledRelease document */
  connect?: Maybe<ScheduledReleaseWhereUniqueInput>
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected ScheduledRelease document */
  delete?: Maybe<Scalars['Boolean']>
}

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput
  /** Document to update */
  data: ScheduledReleaseUpdateInput
}

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput
}

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput
  /** Upsert data */
  data: ScheduledReleaseUpsertInput
}

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ScheduledReleaseWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ScheduledReleaseWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ScheduledReleaseWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  errorMessage?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  errorMessage_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isActive_not?: Maybe<Scalars['Boolean']>
  isImplicit?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isImplicit_not?: Maybe<Scalars['Boolean']>
  releaseAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  releaseAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  releaseAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  releaseAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  releaseAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  releaseAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  releaseAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  releaseAt_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  operations_every?: Maybe<ScheduledOperationWhereInput>
  operations_some?: Maybe<ScheduledOperationWhereInput>
  operations_none?: Maybe<ScheduledOperationWhereInput>
  status?: Maybe<ScheduledReleaseStatus>
  /** All values that are not equal to given value. */
  status_not?: Maybe<ScheduledReleaseStatus>
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<ScheduledReleaseStatus>>
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<ScheduledReleaseStatus>>
}

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED',
}

export type StaticPage = Node & {
  __typename?: 'StaticPage'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<StaticPage>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  content: RichText
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  staticPageType: StaticPageType
  scheduledIn: Array<ScheduledOperation>
  /** List of StaticPage versions */
  history: Array<Version>
}

export type StaticPageDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type StaticPageCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type StaticPageUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type StaticPagePublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type StaticPageScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type StaticPageHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type StaticPageConnectInput = {
  /** Document to connect */
  where: StaticPageWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type StaticPageConnection = {
  __typename?: 'StaticPageConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<StaticPageEdge>
  aggregate: Aggregate
}

export type StaticPageCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  content: Scalars['RichTextAST']
  staticPageType: StaticPageType
}

export type StaticPageCreateManyInlineInput = {
  /** Create and connect multiple existing StaticPage documents */
  create?: Maybe<Array<StaticPageCreateInput>>
  /** Connect multiple existing StaticPage documents */
  connect?: Maybe<Array<StaticPageWhereUniqueInput>>
}

export type StaticPageCreateOneInlineInput = {
  /** Create and connect one StaticPage document */
  create?: Maybe<StaticPageCreateInput>
  /** Connect one existing StaticPage document */
  connect?: Maybe<StaticPageWhereUniqueInput>
}

/** An edge in a connection. */
export type StaticPageEdge = {
  __typename?: 'StaticPageEdge'
  /** The item at the end of the edge. */
  node: StaticPage
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type StaticPageManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StaticPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StaticPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StaticPageWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  staticPageType?: Maybe<StaticPageType>
  /** All values that are not equal to given value. */
  staticPageType_not?: Maybe<StaticPageType>
  /** All values that are contained in given list. */
  staticPageType_in?: Maybe<Array<StaticPageType>>
  /** All values that are not contained in given list. */
  staticPageType_not_in?: Maybe<Array<StaticPageType>>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum StaticPageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  StaticPageTypeAsc = 'staticPageType_ASC',
  StaticPageTypeDesc = 'staticPageType_DESC',
}

export enum StaticPageType {
  Terms = 'Terms',
  MakerList = 'MakerList',
}

export type StaticPageUpdateInput = {
  title?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  staticPageType?: Maybe<StaticPageType>
}

export type StaticPageUpdateManyInlineInput = {
  /** Create and connect multiple StaticPage documents */
  create?: Maybe<Array<StaticPageCreateInput>>
  /** Connect multiple existing StaticPage documents */
  connect?: Maybe<Array<StaticPageConnectInput>>
  /** Override currently-connected documents with multiple existing StaticPage documents */
  set?: Maybe<Array<StaticPageWhereUniqueInput>>
  /** Update multiple StaticPage documents */
  update?: Maybe<Array<StaticPageUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple StaticPage documents */
  upsert?: Maybe<Array<StaticPageUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple StaticPage documents */
  disconnect?: Maybe<Array<StaticPageWhereUniqueInput>>
  /** Delete multiple StaticPage documents */
  delete?: Maybe<Array<StaticPageWhereUniqueInput>>
}

export type StaticPageUpdateManyInput = {
  content?: Maybe<Scalars['RichTextAST']>
}

export type StaticPageUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: StaticPageWhereInput
  /** Update many input */
  data: StaticPageUpdateManyInput
}

export type StaticPageUpdateOneInlineInput = {
  /** Create and connect one StaticPage document */
  create?: Maybe<StaticPageCreateInput>
  /** Update single StaticPage document */
  update?: Maybe<StaticPageUpdateWithNestedWhereUniqueInput>
  /** Upsert single StaticPage document */
  upsert?: Maybe<StaticPageUpsertWithNestedWhereUniqueInput>
  /** Connect existing StaticPage document */
  connect?: Maybe<StaticPageWhereUniqueInput>
  /** Disconnect currently connected StaticPage document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected StaticPage document */
  delete?: Maybe<Scalars['Boolean']>
}

export type StaticPageUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: StaticPageWhereUniqueInput
  /** Document to update */
  data: StaticPageUpdateInput
}

export type StaticPageUpsertInput = {
  /** Create document if it didn't exist */
  create: StaticPageCreateInput
  /** Update document if it exists */
  update: StaticPageUpdateInput
}

export type StaticPageUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: StaticPageWhereUniqueInput
  /** Upsert data */
  data: StaticPageUpsertInput
}

/** Identifies documents */
export type StaticPageWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StaticPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StaticPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StaticPageWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  staticPageType?: Maybe<StaticPageType>
  /** All values that are not equal to given value. */
  staticPageType_not?: Maybe<StaticPageType>
  /** All values that are contained in given list. */
  staticPageType_in?: Maybe<Array<StaticPageType>>
  /** All values that are not contained in given list. */
  staticPageType_not_in?: Maybe<Array<StaticPageType>>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References StaticPage record uniquely */
export type StaticPageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
  staticPageType?: Maybe<StaticPageType>
}

export type Story = Node & {
  __typename?: 'Story'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Story>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  excerpt?: Maybe<Scalars['String']>
  publishDate: Scalars['DateTime']
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  thumbnail?: Maybe<Asset>
  articles: Array<Article>
  scheduledIn: Array<ScheduledOperation>
  /** List of Story versions */
  history: Array<Version>
}

export type StoryDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type StoryCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type StoryUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type StoryPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type StoryThumbnailArgs = {
  locales?: Maybe<Array<Locale>>
}

export type StoryArticlesArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type StoryScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type StoryHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type StoryConnectInput = {
  /** Document to connect */
  where: StoryWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type StoryConnection = {
  __typename?: 'StoryConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<StoryEdge>
  aggregate: Aggregate
}

export type StoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  excerpt?: Maybe<Scalars['String']>
  publishDate: Scalars['DateTime']
  thumbnail?: Maybe<AssetCreateOneInlineInput>
  articles?: Maybe<ArticleCreateManyInlineInput>
}

export type StoryCreateManyInlineInput = {
  /** Create and connect multiple existing Story documents */
  create?: Maybe<Array<StoryCreateInput>>
  /** Connect multiple existing Story documents */
  connect?: Maybe<Array<StoryWhereUniqueInput>>
}

export type StoryCreateOneInlineInput = {
  /** Create and connect one Story document */
  create?: Maybe<StoryCreateInput>
  /** Connect one existing Story document */
  connect?: Maybe<StoryWhereUniqueInput>
}

/** An edge in a connection. */
export type StoryEdge = {
  __typename?: 'StoryEdge'
  /** The item at the end of the edge. */
  node: Story
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type StoryManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StoryWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  excerpt_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  excerpt_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  excerpt_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  excerpt_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  excerpt_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  excerpt_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  excerpt_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  excerpt_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  excerpt_not_ends_with?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishDate_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishDate_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishDate_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishDate_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishDate_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  articles_every?: Maybe<ArticleWhereInput>
  articles_some?: Maybe<ArticleWhereInput>
  articles_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum StoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ExcerptAsc = 'excerpt_ASC',
  ExcerptDesc = 'excerpt_DESC',
  PublishDateAsc = 'publishDate_ASC',
  PublishDateDesc = 'publishDate_DESC',
}

export type StoryUpdateInput = {
  title?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  thumbnail?: Maybe<AssetUpdateOneInlineInput>
  articles?: Maybe<ArticleUpdateManyInlineInput>
}

export type StoryUpdateManyInlineInput = {
  /** Create and connect multiple Story documents */
  create?: Maybe<Array<StoryCreateInput>>
  /** Connect multiple existing Story documents */
  connect?: Maybe<Array<StoryConnectInput>>
  /** Override currently-connected documents with multiple existing Story documents */
  set?: Maybe<Array<StoryWhereUniqueInput>>
  /** Update multiple Story documents */
  update?: Maybe<Array<StoryUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Story documents */
  upsert?: Maybe<Array<StoryUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Story documents */
  disconnect?: Maybe<Array<StoryWhereUniqueInput>>
  /** Delete multiple Story documents */
  delete?: Maybe<Array<StoryWhereUniqueInput>>
}

export type StoryUpdateManyInput = {
  title?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
}

export type StoryUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: StoryWhereInput
  /** Update many input */
  data: StoryUpdateManyInput
}

export type StoryUpdateOneInlineInput = {
  /** Create and connect one Story document */
  create?: Maybe<StoryCreateInput>
  /** Update single Story document */
  update?: Maybe<StoryUpdateWithNestedWhereUniqueInput>
  /** Upsert single Story document */
  upsert?: Maybe<StoryUpsertWithNestedWhereUniqueInput>
  /** Connect existing Story document */
  connect?: Maybe<StoryWhereUniqueInput>
  /** Disconnect currently connected Story document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Story document */
  delete?: Maybe<Scalars['Boolean']>
}

export type StoryUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: StoryWhereUniqueInput
  /** Document to update */
  data: StoryUpdateInput
}

export type StoryUpsertInput = {
  /** Create document if it didn't exist */
  create: StoryCreateInput
  /** Update document if it exists */
  update: StoryUpdateInput
}

export type StoryUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: StoryWhereUniqueInput
  /** Upsert data */
  data: StoryUpsertInput
}

/** Identifies documents */
export type StoryWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<StoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<StoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<StoryWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  excerpt_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  excerpt_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  excerpt_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  excerpt_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  excerpt_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  excerpt_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  excerpt_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  excerpt_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  excerpt_not_ends_with?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishDate_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishDate_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishDate_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishDate_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishDate_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishDate_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  thumbnail?: Maybe<AssetWhereInput>
  articles_every?: Maybe<ArticleWhereInput>
  articles_some?: Maybe<ArticleWhereInput>
  articles_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Story record uniquely */
export type StoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export enum SystemDateTimeFieldVariation {
  Base = 'BASE',
  Localization = 'LOCALIZATION',
  Combined = 'COMBINED',
}

export type Tag = Node & {
  __typename?: 'Tag'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Tag>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  article: Array<Article>
  scheduledIn: Array<ScheduledOperation>
  /** List of Tag versions */
  history: Array<Version>
}

export type TagDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type TagCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type TagUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type TagPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type TagArticleArgs = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type TagScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type TagHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type TagConnectInput = {
  /** Document to connect */
  where: TagWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type TagConnection = {
  __typename?: 'TagConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<TagEdge>
  aggregate: Aggregate
}

export type TagCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  article?: Maybe<ArticleCreateManyInlineInput>
}

export type TagCreateManyInlineInput = {
  /** Create and connect multiple existing Tag documents */
  create?: Maybe<Array<TagCreateInput>>
  /** Connect multiple existing Tag documents */
  connect?: Maybe<Array<TagWhereUniqueInput>>
}

export type TagCreateOneInlineInput = {
  /** Create and connect one Tag document */
  create?: Maybe<TagCreateInput>
  /** Connect one existing Tag document */
  connect?: Maybe<TagWhereUniqueInput>
}

/** An edge in a connection. */
export type TagEdge = {
  __typename?: 'TagEdge'
  /** The item at the end of the edge. */
  node: Tag
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type TagManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TagWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TagWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TagWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  slug_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  slug_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  slug_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  slug_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  article_every?: Maybe<ArticleWhereInput>
  article_some?: Maybe<ArticleWhereInput>
  article_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum TagOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
}

export type TagUpdateInput = {
  title?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  article?: Maybe<ArticleUpdateManyInlineInput>
}

export type TagUpdateManyInlineInput = {
  /** Create and connect multiple Tag documents */
  create?: Maybe<Array<TagCreateInput>>
  /** Connect multiple existing Tag documents */
  connect?: Maybe<Array<TagConnectInput>>
  /** Override currently-connected documents with multiple existing Tag documents */
  set?: Maybe<Array<TagWhereUniqueInput>>
  /** Update multiple Tag documents */
  update?: Maybe<Array<TagUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Tag documents */
  upsert?: Maybe<Array<TagUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Tag documents */
  disconnect?: Maybe<Array<TagWhereUniqueInput>>
  /** Delete multiple Tag documents */
  delete?: Maybe<Array<TagWhereUniqueInput>>
}

export type TagUpdateManyInput = {
  title?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type TagUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: TagWhereInput
  /** Update many input */
  data: TagUpdateManyInput
}

export type TagUpdateOneInlineInput = {
  /** Create and connect one Tag document */
  create?: Maybe<TagCreateInput>
  /** Update single Tag document */
  update?: Maybe<TagUpdateWithNestedWhereUniqueInput>
  /** Upsert single Tag document */
  upsert?: Maybe<TagUpsertWithNestedWhereUniqueInput>
  /** Connect existing Tag document */
  connect?: Maybe<TagWhereUniqueInput>
  /** Disconnect currently connected Tag document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Tag document */
  delete?: Maybe<Scalars['Boolean']>
}

export type TagUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: TagWhereUniqueInput
  /** Document to update */
  data: TagUpdateInput
}

export type TagUpsertInput = {
  /** Create document if it didn't exist */
  create: TagCreateInput
  /** Update document if it exists */
  update: TagUpdateInput
}

export type TagUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: TagWhereUniqueInput
  /** Upsert data */
  data: TagUpsertInput
}

/** Identifies documents */
export type TagWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TagWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TagWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TagWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  slug_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  slug_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  slug_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  slug_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  article_every?: Maybe<ArticleWhereInput>
  article_some?: Maybe<ArticleWhereInput>
  article_none?: Maybe<ArticleWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Tag record uniquely */
export type TagWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  slug?: Maybe<Scalars['String']>
}

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>
}

/** User system model */
export type User = Node & {
  __typename?: 'User'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<User>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  /** The username */
  name: Scalars['String']
  /** Profile Picture url */
  picture?: Maybe<Scalars['String']>
  /** Flag to determine if user is active or not */
  isActive: Scalars['Boolean']
  /** User Kind. Can be either MEMBER, PAT or PUBLIC */
  kind: UserKind
}

/** User system model */
export type UserDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type UserConnectInput = {
  /** Document to connect */
  where: UserWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<UserEdge>
  aggregate: Aggregate
}

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: Maybe<Array<UserWhereUniqueInput>>
}

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: Maybe<UserWhereUniqueInput>
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge'
  /** The item at the end of the edge. */
  node: User
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** System User Kind */
export enum UserKind {
  Member = 'MEMBER',
  Webhook = 'WEBHOOK',
  Pat = 'PAT',
  Public = 'PUBLIC',
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<UserWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<UserWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<UserWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  picture_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  picture_not_ends_with?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isActive_not?: Maybe<Scalars['Boolean']>
  kind?: Maybe<UserKind>
  /** All values that are not equal to given value. */
  kind_not?: Maybe<UserKind>
  /** All values that are contained in given list. */
  kind_in?: Maybe<Array<UserKind>>
  /** All values that are not contained in given list. */
  kind_not_in?: Maybe<Array<UserKind>>
}

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: Maybe<Array<UserConnectInput>>
  /** Override currently-connected documents with multiple existing User documents */
  set?: Maybe<Array<UserWhereUniqueInput>>
  /** Disconnect multiple User documents */
  disconnect?: Maybe<Array<UserWhereUniqueInput>>
}

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: Maybe<UserWhereUniqueInput>
  /** Disconnect currently connected User document */
  disconnect?: Maybe<Scalars['Boolean']>
}

/** Identifies documents */
export type UserWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<UserWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<UserWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<UserWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  picture_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  picture_not_ends_with?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  /** All values that are not equal to given value. */
  isActive_not?: Maybe<Scalars['Boolean']>
  kind?: Maybe<UserKind>
  /** All values that are not equal to given value. */
  kind_not?: Maybe<UserKind>
  /** All values that are contained in given list. */
  kind_in?: Maybe<Array<UserKind>>
  /** All values that are not contained in given list. */
  kind_not_in?: Maybe<Array<UserKind>>
}

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export type Version = {
  __typename?: 'Version'
  id: Scalars['ID']
  stage: Stage
  revision: Scalars['Int']
  createdAt: Scalars['DateTime']
}

export type VersionWhereInput = {
  id: Scalars['ID']
  stage: Stage
  revision: Scalars['Int']
}

export type Word = Node & {
  __typename?: 'Word'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<Word>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  titleKana: Scalars['String']
  content: RichText
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  wordCategory?: Maybe<WordCategory>
  scheduledIn: Array<ScheduledOperation>
  /** List of Word versions */
  history: Array<Version>
}

export type WordDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type WordCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type WordUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type WordPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type WordWordCategoryArgs = {
  locales?: Maybe<Array<Locale>>
}

export type WordScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type WordHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type WordCategory = Node & {
  __typename?: 'WordCategory'
  /** System stage field */
  stage: Stage
  /** Get the document in other stages */
  documentInStages: Array<WordCategory>
  /** The unique identifier */
  id: Scalars['ID']
  /** The time the document was created */
  createdAt: Scalars['DateTime']
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  name: Scalars['String']
  nameKana: Scalars['String']
  description?: Maybe<RichText>
  /** User that created this document */
  createdBy?: Maybe<User>
  /** User that last updated this document */
  updatedBy?: Maybe<User>
  /** User that last published this document */
  publishedBy?: Maybe<User>
  words: Array<Word>
  scheduledIn: Array<ScheduledOperation>
  /** List of WordCategory versions */
  history: Array<Version>
}

export type WordCategoryDocumentInStagesArgs = {
  stages?: Array<Stage>
  includeCurrent?: Scalars['Boolean']
  inheritLocale?: Scalars['Boolean']
}

export type WordCategoryCreatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type WordCategoryUpdatedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type WordCategoryPublishedByArgs = {
  locales?: Maybe<Array<Locale>>
}

export type WordCategoryWordsArgs = {
  where?: Maybe<WordWhereInput>
  orderBy?: Maybe<WordOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type WordCategoryScheduledInArgs = {
  where?: Maybe<ScheduledOperationWhereInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  locales?: Maybe<Array<Locale>>
}

export type WordCategoryHistoryArgs = {
  limit?: Scalars['Int']
  skip?: Scalars['Int']
  stageOverride?: Maybe<Stage>
}

export type WordCategoryConnectInput = {
  /** Document to connect */
  where: WordCategoryWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type WordCategoryConnection = {
  __typename?: 'WordCategoryConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<WordCategoryEdge>
  aggregate: Aggregate
}

export type WordCategoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  name: Scalars['String']
  nameKana: Scalars['String']
  description?: Maybe<Scalars['RichTextAST']>
  words?: Maybe<WordCreateManyInlineInput>
}

export type WordCategoryCreateManyInlineInput = {
  /** Create and connect multiple existing WordCategory documents */
  create?: Maybe<Array<WordCategoryCreateInput>>
  /** Connect multiple existing WordCategory documents */
  connect?: Maybe<Array<WordCategoryWhereUniqueInput>>
}

export type WordCategoryCreateOneInlineInput = {
  /** Create and connect one WordCategory document */
  create?: Maybe<WordCategoryCreateInput>
  /** Connect one existing WordCategory document */
  connect?: Maybe<WordCategoryWhereUniqueInput>
}

/** An edge in a connection. */
export type WordCategoryEdge = {
  __typename?: 'WordCategoryEdge'
  /** The item at the end of the edge. */
  node: WordCategory
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type WordCategoryManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<WordCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<WordCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<WordCategoryWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  nameKana?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  nameKana_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  nameKana_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  nameKana_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  nameKana_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  nameKana_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  nameKana_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  nameKana_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  nameKana_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  nameKana_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  words_every?: Maybe<WordWhereInput>
  words_some?: Maybe<WordWhereInput>
  words_none?: Maybe<WordWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum WordCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NameKanaAsc = 'nameKana_ASC',
  NameKanaDesc = 'nameKana_DESC',
}

export type WordCategoryUpdateInput = {
  title?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nameKana?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['RichTextAST']>
  words?: Maybe<WordUpdateManyInlineInput>
}

export type WordCategoryUpdateManyInlineInput = {
  /** Create and connect multiple WordCategory documents */
  create?: Maybe<Array<WordCategoryCreateInput>>
  /** Connect multiple existing WordCategory documents */
  connect?: Maybe<Array<WordCategoryConnectInput>>
  /** Override currently-connected documents with multiple existing WordCategory documents */
  set?: Maybe<Array<WordCategoryWhereUniqueInput>>
  /** Update multiple WordCategory documents */
  update?: Maybe<Array<WordCategoryUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple WordCategory documents */
  upsert?: Maybe<Array<WordCategoryUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple WordCategory documents */
  disconnect?: Maybe<Array<WordCategoryWhereUniqueInput>>
  /** Delete multiple WordCategory documents */
  delete?: Maybe<Array<WordCategoryWhereUniqueInput>>
}

export type WordCategoryUpdateManyInput = {
  name?: Maybe<Scalars['String']>
  nameKana?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['RichTextAST']>
}

export type WordCategoryUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: WordCategoryWhereInput
  /** Update many input */
  data: WordCategoryUpdateManyInput
}

export type WordCategoryUpdateOneInlineInput = {
  /** Create and connect one WordCategory document */
  create?: Maybe<WordCategoryCreateInput>
  /** Update single WordCategory document */
  update?: Maybe<WordCategoryUpdateWithNestedWhereUniqueInput>
  /** Upsert single WordCategory document */
  upsert?: Maybe<WordCategoryUpsertWithNestedWhereUniqueInput>
  /** Connect existing WordCategory document */
  connect?: Maybe<WordCategoryWhereUniqueInput>
  /** Disconnect currently connected WordCategory document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected WordCategory document */
  delete?: Maybe<Scalars['Boolean']>
}

export type WordCategoryUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: WordCategoryWhereUniqueInput
  /** Document to update */
  data: WordCategoryUpdateInput
}

export type WordCategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: WordCategoryCreateInput
  /** Update document if it exists */
  update: WordCategoryUpdateInput
}

export type WordCategoryUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: WordCategoryWhereUniqueInput
  /** Upsert data */
  data: WordCategoryUpsertInput
}

/** Identifies documents */
export type WordCategoryWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<WordCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<WordCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<WordCategoryWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>
  nameKana?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  nameKana_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  nameKana_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  nameKana_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  nameKana_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  nameKana_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  nameKana_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  nameKana_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  nameKana_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  nameKana_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  words_every?: Maybe<WordWhereInput>
  words_some?: Maybe<WordWhereInput>
  words_none?: Maybe<WordWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References WordCategory record uniquely */
export type WordCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
}

export type WordConnectInput = {
  /** Document to connect */
  where: WordWhereUniqueInput
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<ConnectPositionInput>
}

/** A connection to a list of items. */
export type WordConnection = {
  __typename?: 'WordConnection'
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A list of edges. */
  edges: Array<WordEdge>
  aggregate: Aggregate
}

export type WordCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  title: Scalars['String']
  titleKana: Scalars['String']
  content: Scalars['RichTextAST']
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  wordCategory?: Maybe<WordCategoryCreateOneInlineInput>
}

export type WordCreateManyInlineInput = {
  /** Create and connect multiple existing Word documents */
  create?: Maybe<Array<WordCreateInput>>
  /** Connect multiple existing Word documents */
  connect?: Maybe<Array<WordWhereUniqueInput>>
}

export type WordCreateOneInlineInput = {
  /** Create and connect one Word document */
  create?: Maybe<WordCreateInput>
  /** Connect one existing Word document */
  connect?: Maybe<WordWhereUniqueInput>
}

/** An edge in a connection. */
export type WordEdge = {
  __typename?: 'WordEdge'
  /** The item at the end of the edge. */
  node: Word
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
}

/** Identifies documents */
export type WordManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<WordWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<WordWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<WordWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  titleKana?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  titleKana_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  titleKana_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  titleKana_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  titleKana_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  titleKana_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  titleKana_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  titleKana_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  titleKana_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  titleKana_not_ends_with?: Maybe<Scalars['String']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogFirstDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogFirstDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogFirstDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogFirstDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogFirstDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogFirstDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogFirstDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogFirstDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogFirstDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogSecondDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogSecondDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogSecondDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogSecondDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogSecondDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogSecondDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogSecondDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogSecondDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogSecondDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogThirdDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogThirdDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogThirdDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogThirdDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogThirdDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogThirdDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogThirdDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogThirdDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogThirdDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  wordCategory?: Maybe<WordCategoryWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

export enum WordOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TitleKanaAsc = 'titleKana_ASC',
  TitleKanaDesc = 'titleKana_DESC',
  CatalogFirstDepthCategoryIdAsc = 'catalogFirstDepthCategoryId_ASC',
  CatalogFirstDepthCategoryIdDesc = 'catalogFirstDepthCategoryId_DESC',
  CatalogSecondDepthCategoryIdAsc = 'catalogSecondDepthCategoryId_ASC',
  CatalogSecondDepthCategoryIdDesc = 'catalogSecondDepthCategoryId_DESC',
  CatalogThirdDepthCategoryIdAsc = 'catalogThirdDepthCategoryId_ASC',
  CatalogThirdDepthCategoryIdDesc = 'catalogThirdDepthCategoryId_DESC',
}

export type WordUpdateInput = {
  title?: Maybe<Scalars['String']>
  titleKana?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  wordCategory?: Maybe<WordCategoryUpdateOneInlineInput>
}

export type WordUpdateManyInlineInput = {
  /** Create and connect multiple Word documents */
  create?: Maybe<Array<WordCreateInput>>
  /** Connect multiple existing Word documents */
  connect?: Maybe<Array<WordConnectInput>>
  /** Override currently-connected documents with multiple existing Word documents */
  set?: Maybe<Array<WordWhereUniqueInput>>
  /** Update multiple Word documents */
  update?: Maybe<Array<WordUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Word documents */
  upsert?: Maybe<Array<WordUpsertWithNestedWhereUniqueInput>>
  /** Disconnect multiple Word documents */
  disconnect?: Maybe<Array<WordWhereUniqueInput>>
  /** Delete multiple Word documents */
  delete?: Maybe<Array<WordWhereUniqueInput>>
}

export type WordUpdateManyInput = {
  title?: Maybe<Scalars['String']>
  titleKana?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['RichTextAST']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
}

export type WordUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: WordWhereInput
  /** Update many input */
  data: WordUpdateManyInput
}

export type WordUpdateOneInlineInput = {
  /** Create and connect one Word document */
  create?: Maybe<WordCreateInput>
  /** Update single Word document */
  update?: Maybe<WordUpdateWithNestedWhereUniqueInput>
  /** Upsert single Word document */
  upsert?: Maybe<WordUpsertWithNestedWhereUniqueInput>
  /** Connect existing Word document */
  connect?: Maybe<WordWhereUniqueInput>
  /** Disconnect currently connected Word document */
  disconnect?: Maybe<Scalars['Boolean']>
  /** Delete currently connected Word document */
  delete?: Maybe<Scalars['Boolean']>
}

export type WordUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: WordWhereUniqueInput
  /** Document to update */
  data: WordUpdateInput
}

export type WordUpsertInput = {
  /** Create document if it didn't exist */
  create: WordCreateInput
  /** Update document if it exists */
  update: WordUpdateInput
}

export type WordUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: WordWhereUniqueInput
  /** Upsert data */
  data: WordUpsertInput
}

/** Identifies documents */
export type WordWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<WordWhereInput>>
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<WordWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<WordWhereInput>>
  id?: Maybe<Scalars['ID']>
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt?: Maybe<Scalars['DateTime']>
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>
  titleKana?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  titleKana_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  titleKana_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  titleKana_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  titleKana_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  titleKana_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  titleKana_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  titleKana_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  titleKana_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  titleKana_not_ends_with?: Maybe<Scalars['String']>
  catalogFirstDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogFirstDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogFirstDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogFirstDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogFirstDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogFirstDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogFirstDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogFirstDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogFirstDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogFirstDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  catalogSecondDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogSecondDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogSecondDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogSecondDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogSecondDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogSecondDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogSecondDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogSecondDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogSecondDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogSecondDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  catalogThirdDepthCategoryId?: Maybe<Scalars['String']>
  /** All values that are not equal to given value. */
  catalogThirdDepthCategoryId_not?: Maybe<Scalars['String']>
  /** All values that are contained in given list. */
  catalogThirdDepthCategoryId_in?: Maybe<Array<Scalars['String']>>
  /** All values that are not contained in given list. */
  catalogThirdDepthCategoryId_not_in?: Maybe<Array<Scalars['String']>>
  /** All values containing the given string. */
  catalogThirdDepthCategoryId_contains?: Maybe<Scalars['String']>
  /** All values not containing the given string. */
  catalogThirdDepthCategoryId_not_contains?: Maybe<Scalars['String']>
  /** All values starting with the given string. */
  catalogThirdDepthCategoryId_starts_with?: Maybe<Scalars['String']>
  /** All values not starting with the given string. */
  catalogThirdDepthCategoryId_not_starts_with?: Maybe<Scalars['String']>
  /** All values ending with the given string. */
  catalogThirdDepthCategoryId_ends_with?: Maybe<Scalars['String']>
  /** All values not ending with the given string */
  catalogThirdDepthCategoryId_not_ends_with?: Maybe<Scalars['String']>
  createdBy?: Maybe<UserWhereInput>
  updatedBy?: Maybe<UserWhereInput>
  publishedBy?: Maybe<UserWhereInput>
  wordCategory?: Maybe<WordCategoryWhereInput>
  scheduledIn_every?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_some?: Maybe<ScheduledOperationWhereInput>
  scheduledIn_none?: Maybe<ScheduledOperationWhereInput>
}

/** References Word record uniquely */
export type WordWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
}

export enum _FilterKind {
  Search = 'search',
  And = 'AND',
  Or = 'OR',
  Not = 'NOT',
  Eq = 'eq',
  EqNot = 'eq_not',
  In = 'in',
  NotIn = 'not_in',
  Lt = 'lt',
  Lte = 'lte',
  Gt = 'gt',
  Gte = 'gte',
  Contains = 'contains',
  NotContains = 'not_contains',
  StartsWith = 'starts_with',
  NotStartsWith = 'not_starts_with',
  EndsWith = 'ends_with',
  NotEndsWith = 'not_ends_with',
  ContainsAll = 'contains_all',
  ContainsSome = 'contains_some',
  ContainsNone = 'contains_none',
  RelationalSingle = 'relational_single',
  RelationalEvery = 'relational_every',
  RelationalSome = 'relational_some',
  RelationalNone = 'relational_none',
}

export enum _MutationInputFieldKind {
  Scalar = 'scalar',
  RichText = 'richText',
  RichTextWithEmbeds = 'richTextWithEmbeds',
  Enum = 'enum',
  Relation = 'relation',
  Union = 'union',
  Virtual = 'virtual',
}

export enum _MutationKind {
  Create = 'create',
  Publish = 'publish',
  Unpublish = 'unpublish',
  Update = 'update',
  Upsert = 'upsert',
  Delete = 'delete',
  UpdateMany = 'updateMany',
  PublishMany = 'publishMany',
  UnpublishMany = 'unpublishMany',
  DeleteMany = 'deleteMany',
}

export enum _OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum _RelationInputCardinality {
  One = 'one',
  Many = 'many',
}

export enum _RelationInputKind {
  Create = 'create',
  Update = 'update',
}

export enum _RelationKind {
  Regular = 'regular',
  Union = 'union',
}

export enum _SystemDateTimeFieldVariation {
  Base = 'base',
  Localization = 'localization',
  Combined = 'combined',
}

export type AdDistributionManagementFragment = {
  __typename?: 'AdDistributionManagement'
} & Pick<AdDistributionManagement, 'id' | 'destination' | 'format'> & {
    content?: Maybe<
      { __typename?: 'AdDistributionContent' } & Pick<
        AdDistributionContent,
        'id' | 'sponsored' | 'title' | 'description' | 'targetUrl' | 'urlParameter'
      > & {
          thumbnail: { __typename?: 'Asset' } & Pick<Asset, 'mimeType' | 'url'>
        }
    >
  }

export type GetAdDistributionManagementQueryVariables = Exact<{
  destination: AdDeliveryDestination
}>

export type GetAdDistributionManagementQuery = { __typename?: 'Query' } & {
  ad?: Maybe<
    {
      __typename?: 'AdDistributionManagement'
    } & AdDistributionManagementFragment
  >
}

export type ArticleFragment = { __typename?: 'Article' } & Pick<
  Article,
  | 'id'
  | 'title'
  | 'excerpt'
  | 'publishDate'
  | 'isOnlyUser'
  | 'isStory'
  | 'catalogFirstDepthCategoryId'
  | 'catalogSecondDepthCategoryId'
  | 'catalogThirdDepthCategoryId'
> & {
    thumbnail?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
    categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'title' | 'name'>>
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'name'>>
    content?: Maybe<{ __typename?: 'RichText' } & Pick<RichText, 'html'>>
    author?: Maybe<
      { __typename?: 'Author' } & Pick<Author, 'name' | 'link'> & {
          description?: Maybe<{ __typename?: 'RichText' } & Pick<RichText, 'html'>>
          thumbnail?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
        }
    >
    story?: Maybe<
      { __typename?: 'Story' } & Pick<Story, 'id'> & {
          articles: Array<
            { __typename?: 'Article' } & Pick<Article, 'id' | 'title' | 'excerpt' | 'storyPage'> & {
                thumbnail?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
              }
          >
        }
    >
  }

export type GetArticleQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetArticleQuery = { __typename?: 'Query' } & {
  article?: Maybe<{ __typename?: 'Article' } & ArticleFragment>
}

export type ArticleAllFragment = { __typename?: 'Article' } & Pick<
  Article,
  'id' | 'title' | 'excerpt' | 'publishDate'
> & {
    categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'title' | 'name'>>
  }

export type GetArticleAllQueryVariables = Exact<{
  first: Scalars['Int']
  skip: Scalars['Int']
}>

export type GetArticleAllQuery = { __typename?: 'Query' } & {
  articles: Array<{ __typename?: 'Article' } & ArticleAllFragment>
  articlesConnection: { __typename?: 'ArticleConnection' } & {
    aggregate: { __typename?: 'Aggregate' } & Pick<Aggregate, 'count'>
  }
}

export type ArticleCategoryFragment = { __typename?: 'Article' } & Pick<
  Article,
  'id' | 'title' | 'excerpt' | 'publishDate'
> & {
    categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'title' | 'name'>>
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'name'>>
  }

export type CategoryFragment = { __typename?: 'Category' } & Pick<Category, 'title' | 'name'>

export type GetArticleCategoryQueryVariables = Exact<{
  first: Scalars['Int']
  skip: Scalars['Int']
  id: Scalars['ID']
}>

export type GetArticleCategoryQuery = { __typename?: 'Query' } & {
  articles: Array<{ __typename?: 'Article' } & ArticleCategoryFragment>
  category?: Maybe<{ __typename?: 'Category' } & CategoryFragment>
  articlesConnection: { __typename?: 'ArticleConnection' } & {
    aggregate: { __typename?: 'Aggregate' } & Pick<Aggregate, 'count'>
  }
}

export type ArticleContactFragment = { __typename?: 'Article' } & Pick<
  Article,
  'id' | 'title' | 'isStory'
>

export type GetArticleContactQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetArticleContactQuery = { __typename?: 'Query' } & {
  article?: Maybe<{ __typename?: 'Article' } & ArticleContactFragment>
}

export type GetArticlePageQueryVariables = Exact<{ [key: string]: never }>

export type GetArticlePageQuery = { __typename?: 'Query' } & {
  articles: Array<{ __typename?: 'Article' } & Pick<Article, 'id' | 'title'>>
}

export type ArticlesFragment = { __typename?: 'Article' } & Pick<
  Article,
  'id' | 'title' | 'excerpt' | 'publishDate'
> & {
    thumbnail?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
    categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'title'>>
    author?: Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>
  }

export type GetArticlesQueryVariables = Exact<{
  first: Scalars['Int']
  skip: Scalars['Int']
}>

export type GetArticlesQuery = { __typename?: 'Query' } & {
  articles: Array<{ __typename?: 'Article' } & ArticlesFragment>
  carouselArticles: Array<{ __typename?: 'Article' } & ArticlesFragment>
}

export type ArticlesCategoryFragment = { __typename?: 'Article' } & Pick<
  Article,
  'id' | 'title' | 'excerpt' | 'publishDate'
> & {
    thumbnail?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
    categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'title'>>
    author?: Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>
  }

export type CategoryPageFragment = { __typename?: 'Category' } & Pick<Category, 'id' | 'name'>

export type GetArticlesCategoryQueryVariables = Exact<{
  categoryTitle: Scalars['String']
  articleId?: Maybe<Scalars['ID']>
  first: Scalars['Int']
  skip: Scalars['Int']
  category: Scalars['String']
}>

export type GetArticlesCategoryQuery = { __typename?: 'Query' } & {
  articles: Array<{ __typename?: 'Article' } & ArticlesCategoryFragment>
  category?: Maybe<{ __typename?: 'Category' } & CategoryPageFragment>
}

export type ArticlesCategoryContainerFragment = {
  __typename?: 'Article'
} & Pick<Article, 'id' | 'title' | 'publishDate'>

export type GetArticlesCategoryContainerQueryVariables = Exact<{
  [key: string]: never
}>

export type GetArticlesCategoryContainerQuery = { __typename?: 'Query' } & {
  articles: Array<{ __typename?: 'Article' } & ArticlesCategoryContainerFragment>
  factory: Array<{ __typename?: 'Article' } & ArticlesCategoryContainerFragment>
  architecture: Array<{ __typename?: 'Article' } & ArticlesCategoryContainerFragment>
  topics: Array<{ __typename?: 'Article' } & ArticlesCategoryContainerFragment>
}

export type GetCategoryQueryVariables = Exact<{ [key: string]: never }>

export type GetCategoryQuery = { __typename?: 'Query' } & {
  categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'title'>>
}

export type FaqFragment = { __typename?: 'Faq' } & Pick<Faq, 'id' | 'title' | 'category'> & {
    content: { __typename?: 'RichText' } & Pick<RichText, 'html'>
  }

export type GetFaqQueryVariables = Exact<{
  first: Scalars['Int']
  skip: Scalars['Int']
}>

export type GetFaqQuery = { __typename?: 'Query' } & {
  faqs: Array<{ __typename?: 'Faq' } & FaqFragment>
}

export type InformationPageFragment = { __typename?: 'Information' } & Pick<
  Information,
  'id' | 'title' | 'excerpt' | 'publishDate'
> & {
    thumbnail?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
    content: { __typename?: 'RichText' } & Pick<RichText, 'html'>
  }

export type GetInformationPageQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetInformationPageQuery = { __typename?: 'Query' } & {
  information?: Maybe<{ __typename?: 'Information' } & InformationPageFragment>
}

export type InformationTopFragment = { __typename?: 'Information' } & Pick<
  Information,
  'id' | 'title' | 'publishDate' | 'createdAt'
>

export type GetInformationTopQueryVariables = Exact<{ [key: string]: never }>

export type GetInformationTopQuery = { __typename?: 'Query' } & {
  informations: Array<{ __typename?: 'Information' } & InformationTopFragment>
}

export type GetInformationsQueryVariables = Exact<{ [key: string]: never }>

export type GetInformationsQuery = { __typename?: 'Query' } & {
  informations: Array<{ __typename?: 'Information' } & Pick<Information, 'id' | 'title'>>
}

export type IssueFragment = { __typename?: 'Issue' } & Pick<
  Issue,
  'id' | 'title' | 'titleKana' | 'keywords' | 'catalogIds'
> & {
    problem: { __typename?: 'RichText' } & Pick<RichText, 'html' | 'text'>
    cause: { __typename?: 'RichText' } & Pick<RichText, 'html'>
    solution: { __typename?: 'RichText' } & Pick<RichText, 'html'>
    issueCategory?: Maybe<{ __typename?: 'IssueCategory' } & Pick<IssueCategory, 'title' | 'name'>>
    articles: Array<
      { __typename?: 'Article' } & Pick<Article, 'id' | 'title' | 'excerpt' | 'publishDate'> & {
          thumbnail?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
          categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'title'>>
          author?: Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>
        }
    >
  }

export type GetIssueQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetIssueQuery = { __typename?: 'Query' } & {
  issue?: Maybe<{ __typename?: 'Issue' } & IssueFragment>
}

export type IssueCategoryFragment = { __typename?: 'IssueCategory' } & Pick<
  IssueCategory,
  'id' | 'title' | 'name' | 'nameKana' | 'sort'
> & {
    issues: Array<
      { __typename?: 'Issue' } & Pick<Issue, 'id' | 'title' | 'titleKana' | 'keywords'> & {
          problem: { __typename?: 'RichText' } & Pick<RichText, 'html'>
          cause: { __typename?: 'RichText' } & Pick<RichText, 'html'>
          solution: { __typename?: 'RichText' } & Pick<RichText, 'html'>
        }
    >
  }

export type GetIssueCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetIssueCategoriesQuery = { __typename?: 'Query' } & {
  issueCategories: Array<{ __typename?: 'IssueCategory' } & IssueCategoryFragment>
}

export type IssuesFragment = { __typename?: 'Issue' } & Pick<Issue, 'id'>

export type GetIssuesQueryVariables = Exact<{ [key: string]: never }>

export type GetIssuesQuery = { __typename?: 'Query' } & {
  issues: Array<{ __typename?: 'Issue' } & IssuesFragment>
}

export type PickupCatalogFragment = { __typename?: 'PickupCatalog' } & Pick<
  PickupCatalog,
  'catalogId' | 'sort'
>

export type GetPickupCatalogQueryVariables = Exact<{ [key: string]: never }>

export type GetPickupCatalogQuery = { __typename?: 'Query' } & {
  pickupCatalogs: Array<{ __typename?: 'PickupCatalog' } & PickupCatalogFragment>
}

export type StaticPageFragment = { __typename?: 'StaticPage' } & Pick<
  StaticPage,
  'id' | 'title' | 'staticPageType'
> & { content: { __typename?: 'RichText' } & Pick<RichText, 'html'> }

export type GetStaticPageQueryVariables = Exact<{
  staticPageType: StaticPageType
}>

export type GetStaticPageQuery = { __typename?: 'Query' } & {
  staticPage?: Maybe<{ __typename?: 'StaticPage' } & StaticPageFragment>
}

export type StoryArticlesFragment = { __typename?: 'Article' } & Pick<
  Article,
  'id' | 'title' | 'excerpt' | 'publishDate'
> & {
    thumbnail?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
    categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'title'>>
    author?: Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>
  }

export type GetStoryArticlesQueryVariables = Exact<{
  first: Scalars['Int']
  skip: Scalars['Int']
}>

export type GetStoryArticlesQuery = { __typename?: 'Query' } & {
  stories: Array<{ __typename?: 'Article' } & StoryArticlesFragment>
  articlesConnection: { __typename?: 'ArticleConnection' } & {
    aggregate: { __typename?: 'Aggregate' } & Pick<Aggregate, 'count'>
  }
}

export type WordFragment = { __typename?: 'Word' } & Pick<
  Word,
  | 'id'
  | 'title'
  | 'catalogFirstDepthCategoryId'
  | 'catalogSecondDepthCategoryId'
  | 'catalogThirdDepthCategoryId'
> & {
    content: { __typename?: 'RichText' } & Pick<RichText, 'html' | 'text'>
    wordCategory?: Maybe<{ __typename?: 'WordCategory' } & Pick<WordCategory, 'title' | 'name'>>
  }

export type GetWordQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetWordQuery = { __typename?: 'Query' } & {
  word?: Maybe<{ __typename?: 'Word' } & WordFragment>
}

export type WordCategoryFragment = { __typename?: 'WordCategory' } & Pick<
  WordCategory,
  'id' | 'title' | 'name' | 'nameKana'
> & {
    words: Array<
      { __typename?: 'Word' } & Pick<Word, 'id' | 'title' | 'titleKana'> & {
          content: { __typename?: 'RichText' } & Pick<RichText, 'html'>
        }
    >
  }

export type GetWordCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetWordCategoriesQuery = { __typename?: 'Query' } & {
  wordCategories: Array<{ __typename?: 'WordCategory' } & WordCategoryFragment>
}

export type WordsFragment = { __typename?: 'Word' } & Pick<Word, 'id'>

export type GetWordsQueryVariables = Exact<{ [key: string]: never }>

export type GetWordsQuery = { __typename?: 'Query' } & {
  words: Array<{ __typename?: 'Word' } & WordsFragment>
}

export const AdDistributionManagementFragmentDoc = gql`
  fragment AdDistributionManagement on AdDistributionManagement {
    id
    destination
    format
    content {
      id
      sponsored
      title
      description
      thumbnail {
        mimeType
        url
      }
      targetUrl
      urlParameter
    }
  }
`
export const ArticleFragmentDoc = gql`
  fragment Article on Article {
    id
    title
    excerpt
    thumbnail {
      url
    }
    categories {
      id
      title
      name
    }
    tags {
      id
      title
      name
    }
    publishDate
    content {
      html
    }
    author {
      name
      description {
        html
      }
      link
      thumbnail {
        url
      }
    }
    isOnlyUser
    isStory
    story {
      id
      articles {
        id
        title
        excerpt
        storyPage
        thumbnail {
          url
        }
      }
    }
    catalogFirstDepthCategoryId
    catalogSecondDepthCategoryId
    catalogThirdDepthCategoryId
  }
`
export const ArticleAllFragmentDoc = gql`
  fragment ArticleAll on Article {
    id
    title
    excerpt
    categories {
      id
      title
      name
    }
    publishDate
  }
`
export const ArticleCategoryFragmentDoc = gql`
  fragment ArticleCategory on Article {
    id
    title
    excerpt
    categories {
      id
      title
      name
    }
    tags {
      id
      title
      name
    }
    publishDate
  }
`
export const CategoryFragmentDoc = gql`
  fragment Category on Category {
    title
    name
  }
`
export const ArticleContactFragmentDoc = gql`
  fragment ArticleContact on Article {
    id
    title
    isStory
  }
`
export const ArticlesFragmentDoc = gql`
  fragment Articles on Article {
    id
    title
    excerpt
    thumbnail {
      url
    }
    categories {
      id
      title
    }
    author {
      name
    }
    publishDate
  }
`
export const ArticlesCategoryFragmentDoc = gql`
  fragment ArticlesCategory on Article {
    id
    title
    excerpt
    thumbnail {
      url
    }
    categories {
      id
      title
    }
    author {
      name
    }
    publishDate
  }
`
export const CategoryPageFragmentDoc = gql`
  fragment CategoryPage on Category {
    id
    name
  }
`
export const ArticlesCategoryContainerFragmentDoc = gql`
  fragment ArticlesCategoryContainer on Article {
    id
    title
    publishDate
  }
`
export const FaqFragmentDoc = gql`
  fragment Faq on Faq {
    id
    title
    content {
      html
    }
    category
  }
`
export const InformationPageFragmentDoc = gql`
  fragment InformationPage on Information {
    id
    title
    excerpt
    thumbnail {
      url
    }
    content {
      html
    }
    publishDate
  }
`
export const InformationTopFragmentDoc = gql`
  fragment InformationTop on Information {
    id
    title
    publishDate
    createdAt
  }
`
export const IssueFragmentDoc = gql`
  fragment Issue on Issue {
    id
    title
    titleKana
    keywords
    problem {
      html
      text
    }
    cause {
      html
    }
    solution {
      html
    }
    issueCategory {
      title
      name
    }
    catalogIds
    articles {
      id
      title
      excerpt
      thumbnail {
        url
      }
      categories {
        id
        title
      }
      author {
        name
      }
      publishDate
    }
  }
`
export const IssueCategoryFragmentDoc = gql`
  fragment IssueCategory on IssueCategory {
    id
    title
    name
    nameKana
    sort
    issues {
      id
      title
      titleKana
      keywords
      problem {
        html
      }
      cause {
        html
      }
      solution {
        html
      }
    }
  }
`
export const IssuesFragmentDoc = gql`
  fragment Issues on Issue {
    id
  }
`
export const PickupCatalogFragmentDoc = gql`
  fragment PickupCatalog on PickupCatalog {
    catalogId
    sort
  }
`
export const StaticPageFragmentDoc = gql`
  fragment StaticPage on StaticPage {
    id
    title
    content {
      html
    }
    staticPageType
  }
`
export const StoryArticlesFragmentDoc = gql`
  fragment StoryArticles on Article {
    id
    title
    excerpt
    thumbnail {
      url
    }
    categories {
      id
      title
    }
    author {
      name
    }
    publishDate
  }
`
export const WordFragmentDoc = gql`
  fragment Word on Word {
    id
    title
    content {
      html
      text
    }
    wordCategory {
      title
      name
    }
    catalogFirstDepthCategoryId
    catalogSecondDepthCategoryId
    catalogThirdDepthCategoryId
  }
`
export const WordCategoryFragmentDoc = gql`
  fragment WordCategory on WordCategory {
    id
    title
    name
    nameKana
    words {
      id
      title
      titleKana
      content {
        html
      }
    }
  }
`
export const WordsFragmentDoc = gql`
  fragment Words on Word {
    id
  }
`
export const GetAdDistributionManagementDocument = gql`
  query getAdDistributionManagement($destination: AdDeliveryDestination!) @api(name: graphcms) {
    ad: adDistributionManagement(where: { destination: $destination }) {
      ...AdDistributionManagement
    }
  }
  ${AdDistributionManagementFragmentDoc}
`

/**
 * __useGetAdDistributionManagementQuery__
 *
 * To run a query within a React component, call `useGetAdDistributionManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdDistributionManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdDistributionManagementQuery({
 *   variables: {
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useGetAdDistributionManagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdDistributionManagementQuery,
    GetAdDistributionManagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAdDistributionManagementQuery,
    GetAdDistributionManagementQueryVariables
  >(GetAdDistributionManagementDocument, options)
}
export function useGetAdDistributionManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdDistributionManagementQuery,
    GetAdDistributionManagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAdDistributionManagementQuery,
    GetAdDistributionManagementQueryVariables
  >(GetAdDistributionManagementDocument, options)
}
export type GetAdDistributionManagementQueryHookResult = ReturnType<
  typeof useGetAdDistributionManagementQuery
>
export type GetAdDistributionManagementLazyQueryHookResult = ReturnType<
  typeof useGetAdDistributionManagementLazyQuery
>
export type GetAdDistributionManagementQueryResult = Apollo.QueryResult<
  GetAdDistributionManagementQuery,
  GetAdDistributionManagementQueryVariables
>
export const GetArticleDocument = gql`
  query getArticle($id: ID!) @api(name: graphcms) {
    article(where: { id: $id }) {
      ...Article
    }
  }
  ${ArticleFragmentDoc}
`

/**
 * __useGetArticleQuery__
 *
 * To run a query within a React component, call `useGetArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleQuery(
  baseOptions: Apollo.QueryHookOptions<GetArticleQuery, GetArticleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, options)
}
export function useGetArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetArticleQuery, GetArticleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, options)
}
export type GetArticleQueryHookResult = ReturnType<typeof useGetArticleQuery>
export type GetArticleLazyQueryHookResult = ReturnType<typeof useGetArticleLazyQuery>
export type GetArticleQueryResult = Apollo.QueryResult<GetArticleQuery, GetArticleQueryVariables>
export const GetArticleAllDocument = gql`
  query getArticleAll($first: Int!, $skip: Int!) @api(name: graphcms) {
    articles(orderBy: publishDate_DESC, first: $first, skip: $skip) {
      ...ArticleAll
    }
    articlesConnection {
      aggregate {
        count
      }
    }
  }
  ${ArticleAllFragmentDoc}
`

/**
 * __useGetArticleAllQuery__
 *
 * To run a query within a React component, call `useGetArticleAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleAllQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetArticleAllQuery(
  baseOptions: Apollo.QueryHookOptions<GetArticleAllQuery, GetArticleAllQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetArticleAllQuery, GetArticleAllQueryVariables>(
    GetArticleAllDocument,
    options,
  )
}
export function useGetArticleAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetArticleAllQuery, GetArticleAllQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetArticleAllQuery, GetArticleAllQueryVariables>(
    GetArticleAllDocument,
    options,
  )
}
export type GetArticleAllQueryHookResult = ReturnType<typeof useGetArticleAllQuery>
export type GetArticleAllLazyQueryHookResult = ReturnType<typeof useGetArticleAllLazyQuery>
export type GetArticleAllQueryResult = Apollo.QueryResult<
  GetArticleAllQuery,
  GetArticleAllQueryVariables
>
export const GetArticleCategoryDocument = gql`
  query getArticleCategory($first: Int!, $skip: Int!, $id: ID!) @api(name: graphcms) {
    articles(
      orderBy: publishDate_DESC
      first: $first
      skip: $skip
      where: { categories_some: { id: $id } }
    ) {
      ...ArticleCategory
    }
    category(where: { id: $id }) {
      ...Category
    }
    articlesConnection(where: { categories_some: { id: $id } }) {
      aggregate {
        count
      }
    }
  }
  ${ArticleCategoryFragmentDoc}
  ${CategoryFragmentDoc}
`

/**
 * __useGetArticleCategoryQuery__
 *
 * To run a query within a React component, call `useGetArticleCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleCategoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetArticleCategoryQuery, GetArticleCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetArticleCategoryQuery, GetArticleCategoryQueryVariables>(
    GetArticleCategoryDocument,
    options,
  )
}
export function useGetArticleCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleCategoryQuery,
    GetArticleCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetArticleCategoryQuery, GetArticleCategoryQueryVariables>(
    GetArticleCategoryDocument,
    options,
  )
}
export type GetArticleCategoryQueryHookResult = ReturnType<typeof useGetArticleCategoryQuery>
export type GetArticleCategoryLazyQueryHookResult = ReturnType<
  typeof useGetArticleCategoryLazyQuery
>
export type GetArticleCategoryQueryResult = Apollo.QueryResult<
  GetArticleCategoryQuery,
  GetArticleCategoryQueryVariables
>
export const GetArticleContactDocument = gql`
  query getArticleContact($id: ID!) @api(name: graphcms) {
    article(where: { id: $id }) {
      ...ArticleContact
    }
  }
  ${ArticleContactFragmentDoc}
`

/**
 * __useGetArticleContactQuery__
 *
 * To run a query within a React component, call `useGetArticleContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleContactQuery(
  baseOptions: Apollo.QueryHookOptions<GetArticleContactQuery, GetArticleContactQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetArticleContactQuery, GetArticleContactQueryVariables>(
    GetArticleContactDocument,
    options,
  )
}
export function useGetArticleContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleContactQuery,
    GetArticleContactQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetArticleContactQuery, GetArticleContactQueryVariables>(
    GetArticleContactDocument,
    options,
  )
}
export type GetArticleContactQueryHookResult = ReturnType<typeof useGetArticleContactQuery>
export type GetArticleContactLazyQueryHookResult = ReturnType<typeof useGetArticleContactLazyQuery>
export type GetArticleContactQueryResult = Apollo.QueryResult<
  GetArticleContactQuery,
  GetArticleContactQueryVariables
>
export const GetArticlePageDocument = gql`
  query getArticlePage @api(name: graphcms) {
    articles(orderBy: publishDate_DESC, first: 5000) {
      id
      title
    }
  }
`

/**
 * __useGetArticlePageQuery__
 *
 * To run a query within a React component, call `useGetArticlePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArticlePageQuery(
  baseOptions?: Apollo.QueryHookOptions<GetArticlePageQuery, GetArticlePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetArticlePageQuery, GetArticlePageQueryVariables>(
    GetArticlePageDocument,
    options,
  )
}
export function useGetArticlePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetArticlePageQuery, GetArticlePageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetArticlePageQuery, GetArticlePageQueryVariables>(
    GetArticlePageDocument,
    options,
  )
}
export type GetArticlePageQueryHookResult = ReturnType<typeof useGetArticlePageQuery>
export type GetArticlePageLazyQueryHookResult = ReturnType<typeof useGetArticlePageLazyQuery>
export type GetArticlePageQueryResult = Apollo.QueryResult<
  GetArticlePageQuery,
  GetArticlePageQueryVariables
>
export const GetArticlesDocument = gql`
  query getArticles($first: Int!, $skip: Int!) @api(name: graphcms) {
    articles(orderBy: publishDate_DESC, first: $first, skip: $skip) {
      ...Articles
    }
    carouselArticles: articles(orderBy: publishDate_DESC, first: 1) {
      ...Articles
    }
  }
  ${ArticlesFragmentDoc}
`

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options)
}
export function useGetArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(
    GetArticlesDocument,
    options,
  )
}
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>
export type GetArticlesQueryResult = Apollo.QueryResult<GetArticlesQuery, GetArticlesQueryVariables>
export const GetArticlesCategoryDocument = gql`
  query getArticlesCategory(
    $categoryTitle: String!
    $articleId: ID
    $first: Int!
    $skip: Int!
    $category: String!
  ) @api(name: graphcms) {
    articles(
      orderBy: publishDate_DESC
      first: $first
      skip: $skip
      where: { AND: [{ categories_some: { title: $category } }], NOT: [{ id: $articleId }] }
    ) {
      ...ArticlesCategory
    }
    category(where: { title: $categoryTitle }) {
      ...CategoryPage
    }
  }
  ${ArticlesCategoryFragmentDoc}
  ${CategoryPageFragmentDoc}
`

/**
 * __useGetArticlesCategoryQuery__
 *
 * To run a query within a React component, call `useGetArticlesCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesCategoryQuery({
 *   variables: {
 *      categoryTitle: // value for 'categoryTitle'
 *      articleId: // value for 'articleId'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetArticlesCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetArticlesCategoryQuery, GetArticlesCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetArticlesCategoryQuery, GetArticlesCategoryQueryVariables>(
    GetArticlesCategoryDocument,
    options,
  )
}
export function useGetArticlesCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticlesCategoryQuery,
    GetArticlesCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetArticlesCategoryQuery, GetArticlesCategoryQueryVariables>(
    GetArticlesCategoryDocument,
    options,
  )
}
export type GetArticlesCategoryQueryHookResult = ReturnType<typeof useGetArticlesCategoryQuery>
export type GetArticlesCategoryLazyQueryHookResult = ReturnType<
  typeof useGetArticlesCategoryLazyQuery
>
export type GetArticlesCategoryQueryResult = Apollo.QueryResult<
  GetArticlesCategoryQuery,
  GetArticlesCategoryQueryVariables
>
export const GetArticlesCategoryContainerDocument = gql`
  query getArticlesCategoryContainer @api(name: graphcms) {
    articles: articles(orderBy: publishDate_DESC, first: 3) {
      ...ArticlesCategoryContainer
    }
    factory: articles(
      orderBy: publishDate_DESC
      first: 3
      where: { categories_some: { title: "factory" } }
    ) {
      ...ArticlesCategoryContainer
    }
    architecture: articles(
      orderBy: publishDate_DESC
      first: 3
      where: { categories_some: { title: "architecture" } }
    ) {
      ...ArticlesCategoryContainer
    }
    topics: articles(
      orderBy: publishDate_DESC
      first: 3
      where: { categories_some: { title: "topics" } }
    ) {
      ...ArticlesCategoryContainer
    }
  }
  ${ArticlesCategoryContainerFragmentDoc}
`

/**
 * __useGetArticlesCategoryContainerQuery__
 *
 * To run a query within a React component, call `useGetArticlesCategoryContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesCategoryContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesCategoryContainerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArticlesCategoryContainerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetArticlesCategoryContainerQuery,
    GetArticlesCategoryContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetArticlesCategoryContainerQuery,
    GetArticlesCategoryContainerQueryVariables
  >(GetArticlesCategoryContainerDocument, options)
}
export function useGetArticlesCategoryContainerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticlesCategoryContainerQuery,
    GetArticlesCategoryContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetArticlesCategoryContainerQuery,
    GetArticlesCategoryContainerQueryVariables
  >(GetArticlesCategoryContainerDocument, options)
}
export type GetArticlesCategoryContainerQueryHookResult = ReturnType<
  typeof useGetArticlesCategoryContainerQuery
>
export type GetArticlesCategoryContainerLazyQueryHookResult = ReturnType<
  typeof useGetArticlesCategoryContainerLazyQuery
>
export type GetArticlesCategoryContainerQueryResult = Apollo.QueryResult<
  GetArticlesCategoryContainerQuery,
  GetArticlesCategoryContainerQueryVariables
>
export const GetCategoryDocument = gql`
  query getCategory @api(name: graphcms) {
    categories(orderBy: createdAt_DESC, first: 1500) {
      id
      title
    }
  }
`

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options)
}
export function useGetCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(
    GetCategoryDocument,
    options,
  )
}
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>
export type GetCategoryQueryResult = Apollo.QueryResult<GetCategoryQuery, GetCategoryQueryVariables>
export const GetFaqDocument = gql`
  query getFaq($first: Int!, $skip: Int!) @api(name: graphcms) {
    faqs(orderBy: createdAt_ASC, first: $first, skip: $skip) {
      ...Faq
    }
  }
  ${FaqFragmentDoc}
`

/**
 * __useGetFaqQuery__
 *
 * To run a query within a React component, call `useGetFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetFaqQuery(
  baseOptions: Apollo.QueryHookOptions<GetFaqQuery, GetFaqQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options)
}
export function useGetFaqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFaqQuery, GetFaqQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options)
}
export type GetFaqQueryHookResult = ReturnType<typeof useGetFaqQuery>
export type GetFaqLazyQueryHookResult = ReturnType<typeof useGetFaqLazyQuery>
export type GetFaqQueryResult = Apollo.QueryResult<GetFaqQuery, GetFaqQueryVariables>
export const GetInformationPageDocument = gql`
  query getInformationPage($id: ID!) @api(name: graphcms) {
    information(where: { id: $id }) {
      ...InformationPage
    }
  }
  ${InformationPageFragmentDoc}
`

/**
 * __useGetInformationPageQuery__
 *
 * To run a query within a React component, call `useGetInformationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInformationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInformationPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInformationPageQuery(
  baseOptions: Apollo.QueryHookOptions<GetInformationPageQuery, GetInformationPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInformationPageQuery, GetInformationPageQueryVariables>(
    GetInformationPageDocument,
    options,
  )
}
export function useGetInformationPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInformationPageQuery,
    GetInformationPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInformationPageQuery, GetInformationPageQueryVariables>(
    GetInformationPageDocument,
    options,
  )
}
export type GetInformationPageQueryHookResult = ReturnType<typeof useGetInformationPageQuery>
export type GetInformationPageLazyQueryHookResult = ReturnType<
  typeof useGetInformationPageLazyQuery
>
export type GetInformationPageQueryResult = Apollo.QueryResult<
  GetInformationPageQuery,
  GetInformationPageQueryVariables
>
export const GetInformationTopDocument = gql`
  query getInformationTop @api(name: graphcms) {
    informations(orderBy: createdAt_DESC, first: 5) {
      ...InformationTop
    }
  }
  ${InformationTopFragmentDoc}
`

/**
 * __useGetInformationTopQuery__
 *
 * To run a query within a React component, call `useGetInformationTopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInformationTopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInformationTopQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInformationTopQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInformationTopQuery, GetInformationTopQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInformationTopQuery, GetInformationTopQueryVariables>(
    GetInformationTopDocument,
    options,
  )
}
export function useGetInformationTopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInformationTopQuery,
    GetInformationTopQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInformationTopQuery, GetInformationTopQueryVariables>(
    GetInformationTopDocument,
    options,
  )
}
export type GetInformationTopQueryHookResult = ReturnType<typeof useGetInformationTopQuery>
export type GetInformationTopLazyQueryHookResult = ReturnType<typeof useGetInformationTopLazyQuery>
export type GetInformationTopQueryResult = Apollo.QueryResult<
  GetInformationTopQuery,
  GetInformationTopQueryVariables
>
export const GetInformationsDocument = gql`
  query getInformations @api(name: graphcms) {
    informations(orderBy: createdAt_DESC, first: 1500) {
      id
      title
    }
  }
`

/**
 * __useGetInformationsQuery__
 *
 * To run a query within a React component, call `useGetInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInformationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInformationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInformationsQuery, GetInformationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInformationsQuery, GetInformationsQueryVariables>(
    GetInformationsDocument,
    options,
  )
}
export function useGetInformationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInformationsQuery, GetInformationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInformationsQuery, GetInformationsQueryVariables>(
    GetInformationsDocument,
    options,
  )
}
export type GetInformationsQueryHookResult = ReturnType<typeof useGetInformationsQuery>
export type GetInformationsLazyQueryHookResult = ReturnType<typeof useGetInformationsLazyQuery>
export type GetInformationsQueryResult = Apollo.QueryResult<
  GetInformationsQuery,
  GetInformationsQueryVariables
>
export const GetIssueDocument = gql`
  query getIssue($id: ID!) @api(name: graphcms) {
    issue(where: { id: $id }) {
      ...Issue
    }
  }
  ${IssueFragmentDoc}
`

/**
 * __useGetIssueQuery__
 *
 * To run a query within a React component, call `useGetIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIssueQuery(
  baseOptions: Apollo.QueryHookOptions<GetIssueQuery, GetIssueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetIssueQuery, GetIssueQueryVariables>(GetIssueDocument, options)
}
export function useGetIssueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIssueQuery, GetIssueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetIssueQuery, GetIssueQueryVariables>(GetIssueDocument, options)
}
export type GetIssueQueryHookResult = ReturnType<typeof useGetIssueQuery>
export type GetIssueLazyQueryHookResult = ReturnType<typeof useGetIssueLazyQuery>
export type GetIssueQueryResult = Apollo.QueryResult<GetIssueQuery, GetIssueQueryVariables>
export const GetIssueCategoriesDocument = gql`
  query getIssueCategories @api(name: graphcms) {
    issueCategories(orderBy: sort_ASC, first: 1500) {
      ...IssueCategory
    }
  }
  ${IssueCategoryFragmentDoc}
`

/**
 * __useGetIssueCategoriesQuery__
 *
 * To run a query within a React component, call `useGetIssueCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIssueCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetIssueCategoriesQuery, GetIssueCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetIssueCategoriesQuery, GetIssueCategoriesQueryVariables>(
    GetIssueCategoriesDocument,
    options,
  )
}
export function useGetIssueCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIssueCategoriesQuery,
    GetIssueCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetIssueCategoriesQuery, GetIssueCategoriesQueryVariables>(
    GetIssueCategoriesDocument,
    options,
  )
}
export type GetIssueCategoriesQueryHookResult = ReturnType<typeof useGetIssueCategoriesQuery>
export type GetIssueCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetIssueCategoriesLazyQuery
>
export type GetIssueCategoriesQueryResult = Apollo.QueryResult<
  GetIssueCategoriesQuery,
  GetIssueCategoriesQueryVariables
>
export const GetIssuesDocument = gql`
  query getIssues @api(name: graphcms) {
    issues(first: 300) {
      ...Issues
    }
  }
  ${IssuesFragmentDoc}
`

/**
 * __useGetIssuesQuery__
 *
 * To run a query within a React component, call `useGetIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIssuesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetIssuesQuery, GetIssuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetIssuesQuery, GetIssuesQueryVariables>(GetIssuesDocument, options)
}
export function useGetIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIssuesQuery, GetIssuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetIssuesQuery, GetIssuesQueryVariables>(GetIssuesDocument, options)
}
export type GetIssuesQueryHookResult = ReturnType<typeof useGetIssuesQuery>
export type GetIssuesLazyQueryHookResult = ReturnType<typeof useGetIssuesLazyQuery>
export type GetIssuesQueryResult = Apollo.QueryResult<GetIssuesQuery, GetIssuesQueryVariables>
export const GetPickupCatalogDocument = gql`
  query getPickupCatalog @api(name: graphcms) {
    pickupCatalogs(orderBy: sort_ASC, first: 12) {
      ...PickupCatalog
    }
  }
  ${PickupCatalogFragmentDoc}
`

/**
 * __useGetPickupCatalogQuery__
 *
 * To run a query within a React component, call `useGetPickupCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickupCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickupCatalogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPickupCatalogQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPickupCatalogQuery, GetPickupCatalogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPickupCatalogQuery, GetPickupCatalogQueryVariables>(
    GetPickupCatalogDocument,
    options,
  )
}
export function useGetPickupCatalogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPickupCatalogQuery, GetPickupCatalogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPickupCatalogQuery, GetPickupCatalogQueryVariables>(
    GetPickupCatalogDocument,
    options,
  )
}
export type GetPickupCatalogQueryHookResult = ReturnType<typeof useGetPickupCatalogQuery>
export type GetPickupCatalogLazyQueryHookResult = ReturnType<typeof useGetPickupCatalogLazyQuery>
export type GetPickupCatalogQueryResult = Apollo.QueryResult<
  GetPickupCatalogQuery,
  GetPickupCatalogQueryVariables
>
export const GetStaticPageDocument = gql`
  query getStaticPage($staticPageType: StaticPageType!) @api(name: graphcms) {
    staticPage(where: { staticPageType: $staticPageType }) {
      ...StaticPage
    }
  }
  ${StaticPageFragmentDoc}
`

/**
 * __useGetStaticPageQuery__
 *
 * To run a query within a React component, call `useGetStaticPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticPageQuery({
 *   variables: {
 *      staticPageType: // value for 'staticPageType'
 *   },
 * });
 */
export function useGetStaticPageQuery(
  baseOptions: Apollo.QueryHookOptions<GetStaticPageQuery, GetStaticPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetStaticPageQuery, GetStaticPageQueryVariables>(
    GetStaticPageDocument,
    options,
  )
}
export function useGetStaticPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStaticPageQuery, GetStaticPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetStaticPageQuery, GetStaticPageQueryVariables>(
    GetStaticPageDocument,
    options,
  )
}
export type GetStaticPageQueryHookResult = ReturnType<typeof useGetStaticPageQuery>
export type GetStaticPageLazyQueryHookResult = ReturnType<typeof useGetStaticPageLazyQuery>
export type GetStaticPageQueryResult = Apollo.QueryResult<
  GetStaticPageQuery,
  GetStaticPageQueryVariables
>
export const GetStoryArticlesDocument = gql`
  query getStoryArticles($first: Int!, $skip: Int!) @api(name: graphcms) {
    stories: articles(
      orderBy: publishDate_DESC
      first: $first
      skip: $skip
      where: { isStory: true }
    ) {
      ...StoryArticles
    }
    articlesConnection(where: { isStory: true }) {
      aggregate {
        count
      }
    }
  }
  ${StoryArticlesFragmentDoc}
`

/**
 * __useGetStoryArticlesQuery__
 *
 * To run a query within a React component, call `useGetStoryArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryArticlesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetStoryArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<GetStoryArticlesQuery, GetStoryArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetStoryArticlesQuery, GetStoryArticlesQueryVariables>(
    GetStoryArticlesDocument,
    options,
  )
}
export function useGetStoryArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStoryArticlesQuery, GetStoryArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetStoryArticlesQuery, GetStoryArticlesQueryVariables>(
    GetStoryArticlesDocument,
    options,
  )
}
export type GetStoryArticlesQueryHookResult = ReturnType<typeof useGetStoryArticlesQuery>
export type GetStoryArticlesLazyQueryHookResult = ReturnType<typeof useGetStoryArticlesLazyQuery>
export type GetStoryArticlesQueryResult = Apollo.QueryResult<
  GetStoryArticlesQuery,
  GetStoryArticlesQueryVariables
>
export const GetWordDocument = gql`
  query getWord($id: ID!) @api(name: graphcms) {
    word(where: { id: $id }) {
      ...Word
    }
  }
  ${WordFragmentDoc}
`

/**
 * __useGetWordQuery__
 *
 * To run a query within a React component, call `useGetWordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWordQuery(
  baseOptions: Apollo.QueryHookOptions<GetWordQuery, GetWordQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWordQuery, GetWordQueryVariables>(GetWordDocument, options)
}
export function useGetWordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWordQuery, GetWordQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWordQuery, GetWordQueryVariables>(GetWordDocument, options)
}
export type GetWordQueryHookResult = ReturnType<typeof useGetWordQuery>
export type GetWordLazyQueryHookResult = ReturnType<typeof useGetWordLazyQuery>
export type GetWordQueryResult = Apollo.QueryResult<GetWordQuery, GetWordQueryVariables>
export const GetWordCategoriesDocument = gql`
  query getWordCategories @api(name: graphcms) {
    wordCategories(first: 1500) {
      ...WordCategory
    }
  }
  ${WordCategoryFragmentDoc}
`

/**
 * __useGetWordCategoriesQuery__
 *
 * To run a query within a React component, call `useGetWordCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWordCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWordCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWordCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWordCategoriesQuery, GetWordCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWordCategoriesQuery, GetWordCategoriesQueryVariables>(
    GetWordCategoriesDocument,
    options,
  )
}
export function useGetWordCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWordCategoriesQuery,
    GetWordCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWordCategoriesQuery, GetWordCategoriesQueryVariables>(
    GetWordCategoriesDocument,
    options,
  )
}
export type GetWordCategoriesQueryHookResult = ReturnType<typeof useGetWordCategoriesQuery>
export type GetWordCategoriesLazyQueryHookResult = ReturnType<typeof useGetWordCategoriesLazyQuery>
export type GetWordCategoriesQueryResult = Apollo.QueryResult<
  GetWordCategoriesQuery,
  GetWordCategoriesQueryVariables
>
export const GetWordsDocument = gql`
  query getWords @api(name: graphcms) {
    words(first: 300) {
      ...Words
    }
  }
  ${WordsFragmentDoc}
`

/**
 * __useGetWordsQuery__
 *
 * To run a query within a React component, call `useGetWordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWordsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWordsQuery, GetWordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWordsQuery, GetWordsQueryVariables>(GetWordsDocument, options)
}
export function useGetWordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWordsQuery, GetWordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWordsQuery, GetWordsQueryVariables>(GetWordsDocument, options)
}
export type GetWordsQueryHookResult = ReturnType<typeof useGetWordsQuery>
export type GetWordsLazyQueryHookResult = ReturnType<typeof useGetWordsLazyQuery>
export type GetWordsQueryResult = Apollo.QueryResult<GetWordsQuery, GetWordsQueryVariables>
